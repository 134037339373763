import '@/assets/css/normalize.css'
import '@/assets/css/fonts.css'
import '@/assets/css/main.css'
import '@/assets/js/main.js'

import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import store from './store'
import VueMask from 'v-mask'
import router from './router'
import VueMeta from 'vue-meta'
import vSelect from 'vue-select'
import i18n from './locales/index'
import { routerHandlerInit } from './handlers/routerHandler'

import 'vue-select/dist/vue-select.css'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + '/api/'
axios.defaults.headers.common = {
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Language': localStorage.getItem('lang') || 'ru'
}

Vue.use(VueMask)
Vue.use(routerHandlerInit)
Vue.component('v-select', vSelect)
Vue.component('mv-header', require('./components/main/Header').default)
Vue.component('sv-header', require('./components/subscription/Basic/Header').default)
Vue.component('v-footer', require('./components/Footer').default)
Vue.use(VueMeta)

Vue.use(VueMeta, {
  keyName: 'head',
  refreshOnceOnNavigation: true
})

Vue.filter('formatMoney', function (value, fraction) {
  if (fraction === undefined) {
    if (value !== Math.floor(value)) {
      fraction = 0
    }
  }
  const number = parseFloat(value)
  return number.toLocaleString('ru-RU', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction
  })
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
