<template>
  <div class="change-form-item">
      <div class="change-form-item__left-column dashed-line">
        <h1 class="change-form-item__title">{{ setFullName }}</h1>
        <div class="change-form-item__block">
          <div class="change-form-item__logo">
            <img :src="getCardIconUrl" :alt="getCardIconAlt">
          </div>
          <p>
            {{ getPaySystem }}
            <br/>
            <span>• {{ fourDigitsCardNumber }}</span>
          </p>
        </div>
        <button class="btn orange border-radius mb-10" v-on:click="$emit('change_card')">Изменить</button>
        <span>С вашей карты спишется 10 тенге, а потом вернется назад на карту</span>
      </div>
      <div class="change-form-item__right-column">
        <div class="change-form-item__top_icon">
          <img :src="getIconUrl" :alt="getIconAlt">
        </div>
        <ul>
          <li v-if="+item.insrType === 3014">Страхование квартиры</li>
          <li v-if="+item.insrType === 3016">Страхование дома</li>
          <li v-if="+item.insrType === 2225">Страхование авто</li>
          <li>Тариф: <span>{{ item.tarif }}</span></li>
          <li v-if="+item.insrType === 2225">Марка авто:  <span>{{ item.variableParams.CASKO_MODEL_MAKE }}</span> </li>
          <li v-if="+item.insrType === 2225">Гос номер: <span>{{ item.variableParams.CASKO_VEHICLE_NO }}</span> </li>
          <li v-if="+item.insrType === 3014 || +item.insrType === 3016">Адрес: <span>{{ item.variableParams.IMU_ADDRESS }}</span> </li>
        </ul>
      </div>
  </div>
</template>

<script>
// Utils
import setUserName from '@/mixin/setUserName'
import productIconInfo from '@/mixin/productIconInfo'

export default {
  name: 'ProductCardItem',
  mixins: [setUserName, productIconInfo],
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: () => ({
    cardInfo: [
      {
        pay_system: 'Master card',
        icon: {
          url: require('@/assets/images/icon-mastercard.svg'),
          alt: 'icon-mastercard'
        }
      },
      {
        pay_system: 'VISA',
        icon: {
          url: require('@/assets/images/icon-visa.svg'),
          alt: 'icon-visa'
        }
      },
      {
        pay_system: 'Other',
        icon: {
          url: require('@/assets/images/icon-other-card.svg'),
          alt: 'icon-other-card'
        }
      }
    ]
  }),
  computed: {
    fourDigitsCardNumber () {
      return this.item.cardId.split('').splice(-4).join('')
    },
    getPaySystem () {
      let paySystem = ''
      if (this.item.cardType === 'MC' || ['51', '52', '53', '54', '55'].includes(this.item.cardId.slice(0, 2))) {
        paySystem = 'Master card'
      } else if (this.item.cardType === 'VISA' || this.item.cardId.slice(0, 1) === '4') {
        paySystem = 'VISA'
      } else paySystem = 'Other'
      return paySystem
    },
    getCardIconUrl () {
      return this.cardInfo[this.cardInfo.findIndex(obj => obj.pay_system === this.getPaySystem)].icon.url
    },
    getCardIconAlt () {
      return this.cardInfo[this.cardInfo.findIndex(obj => obj.pay_system === this.getPaySystem)].icon.alt
    }
  }
}
</script>

<style lang="css">
.change-form-item {
  justify-content: space-between;
  background: #F9F9F9;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  width: 100%;
}
.change-form-item__title {
  margin: 0 0 14px 0;
  line-height: 140%;
  font-weight: 600;
  font-size: 16px;
}
.change-form-item__left-column, .change-form-item__right-column {
  padding: 30px 25px 15px 30px;
}
.change-form-item .dashed-line {
  position: relative;
}
.change-form-item .dashed-line:before {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="8" width="2"><path d="M.984 0A1 1 0 000 1.014v1c-.02 1.352 2.02 1.352 2 0v-1A1 1 0 00.984 0z" fill="%23d5d5d5"/></svg>') 50% repeat-y;
  position: absolute;
  display: block;
  content: "";
  width: 2px;
  bottom: 0;
  right: 0;
  top: 0;
}
.change-form-item__left-column {
  width: 60%;
}
.change-form-item__left-column > span {
  font-size: 12px;
  display: block;
}
.change-form-item__block {
  box-shadow: 0 0 0 2px transparent;
  border: 1px solid #D5D5D5;
  box-sizing: border-box;
  background: #FFFFFF;
  margin-bottom: 20px;
  border-radius: 3px;
  position: relative;
  padding: 3px 8px;
  display: flex;
  height: 50px;
}
.change-form-item__block > p {
  line-height: 125%;
  font-weight: 500;
  font-size: 16px;
  color: #071222;
}
.change-form-item__block > p > span {
  line-height: 133%;
  font-weight: 500;
  font-size: 12px;
  color: #99A3B3;
}
.change-form-item__right-column {
  width: 40%;
}
.change-form-item__right-column > ul > li > span {
  line-height: 140%;
  font-size: 12px;
  color: #646B69;
}
.change-form-item__right-column > ul > li {
  line-height: 145%;
  font-weight: 500;
  font-size: 16px;
}
.change-form-item__top_icon img {
  margin-bottom: 15px;
  height: 85px;
}
.change-form-item__logo {
  justify-content: center;
  justify-items: center;
  align-items: center;
  background: #F1F2F6;
  border-radius: 12px;
  margin-right: 16px;
  position: relative;
  display: flex;
  width: 44px;
}
.change-form-item__logo img {
  height: auto;
  width: 65%;
}

@media (max-width: 766px) {
  .change-form-item {
    flex-direction: column;
  }
  .change-form-item__title {
    font-size: 14px;
    line-height: 120%;
    margin: 0 0 10px 0;
  }
  .change-form-item__left-column, .change-form-item__right-column {
    padding: 10px 0 10px 10px;
  }
  .change-form-item .dashed-line {
    position: static;
  }
  .change-form-item {
    position: relative;
  }
  .change-form-block .dashed-line:before {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="2" width="8"><path d="M.984 0A1 1 0 000 1.014v1c-.02 1.352 2.02 1.352 2 0v-1A1 1 0 00.984 0z" fill="%23d5d5d5"/></svg>') 50% repeat-x;
    width: 100%;
    top: 35%;
  }
  .change-form-item__left-column {
    border-right: 0;
    width: 90%;
  }
  .change-form-item__left-column > span {
    font-size: 10px;
  }
  .change-form-item__right-column > ul > li > span {
    line-height: 125%;
    font-size: 10px;
  }
  .change-form-item__right-column > ul > li {
    font-size: 12px;
  }
  .change-form-item__block {
    margin-bottom: 10px;
  }
  .change-form-item__right-column {
    align-items: center;
    display: flex;
    width: 90%;
  }
  .change-form-item__top_icon {
    margin-right: 15px;
  }
  .change-form-item__top_icon img {
    margin-bottom: 0;
    height: 60px;
  }
}
</style>
