<template>
    <div class="subscription-product-card"
             :class="item.status !== 'Активен' && 'inactive'"
             :data-item="index"
        >
          <div class="subscription-product-card__panel">
            <div class="subscription-product-card__top">
              <div class="subscription-product-card__top_icon">
                <img
                  :src="getIconUrl"
                  :alt="getIconAlt"
                  :style="{ background: getIconBackground }">
              </div>
              <div class="subscription-product-card__top_price">
                {{ item.premMonth | formatMoney }} ₸ / мес
              </div>
            </div>
            <div class="subscription-product-card__title">
              {{ item.insrTypeName.replace(/ по подписке/g, '')}} <br> по подписке
            </div>
            <div class="subscription-product-card__description" v-show="item.firstTMPrice">
              За первые три месяца от {{ item.firstTMPrice | formatMoney }} ₸
            </div>
            <ul class="subscription-product-card__information_list">
              <li v-if="(item.insrType).includes('3014') || (item.insrType).includes('3016')">
                Адрес <span>{{ item.variableParams.IMU_ADDRESS }}</span>
              </li>
              <li v-if="(item.insrType).includes('2225') && item.variableParams.CASKO_VEHICLE_NO">Гос номер
                <span>{{ item.variableParams.CASKO_VEHICLE_NO }}</span>
              </li>
              <li>{{ item.tarif }} <i>(тип покрытия)</i> <span>{{ item.insSum | formatMoney }}</span></li>
              <li>Дата оформления <span>{{ setDate }}</span></li>
            </ul>
          </div>
          <div
            class="subscription-product-card__btn"
            :style="{ background: gitBtnBackground }"
          >
            {{ item.status === 'Активен' ? 'Текущая подписка' : 'Подписка отключена'}}
          </div>
        </div>
</template>

<script>
// Utils
import productIconInfo from '@/mixin/productIconInfo'
import moment from 'moment'

export default {
  name: 'SubscriptionsMiniSliderItem',
  mixins: [productIconInfo],
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    index: Number
  },
  computed: {
    setDate () {
      return moment(this.item.dateGiven).format('DD.MM.YYYY')
    }
  }
}
</script>

<style lang="css">
  .subscription-product-card {
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, .2);
    transform: scale(1);
    margin-bottom: 74px;
    border-radius: 20px;
    position: relative;
    margin-top: 60px;
    transition: .2s;
    display: block;
    width: 380px;
  }
  .center .subscription-product-card {
    transform: scale(1.02);
  }
  .subscription-product-card:hover {
    transform: scale(1.04);
  }
  .subscription-product-card__panel {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    display: block;
    padding: 25px;
    z-index: 3;
  }
  .subscription-product-card__top {
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-top: -70px;
    display: flex;
  }
  .subscription-product-card__top_icon img {
    height: 85px;
  }
  .subscription-product-card__top_price {
    line-height: 140%;
    font-weight: 600;
    font-size: 16px;
    color: #303030;
  }
  .subscription-product-card__title {
    letter-spacing: -0.03em;
    margin-bottom: 5px;
    line-height: 34px;
    font-weight: 400;
    font-size: 30px;
    color: #303030;
  }
  .subscription-product-card__description {
    margin-bottom: 15px;
    line-height: 140%;
    font-weight: 400;
    font-size: 14px;
    color: #646B69;
  }
  .subscription-product-card__information_list {
    letter-spacing: -0.03em;
    line-height: 34px;
    list-style: none;
    font-size: 14px;
    color: #303030;
    padding: 0;
    margin: 0;
  }
  .subscription-product-card__information_list > li >span {
    float: right;
  }
  .subscription-product-card__btn {
    box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, .2);
    padding: 35px 20px 15px;
    border-radius: 10px;
    position: absolute;
    text-align: center;
    line-height: 14px;
    font-weight: 600;
    font-size: 16px;
    display: block;
    bottom: -46px;
    width: 340px;
    color: #fff;
  }
  .subscription-product-card.inactive {
    filter: grayscale(1);
  }

  @media(max-width:992px) {
    .subscription-product-card__top_icon img {
      height: 75px;
    }
  }
  @media(max-width:766px) {
    .subscription-product-card {
      width: 300px;
    }
    .subscription-product-card__title {
      line-height: 30px;
      font-size: 24px;
    }
    .subscription-product-card__information_list {
      line-height: 30px;
      font-size: 12px;
    }
    .subscription-product-card__btn {
      width: 260px;
    }
  }
</style>
