<template>
  <div class="row">
    <div class="col-7">
      <div class="login-block">
        <div class="login-block__title">
          Введите данные <br>
          <span v-if="!loginError">для поиска</span>
          <span v-else style="color: red"><b v-html="loginError"></b></span>
        </div>
        <div class="row" v-if="!authUser">
          <div class="col-6 pr">
            <background-loader v-if="loading"/>
            <form>
              <text-field
                inputType="text"
                label="Мобильный телефон"
                mask="+7 (###) ### ## ##"
                v-model="form.phone_number"
                idFor="mobile"
                :error="errorFromDB.phone_number"
                v-on:change.native="loginError = ''; errorFromDB.phone_number = ''"
              />
              <span class="login-block__phone-info min" v-html="subtitle_for_sms"></span>
              <text-field
                label="ИИН"
                v-model="form.individual_id_number"
                inputType="text"
                idFor="iin"
                mask="############"
                :error="errorFromDB.individual_id_number"
                v-on:change.native="loginError = ''; errorFromDB.individual_id_number = ''"
                @enter="onSubmit"
              />
              <button
                class="btn orange border-radius block"
                @click.prevent="onSubmit"
                :disabled="!invalid"
              >
                Войти
              </button>
            </form>
          </div>
          <div class="col-6">
            <span class="login-block__phone-info max" v-html="subtitle_for_sms" v-if="!authUser"></span>
          </div>
        </div>
        <otp
          v-if="codeStatus"
          @code="otpModal"
          @resend="otpResend"
          @close="otpClose"
          :error="errorFromDB.error"
          :loader="modalLoading"
          :phone="form.phone_number"
        />
      </div>
    </div>
    <div class="col-5"></div>
  </div>
</template>

<script>
// Vuex
import { mapActions } from 'vuex'
// Loader
import BackgroundLoader from '@/components/loaders/BackgroundLoader'
// Fields
import TextField from '@/components/fields/TextField'
import Otp from '@/components/subscription/Modals/Otp'
// Utils
import setUserName from '@/mixin/setUserName'

export default {
  name: 'AuthOTP',
  mixins: [setUserName],
  components: {
    BackgroundLoader,
    TextField,
    Otp
  },
  data: () => ({
    form: {
      phone_number: '',
      individual_id_number: ''
    },
    loading: false,
    invalid: false,
    loginError: '',
    errorFromDB: {
      phone_number: '',
      individual_id_number: '',
      error: ''
    },
    modalLoading: false,
    codeStatus: false,
    subtitle_for_sms: 'Укажите номер Вашего телефона <br/> (потребуется подтверждение по SMS)'
  }),
  methods: {
    ...mapActions(['signIn', 'getAuthUser', 'logOut', 'otp', 'login']),
    forms: function () {
      this.invalid = this.form.phone_number.length > 17 && this.form.individual_id_number.length > 11
    },
    onSubmit () {
      this.loading = true
      const data = {
        phone_number: this.form.phone_number.replace(/\D+/g, '').slice(1),
        individual_id_number: this.form.individual_id_number
      }
      this.otp(data)
        .then((res) => {
          if (res.data.message === 'ok') {
            this.codeStatus = true
          } else {
            console.log(res.data)
          }
        })
        .catch(err => {
          this.errorFromDB.individual_id_number = err.response.data.message
        })
    },
    accountLogOut () {
      this.loading = true
      this.logOut()
        .then(res => {
          if (res.status === 200) {
            this.loading = false
          }
        })
    },
    otpModal (val) {
      if (val.length === 4) {
        this.modalLoading = true
        this.errorFromDB.error = ''
        const data = {
          phone_number: this.form.phone_number.replace(/\D+/g, '').slice(1),
          code: val
        }
        this.login(data)
          .then(res => {
            this.getAuthUser()
            this.codeStatus = false
            this.loading = false
          })
          .catch(err => {
            if (err.response.data.message) {
              this.errorFromDB.error = err.response.data.message
            }
            this.modalLoading = false
          })
      } else {
        this.errorFromDB.error = ''
      }
    },
    otpResend (val) {
      if (val) {
        this.onSubmit()
      }
    },
    otpClose (val) {
      if (val) {
        this.codeStatus = false
        this.loading = false
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.forms()
      }
    }
  }
}
window.setDeviceId = function (DEVICE_ID) {
  window.DEVICE_ID = DEVICE_ID
}
</script>

<style scoped>
.login-block {
  margin-bottom: 40px;
  position: relative;
}
.login-block__title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
}
.login-block__title span {
  font-weight: 200;
  font-size: 14px;
  color: #646B69;
}
.login-block__phone-info {
  padding-top: 7px;
  font-size: 13px;
  display: block;
}
.login-block__phone-info.max {
  font-size: 12px;
  display: block;
}
.login-block__phone-info.min {
  display: none;
}

@media (max-width: 1000px) {
  .login-block__phone-info.max {
    display: none;
  }
  .login-block__phone-info.min {
    margin: -12px 0 12px 0;
    font-size: 11px;
    display: block;
  }
}
</style>
