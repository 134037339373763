import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import subscriptions from '@/store/subscriptions'
import dictionary from '@/store/dictionary'
import auth from '@/store/auth'
import lang from '@/store/lang'
import cms from '@/store/cms'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    subscriptions,
    dictionary,
    auth,
    lang,
    cms
  }
})
