<template>
  <section class="product-tariffs-block">
    <p class="product-tariff-title">На выбор предоставляем Вам 3 тарифа:</p>
    <div class="product-tariff">
      <product-tariff-item v-for="(item, index) in info" :key="index + Math.random()" :item="item"/>
    </div>
  </section>
</template>

<script>
// Widgets
import ProductTariffItem from '@/components/subscription/ProductTariffs/ProductTariffItem'

export default {
  name: 'ProductTariffs',
  components: {
    ProductTariffItem
  },
  props: {
    info: {
      type: Array
    }
  },
  data: () => ({
    product_tariffs: [
      {
        icon: {
          url: require('@/assets/images/tariff1.png'),
          alt: 'tariff-lite'
        },
        title: '“Легкий”',
        price: 1000000
      },
      {
        icon: {
          url: require('@/assets/images/tariff2.png'),
          alt: 'tariff-standart'
        },
        title: '“Стандартный”',
        price: 2000000
      },
      {
        icon: {
          url: require('@/assets/images/tariff3.png'),
          alt: 'tariff-optimal'
        },
        title: '“Оптимальный”',
        price: 3000000
      }
    ]
  })
}
</script>

<style lang="css">
  .product-tariff {
    display: flex;
    max-width: 570px;
    justify-content: space-around;
    margin: 0 auto 30px;
  }
  .product-tariff-title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 111%;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
  }
  @media (max-width: 700px) {
    .product-tariff {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
</style>
