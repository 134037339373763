import axios from 'axios'
import {
  convertInsuranceProducts,
  getInfoOneProduct
} from '@/utils/insuranceProducts'

export default {
  state: {
    insuranceProducts: null,
    apartmentProducts: null,
    houseProducts: null,
    cascoProducts: null
  },
  mutations: {
    SET_PRODUCTS (state, payload) { state.insuranceProducts = payload },
    SET_APARTMENT_PRODUCTS (state, payload) { state.apartmentProducts = payload },
    SET_HOUSE_PRODUCTS (state, payload) { state.houseProducts = payload },
    SET_CASCO_PRODUCTS (state, payload) { state.cascoProducts = payload }
  },
  actions: {
    getInsuranceProducts ({ commit, state }) {
      return axios.get('/web/insurance-web-products')
        .then(res => {
          const convertProducts = convertInsuranceProducts(res.data.products)
          convertProducts.forEach(async item => {
            if (item.namePage === 'Apartment' && !state.apartmentProducts) {
              commit('SET_APARTMENT_PRODUCTS', await getInfoOneProduct(item.id, item))
            }
            if (item.namePage === 'MyHouse' && !state.houseProducts) {
              commit('SET_HOUSE_PRODUCTS', await getInfoOneProduct(item.id, item))
            }
            if (item.namePage === 'CascoLite' && !state.cascoProducts) {
              commit('SET_CASCO_PRODUCTS', await getInfoOneProduct(item.id, item))
            }
          })
          commit('SET_PRODUCTS', convertProducts)
        })
        .catch((e) => {
          console.log('Ошибка store/cms getInsuranceProducts', e)
        })
    }
  },
  getters: {
    insuranceProducts: state => state.insuranceProducts,
    insuranceProductsMini: state => {
      return state.insuranceProducts.filter(obj => obj.link_to_design)
    },
    apartmentProducts: state => state.apartmentProducts,
    houseProducts: state => state.houseProducts,
    cascoProducts: state => state.cascoProducts
  }
}
