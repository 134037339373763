<template>
  <div class="add-btn-block">
    <div class="icon-box">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1V9H17" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.5" d="M1 9H9V17" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <span class="btn-text">Добавить застрахованного</span>
  </div>
</template>

<script>
export default {
  name: 'AddPersonButton'
}
</script>

<style scoped>
  .add-btn-block {
    display: flex !important;
    align-items: center !important;
  }
  .icon-box {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #f1f2f6;
    box-sizing: border-box;
    border-radius: 12px;
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem !important;
    margin-bottom: 0.2rem;
  }
  .btn-text {
    display: block;
    font-size: inherit;
    color: #2aa65c;
  }

  @media (max-width: 766px) {
    .add-btn-block {
      width: 100%;
    }
  }
</style>
