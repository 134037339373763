<template>
  <section>
      <div class="calendar-item" :class="item.isPaid ? 'calendar-item-paid' : 'calendar-item-unpaid'">
        <span>{{ item.dayNextPayment || item.isPaid ? item.month : '' }}</span>
        <span class="calendar-icon" :class="item.isPaid ? 'calendar-icon-paid' : 'calendar-icon-unpaid'">
          <b>{{ (item.dayNextPayment && !item.isPaid) || item.isPaid ? item.dayNextPayment : item.month }}</b>
        </span>
        <span>{{ item.price }} ₸</span>
      </div>
  </section>
</template>

<script>

export default {
  name: 'Calendar',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style lang="css">
  .calendar-title {
    background: #F9F9F9;
    border-radius: 3px;
    text-align: center;
    font-family: Manrope, sans-serif;
    margin: 10px 0;
    font-weight: 700;
    font-size: 11px;
    line-height: 227%;
    padding: 3px 0;
  }
  .calendar-block {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .calendar-item {
    width: 88px;
    height: 88px;
    border-radius: 3px;
    margin: 0 10px 10px 0;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .calendar-item-paid {
    background: #12AB6B;
  }
  .calendar-item-unpaid {
    background: #B4B4B4;
  }
  .calendar-item > span {
    font-family: Museo Sans Cyrl, sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    color: #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .calendar-icon > b {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
  }
  .calendar-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .calendar-icon-paid {
    background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 0C7.1776 0 0 7.1776 0 16C0 24.8224 7.1776 32 16 32C24.8224 32 32 24.8224 32 16C32 7.1776 24.8224 0 16 0ZM22.8488 13.2488L14.8488 21.2488C14.6144 21.4832 14.3072 21.6 14 21.6C13.6928 21.6 13.3856 21.4832 13.1512 21.2488L9.1512 17.2488C8.6824 16.78 8.6824 16.0208 9.1512 15.552C9.62 15.0832 10.3792 15.0832 10.848 15.552L13.9992 18.7032L21.1504 11.552C21.6192 11.0832 22.3784 11.0832 22.8472 11.552C23.316 12.0208 23.3168 12.78 22.8488 13.2488Z" fill="white"/></svg>');
    background-color: #12AB6B;
  }
  .calendar-icon-unpaid {
    /*border: 1px solid #FFFFFF;*/
    background: #B4B4B4;
  }
</style>
