import moment from 'moment'

export function convertPersonForBack (val, isOwner = false) {
  const data = {
    address_city: parseInt(val.address_city),
    address_flat_no: val.address_flat_no,
    address_region: parseInt(val.address_region),
    address_state: val.address_state,
    address_street_full: val.address_street_full,
    address_street_no: val.address_street_no,
    birth_date: val.birth_date,
    checked: val.checked,
    doc_issue_date: moment(val.doc_issue_date).format('YYYY-MM-DD'),
    doc_number: val.doc_number,
    doc_type: val.doc_type,
    gender: val.gender,
    individual_id_number: val.individual_id_number,
    new_client: val.new_client,
    uuid: val.uuid
  }
  if (isOwner) {
    const owner = {
      first_name: val.first_name,
      last_name: val.last_name,
      s_name: val.s_name
    }
    Object.assign(data, owner)
  }

  return data
}
export function convertFormForBack (val) {
  return {
    draft_id: val.draft_id,
    phone_number: val.phone_number.replace(/\D/g, '').slice(1),
    insurance_type: val.insurance_type,
    holder: convertPersonForBack(val.holder),
    start_date: moment(val.start_date).format('YYYY-MM-DD'),
    owner_is_holder: val.owner_is_holder,
    property_owner: !val.owner_is_holder ? convertPersonForBack(val.property_owner) : val.property_owner,
    insured_property: {
      country: val.insured_property.country,
      state: val.insured_property.state,
      region: parseInt(val.insured_property.region),
      city: parseInt(val.insured_property.city),
      street_name: val.insured_property.street_name,
      street_no: val.insured_property.street_no,
      flat_no: val.insured_property.flat_no
    },
    tariff: val.tariff
  }
}
export function convertFormForBackCasco (val) {
  const insureds = []
  if (val.insureds.length > 0) {
    val.insureds.forEach(obj => {
      insureds.push(convertPersonForBack(obj))
    })
  }
  return {
    draft_id: val.draft_id,
    phone_number: val.phone_number.replace(/\D/g, '').slice(1),
    insurance_type: val.insurance_type,
    holder: convertPersonForBack(val.holder),
    start_date: moment(val.start_date).format('YYYY-MM-DD'),
    insureds,
    vehicle: {
      uuid: val.vehicle.uuid,
      license_plate: val.vehicle.license_plate,
      model: val.vehicle.model,
      territory: val.vehicle.territory,
      type: val.vehicle.type,
      year: val.vehicle.year
    },
    tariff: val.tariff
  }
}

// Для формирование данных при возобновлении подписок
export function convertPersonForBackResubscribe (val, isOwner = false) {
  const address = val.addresses[0]
  const documents = val.documents[0]
  const data = {
    address_city: parseInt(address.address_city),
    address_flat_no: address.address_flat_no,
    address_region: parseInt(address.address_region),
    address_state: address.address_state,
    address_street_full: address.address_street,
    address_street_no: address.address_street_no,
    birth_date: val.birthDate.date,
    checked: true,
    client_id: val.manID,
    doc_issue_date: moment(documents.doc_issue_date.date).format('YYYY-MM-DD'),
    doc_number: documents.doc_number,
    doc_type: documents.doc_type,
    gender: val.sex,
    individual_id_number: val.bin,
    new_client: false,
    uuid: val.uuid
  }
  if (isOwner) {
    const owner = {
      first_name: val.fName,
      last_name: val.lName,
      s_name: val.sName
    }
    Object.assign(data, owner)
  }

  return data
}
export function convertFormForBackResubscribe (val) {
  const d = new Date()
  d.setDate(d.getDate() + 1)
  const object = val.objects[0]
  return {
    draft_id: val.draft_id,
    phone_number: val.holder.contacts.MOBILE.replace(/\D/g, '').slice(1),
    insurance_type: parseInt(val.insrType),
    holder: convertPersonForBackResubscribe(val.holder),
    start_date: moment(d).format('YYYY-MM-DD'),
    owner_is_holder: true,
    property_owner: null,
    insured_property: {
      country: 'KAZ',
      state: object.state,
      region: object.region,
      city: object.city,
      street_name: object.street_name,
      street_no: object.blockNumber,
      flat_no: object.apartmentNumber
    },
    tariff: val.tariff
  }
}

export function convertFormForBackResubscribeCasco (val) {
  const d = new Date()
  d.setDate(d.getDate() + 1)
  const object = val.objects[0]
  const insureds = []
  if (val.insureds.length > 0) {
    val.insureds.forEach(obj => {
      insureds.push(convertPersonForBackResubscribe(obj))
    })
  }
  return {
    draft_id: val.draft_id,
    phone_number: val.holder.contacts.MOBILE.replace(/\D/g, '').slice(1),
    insurance_type: parseInt(val.insrType),
    holder: convertPersonForBackResubscribe(val.holder),
    start_date: moment(d).format('YYYY-MM-DD'),
    insureds,
    vehicle: {
      uuid: object.uuid,
      license_plate: object.license_plate,
      model: object.model,
      territory: object.territory,
      type: object.type,
      year: object.year
    },
    tariff: val.tariff
  }
}
