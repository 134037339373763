<template>
  <section class="product-block" v-if="insuranceProductsMini">
    <div class="container pr">
      <h1 class="product-block__title">Продукты</h1>
<!--      <p class="product-block__subtitle">-->
<!--        Мы хотим, чтобы каждый человек чувствовал себя защищённым и знал, что в случае непредвиденной ситуации он не останется наедине со своими проблемами.-->
<!--      </p>-->
      <carousel
        :items="5"
        :margin="20"
        :dots="false"
        :center="true"
        :autoWidth="true"
        :loop="true"
        :responsive="{766:{items:1}}"
      >
        <div class="product-card" v-for="(card, index) in insuranceProductsMini" :key="index + Math.random()">
          <router-link :to="{ name: card.namePage }" class="product-card__panel">
            <div class="product-card__top">
              <div class="product-card__top_icon">
                <img :src="card.iconProduct.url" :alt="card.iconProduct.alt" :style="{ background: card.iconProduct.background }">
              </div>
              <div class="product-card__top_price">
                от {{ card.priceMonth }} ₸ / мес
              </div>
            </div>
            <div class="product-card__title">
              {{ card.titleProductCard }} <br> по подписке
            </div>
            <div class="product-card__description">
              {{ card.firstTMPrice }}
            </div>
            <div class="product-card__info">
              <div class="product-card__info_title">ЧТО ВХОДИТ</div>
              <ul class="product-card__info_list">
                <li v-for="(item, index) in card.lineup" :key="index">{{ item }}</li>
              </ul>
            </div>
            <div class="product-card__coating" v-html="card.coating">
<!--              <div class="product-card__coating_title">Тип покрытия</div>-->
<!--              <ul class="product-card__coating_list">-->
<!--                <li v-for="(item, index) in card.tafiffs" :key="index">{{ item.title }} <span>до {{ item.coating }} ₸</span></li>-->
<!--              </ul>-->
            </div>
          </router-link>
          <router-link :to="{ name: card.namePage }" class="product-card__btn" :style="{ background: card.additionBackground }">Подробнее</router-link>
        </div>
      </carousel>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'
import { mapGetters } from 'vuex'

export default {
  name: 'Products',
  components: { carousel },
  data: () => ({
    cards: [
      {
        icon: {
          url: require('@/assets/images/icon-product1.svg'),
          alt: 'icon-product1.svg',
          background: '#FFFFFF'
        },
        priceMonth: 490,
        title: 'Страхование квартиры',
        firstTMPrice: 270,
        coverData: [
          { name: 'Легкая', price: 1000000 },
          { name: 'Стандартная', price: 2000000 },
          { name: 'Оптимальная', price: 3000000 }
        ],
        lineup: [
          { name: 'потоп' },
          { name: 'пожар' },
          { name: 'кража' },
          { name: 'залив соседей снизу' }
        ],
        additionBackground: '#017C66',
        link: { name: 'Apartment' }
      },
      {
        icon: {
          url: require('@/assets/images/icon-product2.svg'),
          alt: 'icon-product2.svg',
          background: ''
        },
        priceMonth: 390,
        title: 'Страхование дома',
        firstTMPrice: 270,
        coverData: [
          { name: 'Легкая', price: 1000000 },
          { name: 'Стандартная', price: 2000000 },
          { name: 'Оптимальная', price: 3000000 }
        ],
        lineup: [
          { name: 'потоп' },
          { name: 'пожар' },
          { name: 'кража' }
        ],
        additionBackground: '#12AB6B',
        link: { name: 'MyHouse' }
      },
      {
        icon: {
          url: require('@/assets/images/icon-product3.svg'),
          alt: 'icon-product3.svg',
          background: ''
        },
        priceMonth: 690,
        title: 'Каско',
        firstTMPrice: 570,
        coverData: [
          { name: 'Легкая', price: 200000 },
          { name: 'Стандартная', price: 300000 },
          { name: 'Оптимальная', price: 400000 }
        ],
        lineup: [
          { name: 'доплата по основному полису' },
          { name: 'отсутсвие ОГПО у другого водителя' }
        ],
        additionBackground: '#00C1C1',
        link: { name: 'CascoLite' }
      }
    ]
  }),
  computed: {
    ...mapGetters(['insuranceProductsMini'])
  }
}
</script>

<style lang="css" scoped>

</style>
