<template>
  <div class="tariff-form">
    <p class="tariff-title">Тарифы</p>
    <div class="tariff-panel">
      <div class="tariff-item" v-for="(item, key) in value" :key="key">
        <input type="radio" name="accordion" :id="'cb'+key" :value="key" :checked="key === 0" v-model="selected">
        <span class="checked"></span>
        <section class="box">
          <label :for="'cb'+key" class="box-title"> {{ item.title }} <b>{{ item.price | formatMoney }} ₸/мес</b></label>
          <div class="box-content">
            <hr>
            <ul class="list-price">
              <li v-if="item.to && insurance_type === 3014"><span class="title-list">Выплата Вам </span><span class="price">{{ item.to | formatMoney }} ₸</span></li>
              <li v-if="item.to && [3016, 2225].includes(insurance_type)"><span class="title-list">Выплата Вам </span><span class="price">{{ item.to * 2 | formatMoney }} ₸</span></li>
              <li v-if="item.from && insurance_type === 3014"><span class="title-list">Выплата соседям </span><span class="price">{{ item.from | formatMoney }} ₸</span></li>
              <li v-if="item.total && insurance_type === 3014"><span class="title-list">Возместим итого  </span><span class="price">{{ item.total | formatMoney }} ₸</span></li>
            </ul>
            <hr>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tariff',
  props: {
    value: Array,
    insurance_type: Number
  },
  data: () => ({
    selected: 0
  }),
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler (newValue) {
        this.$emit('changed', newValue)
      }
    }
  }
}
</script>

<style scoped>
.tariff-form {
  background: #FFFFFF;
  box-shadow:  0 5px 10px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 16px;
}
p.tariff-title {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
}
.tariff-item {
  margin-bottom: 10px;
  position: relative;
}
.tariff-item ul.list-price {
  font-size: 13px;
}
.tariff-item input {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 0;
}
.tariff-item label.box-title {
  position: relative;
  padding-right: 25px;
  margin-bottom: 5px;
  display: block;
  cursor: pointer;
  z-index: 2;
}
.tariff-item label.box-title b {
  float: right;
}
.tariff-item .box-content {
  color: #99a3b3;
  display: none;
}
.tariff-item input:checked ~ .box .box-content  {
  display: block;
}
.tariff-item .box-content span.price {
  float: right;
}
.tariff-item hr {
  color: #C8C7CC !important;
  margin: 15px 0 10px;
  opacity: .3;
}
.tariff-item hr:last-child {
  margin: 10px 0 15px;
}
.tariff-item input {
  position: absolute;
  opacity: 0;
}
.tariff-item span.checked {
  padding-right: 30px;
  position: relative;
  cursor: pointer;
  display: block;
  z-index: 1;
}
.tariff-item span.checked:before {
  border: 1px solid #11bb91;
  border-radius: 50%;
  position: absolute;
  background: #fff;
  display: block;
  height: 16px;
  content: "";
  width: 16px;
  top: 0;
  right: 0;
}
.tariff-item span.checked:after {
  justify-content: center;
  align-content: center;
  align-items: flex-end;
  background: #11bb91;
  position: absolute;
  border-radius: 50%;
  flex-wrap: nowrap;
  transition: .25s;
  content: "";
  font-size: 12px;
  display: flex;
  height: 12px;
  color: #fff;
  width: 12px;
  opacity: 0;
  right: 3px;
  top: 3px;
}
.tariff-item input:checked+span.checked:after {
  opacity: 1;
}
</style>
