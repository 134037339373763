import showPaymentWidget from '@/utils/showPaymentWidget'
import { convertPersonForBack } from '@/utils/person'
import { mapActions, mapGetters } from 'vuex'
import { uuidv4 } from '@/utils/uuidv4'
import axios from 'axios'

export default {
  data: () => ({
    newClient: false,
    form: {
      draft_id: null,
      phone_number: '',
      holder: {
        individual_id_number: '',
        first_name: '',
        last_name: '',
        s_name: '',
        birth_date: '',
        gender: '',
        doc_type: '',
        doc_number: '',
        doc_issue_date: '',
        address_region: null,
        address_state: '',
        address_city: null,
        address_street_full: '',
        address_street_no: '',
        address_flat_no: '',
        new_client: false,
        checked: true,
        uuid: ''
      },
      start_date: '',
      tariff: ''
    },
    finalAmount: {
      toPayment: 0,
      discount: 0,
      cost: 0,
      res: null
    },
    step: 0,
    formLoader: false,
    temp_token: null,
    otp: {
      error: '',
      loading: false
    },
    productAuthRes: null,
    paymentResult: null
  }),
  computed: {
    ...mapGetters({
      countryStates: 'dictionary/countryStates',
      stateRegions: 'dictionary/stateRegions',
      regionCities: 'dictionary/regionCities',
      countries: 'dictionary/countries',
      cityList: 'dictionary/cityList',
      regions: 'dictionary/regions',
      states: 'dictionary/states',
      cities: 'dictionary/cities',
      authUser: 'authUser'
    }),
    tomorrow () {
      const d = new Date()
      d.setDate(d.getDate() + 1)
      return d
    }
  },
  async created () {
    if (this.authUser) {
      this.form.phone_number = '+7(' + this.authUser.phone_number
      this.form.holder.individual_id_number = this.authUser.individual_id_number
      await this.sendDraft()
    } else {
      this.form.phone_number = '+7('
      this.newClient = true
    }
    this.form.draft_id = uuidv4()
    this.form.start_date = this.tomorrow

    const isHaveDictionary = this.countryStates !== {} &&
      this.stateRegions !== {} && this.regionCities !== {} && this.countries.length > 0 &&
      this.regions.length > 0 && this.states.length > 0 && this.cities.length > 0
    try {
      if (!isHaveDictionary) await this.getUnionAddressDictionary()
    } catch (err) {
      console.log({
        title: 'Ошибка при получение данных справочника',
        message: (err?.response?.data?.message || err?.message),
        code: err?.response?.data?.error_code || 0
      })
    }

    try {
      if (this.cityList.length === 0) await this.getCityList()
    } catch (err) {
      console.log({
        title: 'Ошибка при получение городов Казахстана',
        message: (err?.response?.data?.message || err?.message),
        code: err?.response?.data?.error_code || 0
      })
    }
  },
  mounted () {
    if (!this.newClient && !this.authUser) this.importTariff()
  },
  methods: {
    ...mapActions({
      getUnionAddressDictionary: 'dictionary/getUnionAddressDictionary',
      getCityList: 'dictionary/getCityList'
    }),
    readyHolder (val) {
      if (val && this.step === 1) {
        this.step = 4
        this.importTariff()
      } else {
        this.step = 1
      }
    },
    // Get Holder
    getForm (val) {
      if (val) {
        this.form.holder.individual_id_number = val.individual_id_number
        this.form.holder.checked = val.checked
        this.form.holder.uuid = val.uuid
      }
    },
    getUser (val) {
      if (val) {
        this.form.holder.first_name = val.first_name
        this.form.holder.birth_date = val.birth_date
        this.form.holder.last_name = val.last_name
        this.form.holder.s_name = val.s_name
        this.form.holder.gender = val.gender
      }
    },
    getLocation (val) {
      if (val) {
        this.form.holder.address_region = val.address_region
        this.form.holder.address_state = val.address_state
        this.form.holder.address_city = val.address_city
        this.form.holder.address_street_full = val.address_street
        this.form.holder.address_street_no = val.address_street_no
        this.form.holder.address_flat_no = val.address_flat_no
      }
    },
    getDocument (val) {
      if (val) {
        this.form.holder.doc_issue_date = val.doc_issue_date
        this.form.holder.doc_number = val.doc_number
        this.form.holder.doc_type = val.doc_type
      }
    },
    // Modal Status
    getModalStatus (val) {
      if (!val) {
        this.finalAmount.res = null
        this.step = 5
      } else {
        this.otp.error = ''
        this.otp.loading = true
        this.step = 7
        this.otpGenerate(this.finalAmount.res.id)
          .finally(() => {
            this.otp.loading = false
          })
      }
    },
    // Check
    checkToken () {
      let prefix = ''
      if (!this.authUser && this.temp_token) {
        axios.defaults.headers.common['temp-token'] = `${this.temp_token}`
        prefix = '/web'
      }
      return prefix
    },
    checkClients () {
      axios.post('/verified-client-csdb', convertPersonForBack(this.form.holder))
        .then(res => {
          console.log(res.status)
        })
        .catch(err => {
          console.log(err.response.status)
        })
      return true
    },
    // OTP
    otpGenerate (id) {
      return axios.post(this.checkToken() + '/insurance-products/generate-otp/' + id)
        .catch(err => {
          console.log(err)
        })
    },
    otpCode (val) {
      if (val.length === 4) {
        this.otp.loading = true
        this.otp.error = ''
        axios.post(this.checkToken() + '/insurance-products/verify-otp/' + this.finalAmount.res.id, { otp: val })
          .then(res => {
            if (res.data.success) {
              this.codeStatus = false
              this.otp.loading = false
              this.step = 8
              if (this.authUser) {
                showPaymentWidget(this.productAuthRes, this.authUser)
              } else {
                axios.post(this.checkToken() + '/drop-temp-token')
                  .then(res => console.log(res))
                showPaymentWidget(this.productAuthRes, { phone_number: this.form.phone_number.replace(/\D/g, '').slice(1) })
              }
            }
          })
          .catch(err => {
            console.log(err)
            if (err.response.data.message) {
              this.otp.error = err.response.data.message
              this.otp.loading = false
            }
          })
      } else {
        this.otp.error = ''
      }
    },
    otpResend (val) {
      if (val) {
        this.otpGenerate(this.finalAmount.res.id)
          .then(res => console.log(res))
      }
    },
    otpClose (val) {
      if (val) {
        this.otp.error = ''
        this.otp.loading = false
        this.finalAmount.res = null
        this.productAuthRes = null
        this.step = 5
      }
    },
    // Product Auth
    productAuth (val) {
      if (val) this.productAuthRes = val
    }
  },
  watch: {
    step: {
      deep: true,
      handler: function () {
        if (this.form.phone_number) this.sendDraft()
      }
    }
  }
}
