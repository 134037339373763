<template>
  <section class="otp-modal">
    <div class="otp-panel" :class="loader ? 'bg-loading' : ''">
      <div class="close" v-if="timer === 0" v-on:click="close">×</div>
      <div class="otp__title">
        Введите код из SMS, отправленный <br>
        на номер {{ getFilteredPhone }}
      </div>
      <div class="otp-form">
        <otp-field
          v-model="form.code"
          mask="####"
          inputType="text"
        />
        <div class="otp-error" v-if="error" v-html="error"></div>
      </div>
      <div v-show="timer > 0" class="otp-countdown">
        Получить новый код можно через <br>
        <span>{{ timer }} сек</span>
      </div>
      <button v-show="timer <= 0" class="btn lightgreen border-radius block mt-10" v-on:click="startTimer()">
        Отправить код повторно
      </button>
    </div>
  </section>
</template>

<script>
import OtpField from '@/components/fields/OtpField'
export default {
  name: 'Otp',
  props: {
    error: {
      type: String,
      default: null
    },
    loader: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String,
      default: ''
    }
  },
  components: {
    OtpField
  },
  data: () => ({
    form: {
      code: ''
    },
    timer: 300
  }),
  mounted () {
    this.startTimer()
  },
  methods: {
    startTimer: function () {
      if (this.timer === 0) {
        this.$emit('resend', true)
      }
      this.timer = 300
      this.interval = setInterval(() => {
        if (this.timer === 1) {
          clearInterval(this.interval)
        }
        this.timer = this.timer - 1
      }, 1000)
    },
    close () {
      this.$emit('close', true)
    }
  },
  computed: {
    getFilteredPhone () {
      const phone = this.phone
      return '+7 (' + phone.replace(/\D+/g, '').slice(1).slice(0, 3) + ') ***-**-' + phone.substring(phone.length - 2)
    }
  },
  watch: {
    'form.code': {
      deep: true,
      handler: function (newValue) {
        this.$emit('code', newValue)
      }
    }
  }
}
</script>

<style scoped>
  .otp-modal {
    background-color: rgba(0, 0, 0, .3);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 15;
    left: 0;
    top: 0;
  }
  .otp-panel {
    background-color: #fff;
    border-radius: 6px;
    position: relative;
    min-height: 200px;
    padding: 40px;
    width: 340px;
  }
  .otp-panel .close {
    position: absolute;
    line-height: 17px;
    transition: .2s;
    font-size: 30px;
    cursor: pointer;
    color: #99a3b3;
    padding: 20px;
    right: 0;
    top: 0;
  }
  .otp-panel .close:hover {
    color: #000;
  }
  .otp__title {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
  .otp-form {
    box-shadow: 0 12px 16px rgba(0, 0, 0, .05);
    margin: 0 auto 60px auto;
    border-radius: 12px;
    position: relative;
    display: flex;
    width: 230px;
    height: 70px;
  }
  .otp-countdown {
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    color: #99A3B3;
  }
  .otp-countdown span {
    color: #11bb91;
  }
  .otp-modal .bg-loading:after {
    border-radius: 12px;
  }
  .otp-error {
    margin-bottom: 20px;
    position: absolute;
    text-align: center;
    color: #f44336;
    bottom: -60px;
    width: 100%;
    left: 0;
  }
</style>
