<template>
  <div id="app">
    <sv-header/>
    <main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
      <user-main-info v-if="authUser"/>
    </main>
    <v-footer/>
  </div>
</template>

<script>
// Widgets
import UserMainInfo from '@/components/subscription/Basic/UserMainInfo'
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: { UserMainInfo },
  metaInfo: {
    htmlAttrs: {
      lang: 'ru-RU'
    },
    meta: [
      { charset: 'utf-8' },
      { viewport: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
  },
  computed: {
    ...mapGetters(['accessToken', 'authUser'])
  },
  created () {
    if (!this.authUser && this.accessToken) this.getAuthUser()
  },
  methods: {
    ...mapActions(['getAuthUser'])
  }
}
</script>

<style>

</style>
