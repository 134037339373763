<template>
  <modal-layout :visible="visible">
    <p class="modal-title"> Уважаемый клиент </p>
    <p class="modal-subtitle">
      С вашей карты спишется <b>10 тенге</b>, а потом вернется назад на карту
    </p>
    <button
      class="btn sm outline green border-radius mb-10 col-7"
      v-on:click="$emit('change_card')"
    >Хорошо</button>
    <button
      href="#"
      class="btn sm outline green border-radius col-7"
      v-on:click="$emit('close')"
    >Отмена</button>
  </modal-layout>
</template>

<script>
import ModalLayout from '@/components/subscription/Modals/ModalLayout'

export default {
  name: 'ConfirmChangeCardModal',
  components: { ModalLayout },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="css"></style>
