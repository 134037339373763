<template>
  <section class="reviews">
    <services-header/>
    <div class="container" v-if="authUser">
      <div class="reviews-block">
        <gift-friend />
        <div class="reviews-rating-info" :class="loadingComment ? 'bg-loading' : ''">
          <h3>Пожалуйста оцените качество нашего сервиса:</h3>
          <star-rating @changeRating="changeRatingService"/>
          <h3>Пожалуйста оцените представленную линейку бонусов:</h3>
          <star-rating @changeRating="changeRatingBonus"/>
          <h3>Пожалуйста оцените работу менеджера (при его наличии):</h3>
          <star-rating @changeRating="changeRatingManager"/>
          <h3 class="text-center">Комментарии</h3>
          <textarea placeholder="Написать..." v-model="comment"/>
          <button class="btn sm outline green border-radius center" @click="sendReview">Оставить отзыв</button>
          <feedback-rating-modal
            v-if="isFeedbackModal"
            :rating="averageRating"
            @modalStatus="isFeedbackModal = false"
          />
        </div>
      </div>
    </div>
    <services/>
  </section>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'
import axios from 'axios'
// Widgets
import ServicesHeader from '@/components/subscription/ServicesHeader'
import StarRating from '@/components/subscription/StarRating'
import Services from '@/components/subscription/Services'
import GiftFriend from '@/components/subscription/GiftFriend'
// Modals
import FeedbackRatingModal from '@/components/subscription/Modals/FeedbackRatingModal'

export default {
  name: 'Reviews',
  metaInfo: {
    title: 'Отзывы',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    FeedbackRatingModal,
    ServicesHeader,
    GiftFriend,
    StarRating,
    Services
  },
  data: () => ({
    ratingService: null,
    ratingManager: null,
    averageRating: null,
    ratingBonus: null,
    comment: null,
    isFeedbackModal: false,
    loadingComment: false
  }),
  methods: {
    changeRatingService (value) {
      this.ratingService = value
    },
    changeRatingBonus (value) {
      this.ratingBonus = value
    },
    changeRatingManager (value) {
      this.ratingManager = value
    },
    sendReview () {
      this.averageRating = Math.floor((this.ratingService + this.ratingBonus + this.ratingManager) / 3)
      const comment = `Оценки: сервис - ${this.ratingService}, бонусы - ${this.ratingBonus}, менеджер - ${this.ratingManager}; комментарий: '${this.comment}'`
      const data = {
        rating: this.averageRating,
        user_id: this.authUser.id,
        product_id: this.$route.params.insuranceType,
        comment
      }
      this.loadingComment = true
      axios.post('/feedback/', data)
        .finally(() => {
          this.loadingComment = false
          this.isFeedbackModal = true
        })
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'authUser'
    }),
    isValidatePhone () {
      let value = null
      if (this.phone_number) {
        value = this.phone_number.length !== 0 && this.phone_number.length !== 18
      }
      return value
    }
  }
}
</script>

<style lang="css">
.reviews > .container {
  margin-bottom: 50px;
}
.reviews-block {
  max-width: 800px;
  margin: 0 auto;
}
.reviews-rating-info > h3, .reviews-rating-info > h3.text-center {
  line-height: 34px;
  font-size: 25px;
  color: #2A353D;
}
.reviews-rating-info > h3 {
  font-weight: 400;
}
.reviews-rating-info > h3.text-center {
  font-weight: 500;
}
.reviews-rating-info > textarea {
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  background: #EDEDED;
  margin: 30px auto;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #8B8B8B;
  display: block;
  padding: 10px;
  height: 90px;
  border: none;
  outline: none;
  resize: none;
  width: 90%;
}

@media (max-width: 766px) {
  .gift-friend__before > h1 {
    font-size: 36px;
    line-height: 38px;
    margin: 20px 0 30px;
  }
  .gift-friend__before > h2, .gift-friend__after > h2 {
    font-size: 20px;
    line-height: 22px;
  }
  .gift-friend__before > h3, .gift-friend__after > h3 {
    line-height: 15px;
    font-size: 16px;
  }
  .reviews-rating-info > h3, .reviews-rating-info > h3.text-center {
    line-height: 20px;
    font-size: 18px;
  }
  .gift-friend__before, .gift-friend__after {
    margin: 0 auto 30px;
  }
  img.gift-friend__icon-success {
    width: 130px;
    height: 130px;
    margin: 0 auto 20px;
  }
}
</style>
