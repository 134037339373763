<template>
  <div class="form-user" v-if="!valid.readyAfter">
    <div class="form-panel group">
      <text-field
        label="Фамилия"
        v-model="form.last_name"
        inputType="text"
        idFor="insured_l_name"
        v-if="!validate.after.last_name"
      />
      <text-field
        label="Имя"
        v-model="form.first_name"
        inputType="text"
        idFor="insured_f_name"
        v-if="!validate.after.first_name"
      />
      <text-field
        label="Отчество"
        v-model="form.s_name"
        inputType="text"
        idFor="insured_m_name"
        v-if="!validate.after.s_name"
      />
      <date-field
        id="insured_birthday"
        ref="beginDate"
        v-model="form.birth_date"
        label="Дата рождения"
        v-if="!validate.after.birth_date"
      />
      <select-field
        label="Пол"
        v-model="form.gender"
        :options="this.genders"
        idFor="insured_sex"
        v-if="!validate.after.gender"
      />
    </div>
  </div>
</template>

<script>
// Fields
import SelectField from '@/components/fields/SelectField'
import TextField from '@/components/fields/TextField'
import DateField from '@/components/fields/DateField'
export default {
  name: 'User',
  components: {
    SelectField,
    TextField,
    DateField
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    form: {
      first_name: null,
      birth_date: null,
      last_name: null,
      s_name: null,
      gender: null
    },
    genders: [
      { value: 1, title: 'Мужской' },
      { value: 2, title: 'Женский' }
    ],
    validate: {
      after: {
        first_name: null,
        birth_date: null,
        last_name: null,
        s_name: null,
        gender: null
      },
      before: {
        first_name: null,
        birth_date: null,
        last_name: null,
        s_name: null,
        gender: null
      }
    },
    valid: {
      readyAfter: null,
      readyBefore: null
    },
    autoInit: false
  }),
  created () {
    if (this.value) {
      this.initData(this.value)
    }
  },
  mounted () {
    this.validateAfter(this.value)
  },
  methods: {
    initData (val) {
      if (val) {
        this.validateBefore(val)
        this.form.first_name = val.first_name
        this.form.birth_date = val.birth_date
        this.form.last_name = val.last_name
        this.form.s_name = val.s_name
        this.form.gender = val.gender
        this.full_name = val.first_name + ' ' + val.last_name + ' ' + val.s_name
        this.autoInit = true
      }
    },
    validateAfter (val) {
      this.validate.after.first_name = !!val.first_name
      this.validate.after.birth_date = !!val.birth_date
      this.validate.after.last_name = !!val.last_name
      this.validate.after.gender = !!val.gender
      this.valid.readyAfter = !!(
        this.validate.after.first_name &&
        this.validate.after.birth_date &&
        this.validate.after.last_name &&
        this.validate.after.gender
      )
    },
    validateBefore (val) {
      this.validate.before.first_name = !!val.first_name
      this.validate.before.birth_date = !!val.birth_date
      this.validate.before.last_name = !!val.last_name
      this.validate.before.gender = !!val.gender
      this.valid.readyBefore = !!(
        this.validate.before.first_name &&
        this.validate.before.birth_date &&
        this.validate.before.last_name &&
        this.validate.before.gender
      )
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        this.initData(newValue)
      }
    },
    form: {
      deep: true,
      handler (changed) {
        this.$emit('changed', changed)
      }
    },
    valid: {
      deep: true,
      immediate: true,
      handler (validater) {
        this.$emit('validater', validater)
      }
    }
  }
}
</script>

<style scoped>

</style>
