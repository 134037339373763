<template>
  <section class="subscription-block">
    <div class="container">
      <div class="subscription">
        <div class="subscription_column">
          <div class="subscription_title">
            Оформите подписку
          </div>
          <div class="subscription_description">
            <p v-html="item.description_subscription"></p>
          </div>
        </div>
        <div class="subscription_panel">
          <span>
            <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.1702 0.255411L13.8533 2.71138C13.0787 2.65857 12.4801 2.79941 12.0575 3.13392C11.635 3.46842 11.3533 3.93497 11.2125 4.53356C11.0716 5.13215 11.0452 5.80996 11.1332 6.567H14.1702V12.9314H8.33396V6.03883C8.33396 3.96138 8.82691 2.39448 9.81282 1.33815C10.8163 0.264214 12.2688 -0.0966993 14.1702 0.255411ZM6.03643 0.255411L5.71953 2.71138C4.94489 2.65857 4.3463 2.79941 3.92377 3.13392C3.50124 3.46842 3.21955 3.93497 3.0787 4.53356C2.93786 5.13215 2.91145 5.80996 2.99948 6.567H6.03643V12.9314H0.200195V6.03883C0.200195 3.96138 0.693151 2.39448 1.67906 1.33815C2.68258 0.264214 4.13503 -0.0966993 6.03643 0.255411Z" fill="white"/>
            </svg>
          </span>
          <p v-html="item.quote"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Subscription',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
