<template>
  <section class="change-card">
    <services-header/>
    <services-body
      v-if="authUser"
      :status="subStatuses.activeSub"
      empty="Вы пока ничего не застраховали, но всё ещё впереди!"
    >
      <div class="container">
        <div class="row">
          <div class="col-7">
            <div class="change-form-block">
              <p class="page-subtitle"><b>Текущие карты</b></p>
              <product-card-item
                v-for="(card, index) in activeSub"
                :key="index + Math.random()"
                :item="card"
                @change_card="changeCardModal(card.policyId)"
              />
              <confirm-change-card-modal
                :visible="isOpenModal"
                @close="isOpenModal = false"
                @change_card="changeCard"
                v-if="isOpenModal"
              />
            </div>
          </div>
          <div class="col-5"></div>
        </div>
      </div>
    </services-body>
    <services/>
  </section>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'
// Widgets
import ProductCardItem from '@/components/subscription/ProductCardItem'
import ServicesHeader from '@/components/subscription/ServicesHeader'
import ServicesBody from '@/components/subscription/ServicesBody'
import Services from '@/components/subscription/Services'
// Modals
import ConfirmChangeCardModal from '@/components/subscription/Modals/ConfirmChangeCardModal'
// Utils
import showPaymentWidget from '@/utils/showPaymentWidget'
import ePayPayment from '@/mixin/ePayPayment'
import axios from 'axios'

export default {
  name: 'ChangeCard',
  mixins: [ePayPayment],
  metaInfo: {
    title: 'Сменить карту',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    ConfirmChangeCardModal,
    ProductCardItem,
    ServicesHeader,
    ServicesBody,
    Services
  },
  data: () => ({
    isOpenModal: false,
    selectedPolicyId: null
  }),
  methods: {
    changeCardModal (policyId) {
      this.selectedPolicyId = policyId
      this.isOpenModal = true
    },
    changeCard () {
      this.isOpenModal = false
      axios.post('/subscription/auth-change-card', {
        policy_id: this.selectedPolicyId
      })
        .then(res => {
          showPaymentWidget(res.data, this.authUser)
        })
        .catch(err => {
          console.log('ошибка changeCard', err)
        })
    }
  },
  computed: {
    ...mapGetters({
      subStatuses: 'subStatuses',
      activeSub: 'activeSub',
      authUser: 'authUser'
    })
  }
}
</script>

<style lang="css">
.change-card > .container {
  margin-bottom: 50px;
}
</style>
