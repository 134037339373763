/* eslint-disable */
export default function (invoice, user) {
  const createPaymentObject = function (invoice, user) {
    // Ссылка при успешной оплате
    const backLink = `${location.origin}/success-payment?calculationId=${invoice.calculationId}&amount=${invoice.amount}&invoiceId=${invoice.id}`
    // Ссылка при неуспешной оплате
    const failureBackLink = `${location.origin}/fail-payment?calculationId=${invoice.calculationId}&amount=${invoice.amount}&invoiceId=${invoice.id}`
    return {
      invoiceId: invoice.uniqRef,
      backLink: backLink,
      failureBackLink: failureBackLink,
      postLink: invoice.postLink,
      failurePostLink: invoice.failurePostLink,
      language: 'RU',
      description: 'Свободная оплата ID:' + invoice.policyID,
      terminal: invoice.terminal,
      accountId: user.id,
      amount: invoice.amount,
      currency: 'KZT',
      phone: '7' + user.phone_number,
      email: user?.email,
      cardSave: true,
      auth: invoice.auth
    }
  }

  window.halyk.pay(createPaymentObject(invoice, user))
}
