<template>
  <section class="fail-payment">
    <div class="fail-body">
      <img
        class="icon-fail"
        :src="require('@/assets/images/icon-fail.svg')"
        alt="icon-fail"
      />
      <div class="fail-info">
        <h3 class="text-center">Платеж не прошел</h3>
        <h1 class="text-center" v-if="amount">{{ amount | formatMoney }} ₸</h1>
        <h1 class="text-center" v-if="!amount">0 ₸</h1>
        <p class="text-center">Платеж мог быть отклонен, если есть ошибка во введенных данных</p>
      </div>
      <button class="btn sm outline green border-radius mb-10 block" @click="$router.go(-1)">К форме оплаты</button>
      <router-link :to="{ name: 'Index' }">
        <button class="btn sm outline green border-radius block">Закрыть</button>
      </router-link>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FailPayment',
  metaInfo: {
    title: 'Неуспешная оплата',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  data: () => ({
    amount: null
  }),
  created () {
    if (this.$route.query.amount) {
      this.amount = this.$route.query.amount
    }
    if (this.$route.query.calculationId !== 'null') {
      axios.get('/insurance-products/calculations/' + this.$route.query.calculationId)
        .then(res => {
          this.amount = res.data.amount
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="css">
  section.fail-payment {
    margin: 30px 0;
  }
  .fail-body {
    background-color: #f7f7f7;
    position: relative;
    border-radius: 6px;
    padding: 30px;
    max-width: 500px;
    margin: 0 auto;
  }
  img.icon-fail {
    width: 108px;
    margin: 0 auto;
  }
  .fail-info {
    margin: 30px 0 100px;
  }
  .fail-info h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #071222;
  }
  .fail-info h1 {
    font-weight: 800;
    font-size: 44px;
    line-height: 48px;
    color: #071222;
  }
  .fail-info p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #566681;
  }
</style>
