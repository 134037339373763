<template>
  <section class="services-header">
    <div class="container">
      <breadcrumbs/>
      <h1 class="page-title">
        {{ authUser ? $route.meta.title : $t('login') }}
        <button
          v-if="authUser"
          class="btn red outline sm border-radius pull-right"
          @click.prevent="accountLogOut">
          Выйти
        </button>
      </h1>
      <auth-o-t-p v-if="!authUser"/>
    </div>
  </section>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Widgets
import Breadcrumbs from '@/components/subscription/Basic/Breadcrumbs'
import AuthOTP from '@/components/subscription/AuthOTP'
// import Auth from '@/components/subscription/Auth'

export default {
  name: 'ServicesHeader',
  computed: {
    ...mapGetters({
      authUser: 'authUser'
    })
  },
  components: {
    AuthOTP,
    Breadcrumbs
  },
  methods: {
    ...mapActions(['logOut', 'resetAll']),
    accountLogOut () {
      this.loading = true
      this.logOut()
        .then(res => {
          if (res.status === 200) {
            this.resetAll()
              .then(() => {
                this.loading = false
              })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
