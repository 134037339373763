<template>
    <modal-layout :visible="visible">
      <p class="modal-title"> Уважаемый клиент </p>
      <p class="modal-subtitle">
        При отмене подписки договор(-а) страхования будет(-ут) действовать:<br/>
        <i
          v-for="(item, index) in info"
          :key="index + Math.random()"
        >
          {{ item.insrTypeName.replace(/ по подписке/g, '') }} до {{ setDate(item.dateNextWriteDowns) }}<br/>
        </i>
        <br/> Вы уверены, что хотите отменить подписку?
      </p>
      <button
        class="btn sm outline green border-radius mb-10 col-7"
        v-on:click="$emit('sms')"
      >Хорошо</button>
      <button
        href="#"
        class="btn sm outline green border-radius col-7"
        v-on:click="$emit('close')"
      >Отмена</button>
    </modal-layout>
</template>

<script>
import ModalLayout from '@/components/subscription/Modals/ModalLayout'
import moment from 'moment'

export default {
  name: 'ConfirmUnsubscribeModal',
  components: { ModalLayout },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: Array
  },
  methods: {
    setDate (val) {
      return moment(val).format('DD.MM.YYYY')
    }
  }
}
</script>

<style lang="css"></style>
