<template>
  <div class="form-document" v-if="!valid.readyAfter">
    <div class="form-panel group">
      <div class="subtitle">Документ, подтверждающий личность</div>
      <select-field
        label="Тип"
        v-model="form.doc_type"
        :options="this.documents"
        idFor="insured_type"
        v-if="!validate.after.doc_type"
      />
      <text-field
        label="Номер документа"
        v-model="form.doc_number"
        inputType="text"
        idFor="insured_address"
        v-if="!validate.after.doc_number"
      />
      <date-field
        id="insured_issue_date"
        ref="beginDate"
        v-model="form.doc_issue_date"
        label="Дата выдачи документа"
        v-if="!validate.after.doc_issue_date"
      />
    </div>
  </div>
</template>

<script>
// Fields
import SelectField from '@/components/fields/SelectField'
import TextField from '@/components/fields/TextField'
import DateField from '@/components/fields/DateField'

export default {
  name: 'Document',
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  components: {
    SelectField,
    TextField,
    DateField
  },
  data: () => ({
    form: {
      doc_issue_date: null,
      doc_number: null,
      doc_type: null
    },
    documents: [
      { title: 'Удостоверение РК', value: 'IC' },
      { title: 'Паспорт', value: 'PS' },
      { title: 'Свидетельство о рождении', value: 'BC' },
      { title: 'Вид на жительство нерезидента', value: 'RPF' },
      { title: 'Водительское удостоверение', value: 'DL' }
    ],
    autoInit: false,
    checkDocument: false,
    validate: {
      after: {
        doc_issue_date: null,
        doc_number: null,
        doc_type: null
      },
      before: {
        doc_issue_date: null,
        doc_number: null,
        doc_type: null
      }
    },
    valid: {
      readyAfter: null,
      readyBefore: null
    }
  }),
  created () {
    if (this.value) {
      this.initData(this.value)
    }
  },
  mounted () {
    this.validateAfter(this.value)
  },
  methods: {
    initData (val) {
      if (val) {
        this.validateBefore(val)
        this.form.doc_issue_date = val.doc_issue_date
        this.form.doc_number = val.doc_number
        this.form.doc_type = val.doc_type
        this.autoInit = true
        this.checkDocument = !!(
          this.form.doc_issue_date &&
          this.form.doc_number &&
          this.form.doc_type
        )
      }
    },
    validateAfter (val) {
      this.validate.after.doc_issue_date = !!val.doc_issue_date
      this.validate.after.doc_number = !!val.doc_number
      this.validate.after.doc_type = !!val.doc_type
      this.valid.readyAfter = !!(
        this.validate.after.doc_issue_date &&
        this.validate.after.doc_number &&
        this.validate.after.doc_type
      )
    },
    validateBefore (val) {
      this.validate.before.doc_issue_date = !!val.doc_issue_date
      this.validate.before.doc_number = !!val.doc_number
      this.validate.before.doc_type = !!val.doc_type
      this.valid.readyBefore = !!(
        this.form.doc_issue_date &&
        this.form.doc_number &&
        this.form.doc_type
      )
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (newValue) {
        this.initData(newValue)
      }
    },
    form: {
      deep: true,
      handler (changed) {
        this.$emit('changed', changed)
      }
    },
    valid: {
      deep: true,
      handler (validater) {
        this.$emit('validater', validater)
      }
    }
  }
}
</script>

<style scoped>

</style>
