<template>
  <section class="user-main-info" v-if="step !== 2">
    <div class="user-main-info__block">
      <div class="user-main-info__loader">
        <svg v-if="step < 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#11bb91" stroke-width="7" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </circle>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 511.755 511.755" fill="#11bb91">
          <g>
            <g>
              <path d="M436.891,74.867c-99.819-99.819-262.208-99.819-362.027,0c-99.819,99.797-99.819,262.229,0,362.027
                c49.899,49.92,115.456,74.859,181.013,74.859s131.093-24.939,181.013-74.859C536.709,337.096,536.709,174.664,436.891,74.867z
                 M398.96,185.629L249.627,334.963c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251l-85.333-85.333
                c-8.341-8.341-8.341-21.824,0-30.165c8.341-8.341,21.824-8.341,30.165,0l70.251,70.251l134.251-134.251
                c8.341-8.341,21.824-8.341,30.165,0C407.301,163.805,407.301,177.288,398.96,185.629z"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="user-main-info_text"><span v-if="step < 1">Загрузка:</span> <b v-html="text"></b></div>
    </div>
  </section>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'UserMainInfo',
  data: () => ({
    step: 0,
    text: ''
  }),
  computed: {
    ...mapGetters({
      authUser: 'authUser'
    })
  },
  beforeMount () {
    this.getUserInfo()
  },
  methods: {
    ...mapActions({
      getApartmentTariff: 'getApartmentTariff',
      getCascoTariff: 'getCascoTariff',
      getHouseTariff: 'getHouseTariff',
      getArchiveSub: 'getArchiveSub',
      getActiveSub: 'getActiveSub',
      getSession: 'getSession'
    }),
    getUserInfo () {
      const params = [this.authUser.client_id, null]
      this.getSession()
      this.text = 'Тарифов по «Квартирный ответ по подписке»'
      this.getApartmentTariff(params)
        .finally(() => {
          this.text = 'Тарифов по «КАСКО по подписке»'
          this.getCascoTariff(params)
            .finally(() => {
              this.text = 'Тарифов по «Мой дом по подписке»'
              this.getHouseTariff(params)
                .finally(() => {
                  this.text = 'Активных подписок'
                  this.getActiveSub()
                    .finally(() => {
                      this.text = 'Архивных подписок'
                      this.getArchiveSub()
                        .finally(() => {
                          this.step = 1
                          this.text = 'Завершено'
                          setTimeout(() => {
                            this.step = 2
                          }, 1500)
                        })
                    })
                })
            })
        })
    }
  }
}
</script>

<style scoped>
.user-main-info {
  background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0) 100%);
  padding: 70px 0 20px;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 15;
  bottom: 0;
  left: 0;
}
.user-main-info__block {
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, .1);
  justify-content: flex-start;
  padding: 5px 15px 5px 2px;
  background-color: #fff;
  align-content: center;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  min-height: 40px;
  max-width: 400px;
  margin: auto;
}
.user-main-info__loader {
  padding: 5px 10px;
  display: flex;
}
</style>
