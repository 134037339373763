<template>
  <div class="declaration-modal">
    <div class="declaration-body">
      <div class="close" v-on:click="status = false">&times;</div>
      <div class="declaration-info">
        <p class="text-center" v-html="text"></p>
        <br/>
        <button class="btn sm outline green border-radius center" v-on:click="status = false">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeclarationModal',
  props: {
    text: {
      type: String,
      default: null
    }
  },
  data: () => ({
    status: null
  }),
  watch: {
    status: {
      deep: true,
      handler: function (newValue) {
        this.$emit('modalStatus', newValue)
      }
    }
  }
}
</script>

<style scoped>
.declaration-modal {
  background-color: rgba(0, 0, 0, .3);
  backdrop-filter: blur(8px);
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
}
.declaration-body {
  background-color: #f7f7f7;
  position: relative;
  border-radius: 6px;
  padding: 30px;
  width: 700px;
  height: 90vh;
}
.declaration-body .close {
  position: absolute;
  line-height: 17px;
  transition: .2s;
  font-size: 30px;
  cursor: pointer;
  color: #99a3b3;
  padding: 20px;
  right: 0;
  top: 0;
}
.declaration-body .close:hover {
  color: #000;
}
.declaration-info {
  margin: 30px 0;
}
.declaration-info p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  height: 82vh;
  overflow-y: scroll;
}
</style>
