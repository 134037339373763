<template>
  <section class="services-block">
    <div class="services-panel">
      <div class="container pr">
        <h1 class="services-block__title">Сервисы</h1>
<!--        <p class="services-block__subtitle">-->
<!--          Мы хотим, чтобы каждый человек чувствовал себя защищённым и знал, что в случае непредвиденной ситуации он не останется наедине со своими проблемами.-->
<!--        </p>-->
        <carousel
          :items="5"
          :margin="35"
          :dots="false"
          :responsive="{0:{items:1, center: true, loop: true, autoWidth: true},766:{items:3}, 1200: {items:5}}"
        >
          <slot v-for="(service, index) in services">
            <router-link
              :to="{ name: service.name }"
              class="services-item"
              :style="$route.name === service.name ? 'border-color: #11bb91' : ''"
              :key="index + Math.random()"
            >
              <div class="services-item__icon" v-html="service.icon"></div>
              <div class="services-item__title" v-html="service.title"></div>
            </router-link>
          </slot>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'Services',
  components: { carousel },
  data: () => ({
    services: [
      {
        icon: '<svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '                <path d="M22.9167 0C10.262 0 0 9.32617 0 20.8333C0 32.3405 10.262 41.6667 22.9167 41.6667V50C22.9167 50 45.8333 38.8346 45.8333 20.8333C45.8333 9.32617 35.5713 0 22.9167 0ZM25.4639 31.5104H20.3695V17.0003H25.4639V31.5104ZM22.9248 12.915C20.166 12.915 20.1497 10.8805 20.1497 10.4085C20.1497 9.92839 20.1497 7.91829 22.9248 7.91829C25.708 7.91829 25.6999 9.92839 25.6999 10.4085C25.6999 10.8805 25.6836 12.915 22.9248 12.915Z" fill="#F9B000"/>\n' +
          '              </svg>',
        title: 'Проверить <br> подписку',
        name: 'CheckSubscription'
      },
      {
        icon: '<svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3362 9.26107C25.5276 7.58464 21.2308 7.32422 17.235 8.58561C9.64223 10.9782 4.71873 18.1803 5.23142 26.1068L0.0475046 26.4486C-0.619812 16.1214 5.80109 6.74642 15.6725 3.62956C21.4505 1.80664 27.7168 2.44141 33.0309 5.31413L38.0032 0L39.3297 14.738L23.721 15.2588L29.3362 9.26107ZM29.1409 46.3704C26.9518 47.054 24.6813 47.404 22.4271 47.404C18.7487 47.404 15.0947 46.4518 11.7988 44.6777L6.81834 50L5.49998 35.262L21.1006 34.7412L15.4935 40.7389C19.2858 42.4072 23.5827 42.6758 27.5784 41.4144C35.1712 39.0137 40.1028 31.8034 39.5901 23.8932L44.7659 23.5514C45.4495 33.8623 39.0205 43.2454 29.1409 46.3704Z" fill="#007757"/>\n' +
          '              </svg>',
        title: 'Поменять <br> тариф',
        name: 'ChangeTariff'
      },
      {
        icon: '<svg width="44" height="51" viewBox="0 0 44 51" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '                <path d="M38.5699 0C35.6971 0 33.3615 2.33561 33.3615 5.20833C33.3615 5.34668 33.4185 5.46875 33.4266 5.59896C22.538 3.39355 17.7284 16.6341 14.2209 19.401C10.8843 21.8994 1.67209 19.3604 0.418831 25.1953C-1.82726 29.069 5.19585 36.9059 15.8485 43.2943C16.9878 46.1182 19.7059 48.112 22.9449 48.112C23.6447 48.112 24.3202 48.0225 24.9631 47.8516C28.967 49.4059 32.5803 50.1953 35.4449 50.1953C37.9432 50.1953 39.8313 49.5687 40.4579 48.112C40.5393 47.9655 40.5962 47.8109 40.6532 47.6562C42.3622 45.4427 42.8261 43.1152 39.4162 37.3047C35.8274 31.4534 49.4911 18.0501 41.2391 9.70052C42.7691 8.7972 43.7782 7.12077 43.7782 5.20833C43.7782 2.33561 41.4426 0 38.5699 0ZM5.43185 26.237C9.81011 26.237 16.4833 28.54 22.9449 32.2917C23.2297 32.4544 23.4494 32.6497 23.7261 32.8125C23.4657 32.7881 23.2134 32.6823 22.9449 32.6823C18.9979 32.6823 15.7834 35.6934 15.3277 39.5182C6.29448 33.7402 2.57541 28.0355 2.95789 26.6927C3.16948 26.4811 3.76356 26.237 5.43185 26.237ZM29.7157 36.849C35.6239 41.4876 38.0409 45.5729 37.7235 46.6797C37.5119 46.8913 36.9179 47.0703 35.2496 47.0703C33.3697 47.0703 31.0097 46.6146 28.4787 45.8333C29.8378 44.4417 30.6272 42.5293 30.6272 40.4297C30.6272 39.1276 30.2854 37.9232 29.7157 36.849Z" fill="#F9B000"/>\n' +
          '              </svg>',
        title: 'Следующая <br> оплата',
        name: 'NextPayment'
      },
      {
        icon: '<svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '                <path d="M16.6667 0C7.47708 0 0 7.47708 0 16.6667C0 25.8562 7.47708 33.3333 16.6667 33.3333C19.7055 33.3333 22.5482 32.5018 25.0041 31.075C27.4589 32.5013 30.2994 33.3333 33.3333 33.3333C42.5134 33.3333 50 25.8467 50 16.6667C50 7.4866 42.5134 0 33.3333 0C30.2994 0 27.4589 0.831997 25.0041 2.2583C22.5482 0.831495 19.7055 0 16.6667 0ZM33.3333 4.16667C40.2616 4.16667 45.8333 9.73843 45.8333 16.6667C45.8333 23.5949 40.2616 29.1667 33.3333 29.1667C31.6642 29.1667 30.0782 28.8349 28.6255 28.2471C31.5328 25.2455 33.3333 21.1652 33.3333 16.6667C33.3333 12.1681 31.5328 8.08782 28.6255 5.08626C30.0782 4.49845 31.6642 4.16667 33.3333 4.16667Z" fill="#F9B000"/>\n' +
          '                <circle cx="17" cy="17" r="17" fill="#FF4B2B"/>\n' +
          '              </svg>',
        title: 'Сменить <br> карту',
        name: 'ChangeCard'
      },
      {
        icon: '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '                <path d="M50 25C50 38.8067 38.8067 50 25 50C11.1933 50 0 38.8067 0 25C0 11.1933 11.1933 0 25 0C38.8067 0 50 11.1933 50 25ZM38.0587 17.101C36.2846 14.2538 33.5606 12.0308 30.4231 10.8721L29.0731 14.474C31.3962 15.3692 33.401 17.0558 34.6837 19.174C35.9798 21.2894 36.4846 23.8337 36.1933 26.2837C35.9 28.7298 34.75 31.0433 33.0019 32.7712C31.2577 34.501 28.9433 35.6298 26.5163 35.9067C24.0817 36.2058 21.5779 35.674 19.5019 34.4163C17.4202 33.1692 15.7692 31.2154 14.9163 28.9654C14.0481 26.7288 13.9635 24.1769 14.6808 21.8981C15.1423 20.3981 15.951 19.0125 17.0029 17.8481L20.0269 21.2067L22.6712 9.62308L10.8625 11.0288L13.8019 14.2933C12.1365 16.0663 10.8769 18.2163 10.1952 20.5538C9.21731 23.8548 9.39423 27.4538 10.6779 30.6356C11.9452 33.8212 14.3077 36.525 17.2519 38.2308C20.1933 39.9548 23.7106 40.6346 27.0596 40.1769C30.426 39.7452 33.6125 38.1337 35.9731 35.7231C38.3442 33.3212 39.8846 30.1077 40.2404 26.7519C40.6019 23.4163 39.8519 19.9442 38.0587 17.101Z" fill="#12AB6B"/>\n' +
          '              </svg>',
        title: 'Возобновить <br> подписку',
        name: 'ReSubscribe'
      },
      {
        icon: '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '                <path d="M25 0C11.1898 0 0 11.1898 0 25C0 38.8102 11.1898 50 25 50C38.8102 50 50 38.8102 50 25C50 11.1898 38.8102 0 25 0ZM36.2305 32.1452L32.1452 36.2305L25 29.0853L17.8548 36.2305L13.7695 32.1452L20.9147 25L13.7695 17.8548L17.8548 13.7695L25 20.9147L32.1452 13.7695L36.2305 17.8548L29.0853 25L36.2305 32.1452Z" fill="#FF8670"/>\n' +
          '              </svg>',
        title: 'Отменить <br> подписку',
        name: 'Unsubscribe'
      }
    ]
  })
}
</script>

<style scoped>

</style>
