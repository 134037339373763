<template>
  <section class="success-payment">
    <div class="success-body">
      <div class="success-body-back">
        <img
          class="icon-success"
          :src="require('@/assets/images/icon-success.svg')"
          alt="icon-success"
        />
        <div class="success-info">
          <h3 class="text-center">Платёж прошёл успешно</h3>
          <h1 class="text-center" v-if="amount">{{ amount | formatMoney }} ₸</h1>
          <h1 class="text-center" v-if="!amount">0 ₸</h1>
          <p class="text-center">Квитанция об оплате и статус платежа доступны в истории</p>
        </div>
      </div>
      <router-link :to="{ name: 'Reviews', params: { insuranceType, amount } }">
          <button class="btn sm outline green border-radius center">Понятно</button>
      </router-link>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SuccessPayment',
  metaInfo: {
    title: 'Успешная оплата',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  data: () => ({
    amount: null,
    insuranceType: null
  }),
  created () {
    if (this.$route.query.amount) {
      this.amount = this.$route.query.amount
    }
    if (this.$route.query.calculationId !== 'null') {
      axios.get('/insurance-products/calculations/' + this.$route.query.calculationId)
        .then(res => {
          this.amount = res.data.amount
          this.insuranceType = res.data.insuranceType
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
  section.success-payment {
    margin: 30px 0;
  }
  .success-body {
    background-color: #f7f7f7;
    position: relative;
    border-radius: 6px;
    padding: 30px;
    max-width: 500px;
    margin: 0 auto;
  }
  .success-body-back {
    background: #2AA65C;
    margin: -30px -30px 100px;
    padding: 30px;
  }
  img.icon-success {
    width: 108px;
    margin: 0 auto;
  }
  .success-info {
    margin: 30px 0;
  }
  .success-info h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
  }
  .success-info h1 {
    font-weight: 800;
    font-size: 44px;
    line-height: 48px;
    color: #FFFFFF;
  }
  .success-info p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
  }
</style>
