<template>
  <section class="re-subscribe">
    <services-header/>
    <services-body
      v-if="authUser"
      :status="subStatuses.archiveSub"
      empty="У Вас еще не закончилась ни одна страховка. Тут будут отображены полисы, у которых закончился срок действия"
    >
      <div class="container">
        <div class="row">
          <div class="col-7">
            <div class="subscription-information" v-if="archiveSub">
              <p class="page-subtitle"><b>Информация о подписке</b></p>
              <sub-item-info
                v-for="(card, index) in archiveSub"
                :key="index + Math.random()"
                :item="card"
                :index="index"
                @input="setChecked"
                :title_btn="'Продлить подписку?'"
              />
              <br/>
              <div class="row">
                <div class="col-7 pr">
                  <button
                    class="btn orange border-radius block"
                    :disabled="!isDisabled"
                    v-on:click="openSMSModal"
                  >
                    Возобновить подписку
                  </button>
                  <sms-modal
                    v-if="isSMSModal"
                    :loading="loading"
                    :visible="isSMSModal"
                    :phone="getFilteredPhone"
                    :insrType="parseInt(this.filteredCheckedSub[0].insrType)"
                    :isSigned="true"
                    :error="errorSms"
                    @close="closeSMSModal"
                    @code="checkCode"
                    @resend="otpResend"
                  />
                </div>
                <div class="col-5"></div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <sub-mini-slider :info="archiveSub" v-if="archiveSub"/>
          </div>
        </div>
      </div>
    </services-body>
    <services/>
  </section>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Widgets
import SubMiniSlider from '@/components/subscription/SubscriptionsMiniSlider/SubscriptionsMiniSlider'
import SubItemInfo from '@/components/subscription/SubscriptionItemInformation'
import ServicesHeader from '@/components/subscription/ServicesHeader'
import ServicesBody from '@/components/subscription/ServicesBody'
import Services from '@/components/subscription/Services'
// Modal
import SmsModal from '@/components/subscription/Modals/SMSModal'
// Utils
import axios from 'axios'
import { generateOtp, verifyOtp } from '@/api/info'
import {
  convertFormForBackResubscribe,
  convertFormForBackResubscribeCasco
} from '@/utils/person'
import { uuidv4 } from '@/utils/uuidv4'
import showPaymentWidget from '@/utils/showPaymentWidget'
// Mixins
import ePayPayment from '@/mixin/ePayPayment'

export default {
  name: 'ReSubscribe',
  mixins: [ePayPayment],
  metaInfo: {
    title: 'Возобновить подписку',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    ServicesHeader,
    SubMiniSlider,
    ServicesBody,
    SubItemInfo,
    Services,
    SmsModal
  },
  data: () => ({
    data: null,
    loading: false,
    isDisabled: false,
    finalAmount: {
      res: null
    },
    isSMSModal: false,
    errorSms: null
  }),
  methods: {
    ...mapActions(['setChangedArchiveSub']),
    changeArchiveSub (selectedPolicyId, checked, loading, success, fail) {
      this.archiveSub.map(obj => {
        if (obj.policyId === selectedPolicyId) {
          if (checked !== null) obj.checked = checked
          if (loading !== null) obj.loading = loading
          if (success !== null) obj.isSuccessReSubscribe = success
          if (fail !== null) obj.isFailReSubscribe = fail
        }
      })
      this.setChangedArchiveSub(this.archiveSub)
    },
    setChecked (val, id) {
      this.changeArchiveSub(id, val)
    },
    openSMSModal () {
      const selectedPolicyId = this.filteredCheckedSub[0].policyId
      const insrType = parseInt(this.filteredCheckedSub[0].insrType)
      this.changeArchiveSub(selectedPolicyId, null, true)

      axios.get(`policies/${selectedPolicyId}`)
        .then(result => {
          console.log('result.data', result.data)
          result.data.draft_id = uuidv4()
          result.data.holder.uuid = uuidv4()
          let data = {}
          let url = ''
          if (insrType === 3016 || insrType === 3014) data = convertFormForBackResubscribe(result.data) // Страхование дома или квартиры
          if (insrType === 2225) {
            if (result.data.insureds.length > 0) {
              result.data.insureds.map(obj => {
                obj.uuid = uuidv4()
              })
            }
            data = convertFormForBackResubscribeCasco(result.data) // Каско
          }
          if (insrType === 3016) url = 'my-house'
          if (insrType === 3014) url = 'apartment'
          if (insrType === 2225) url = 'casco-lite'

          axios.post('/policy-draft', data)
            .then(() => {
              let id = null
              axios.post(`/insurance-products/calculations/subscription/${url}`, data)
                .then(res => {
                  id = res.data.id
                  this.changeArchiveSub(selectedPolicyId, null, false, true)
                  if (id) {
                    setTimeout(() => {
                      this.loading = true
                      this.isSMSModal = true
                      axios.get('/insurance-products/calculations/' + id)
                        .then(res => {
                          this.finalAmount.res = res.data
                          this.generateOtp()
                            .finally(() => {
                              this.loading = false
                            })
                        })
                        .catch(err => {
                          console.log(err)
                        })
                    }, 2000)
                  }
                })
                .catch(err => {
                  console.log(err)
                  this.changeArchiveSub(selectedPolicyId, null, false, null, true)
                })
            })
            .catch(err => console.log(err))
        })
        .catch(e => {
          console.log(`error ${selectedPolicyId}`, e)
          this.changeArchiveSub(selectedPolicyId, null, false, null, true)
        })
    },
    closeSMSModal () {
      this.isSMSModal = false
    },
    // OTP
    generateOtp () {
      this.loading = true
      return generateOtp('renewal', this.filteredCheckedSub[0].policyId)
        .then(res => console.log(res))
        .catch(e => console.log('Ошибка генерации отп при возобновлении', e))
    },
    checkCode (code) {
      this.loading = true
      verifyOtp('renewal', this.filteredCheckedSub[0].policyId, code)
        .then(res => {
          if (res.status === 200) {
            this.errorSms = false
            axios.post(`/insurance-products/auth/${this.finalAmount.res.id}`)
              .then(res => {
                this.loading = false
                this.isSMSModal = false
                showPaymentWidget(res.data, this.authUser)
              })
              .catch(err => {
                this.loading = false
                this.error = err.response.data.message
              })
          }
        })
        .catch(e => {
          this.errorSms = true
          this.loading = false
          console.log('Ошибка при верификации отп при возобновлении', e)
        })
    },
    otpResend (val) {
      if (val) {
        this.generateOtp()
      }
    }
  },
  computed: {
    ...mapGetters({
      subStatuses: 'subStatuses',
      archiveSub: 'archiveSub',
      authUser: 'authUser'
    }),
    getFilteredPhone () {
      const phone = this.authUser.phone_number
      return '+7 (' + phone.substring(0, 3) + ') ***-**-' + phone.substring(phone.length - 2)
    },
    filteredCheckedSub () {
      return this.archiveSub.filter(obj => obj.checked === true && !obj.isSuccessReSubscribe && !obj.isFailReSubscribe)
    }
  },
  watch: {
    archiveSub: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.archiveSub) {
          this.loading = this.archiveSub.some(obj => obj.loading === true)
          this.isDisabled = this.archiveSub.some(obj => obj.checked === true && !obj.isSuccessReSubscribe && !obj.isFailReSubscribe) && !this.loading
        }
      }
    }
  }
}
</script>

<style scoped></style>
