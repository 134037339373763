<template>
  <div class="form-control" :class="[setClassForm, disabled ? 'disabled' : '']">
    <textarea
      :value="value"
      :id="idFor"
      :disabled="disabled"
      class="text-area"
      @input="updateVal"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.prevent="$emit('enter')"
    />
    <label class="label" :for="idFor">{{ label }}</label>
    <span class="text-danger-error" v-if="error && visible_error">{{ error }}</span>
    <rounded-loader v-if="loader" />
  </div>
</template>

<script>
import RoundedLoader from '@/components/loaders/RoundedLoader'

export default {
  name: 'TextAreaField',
  components: {
    RoundedLoader
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    idFor: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    loader: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    upLabel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    visible: false,
    visible_error: false
  }),
  beforeMount () {
    if (this.value) {
      this.visible = true
    }
  },
  methods: {
    updateVal (e) {
      this.$emit('input', e.target.value)
    },
    onFocus () {
      this.visible = true
      this.visible_error = true
    },
    onBlur (e) {
      this.visible = !!this.$emit('input', e.target.value).value
      this.visible_error = false
    }
  },
  mounted () {
    if (this.upLabel) {
      this.visible = this.upLabel
    }
    if (this.value) {
      this.visible = true
    }
  },
  computed: {
    setClassForm () {
      let additionalClass = ''
      if (this.visible) {
        additionalClass += 'val'
      } else additionalClass = ''
      if (this.error && additionalClass) {
        additionalClass += ' form-control-error'
      } else if (this.error && !additionalClass) {
        additionalClass += 'form-control-error'
      }
      return additionalClass
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.visible = !!newValue
        this.val = this.value
        this.onFocus()
      }
    }
  }
}
</script>

<style scoped>
.form-control {
  box-shadow: 0 0 0 2px transparent;
  border: 1px solid #d5d5d5;
  margin-bottom: 20px;
  border-radius: 3px;
  position: relative;
  height: 100px;
}
</style>
