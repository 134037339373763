<template>
  <header>
    <div class="header-top">
      <div class="container">
        <div class="header-top__row">
          <div class="header-left">
            <div class="header-left__links">
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="setLang('kk')">Каз</a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="setLang('ru')">Рус</a>
                </li>
                <li v-for="(item, index) in main_links" :key="index + Math.random()">
                  <a :href="item.link">{{ item.title }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="header-right">
            <div class="header-right__links">
              <ul>
                <li><a href="#">Страховой случай</a></li>
                <li v-if="authUser">
                  <a v-if="authUser.first_name && authUser.last_name" href="javascript:void(0);"
                     v-html="authUser.full_name ? authUser.full_name: setFullName"
                  ></a>
                  <a v-else href="javascript:void(0);">+7 {{ authUser.phone_number | VMask('(###) ### ## ##') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <email-us-modal v-if="isOpenModalEmailUs" @modalStatus="setModalStatus"/>
    <div class="header-bottom">
      <div class="container">
        <div class="header-bottom__row">
          <div class="header-bottom__left">
            <router-link :to="{ name: 'Index' }">
              <img :src="require(`@/assets/images/logo.svg`)" alt="Лого">
            </router-link>
          </div>
          <div class="header-bottom__middle">
            <ul>
              <li>
                <a href="javascript:void(0);">Частным лицам</a>
                <ul class="header-bottom__dropdown">
                  <li v-for="(item, index) in dropdown_links" :key="index + Math.random()" class="header-bottom__dropdown-item">
                    <a :href="item.link">{{ item.title }}</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="javascript:void(0);">Бизнесу</a>
                <ul class="header-bottom__dropdown">
                  <li v-for="(item, index) in dropdown_links_bisness" :key="index + Math.random()" class="header-bottom__dropdown-item">
                    <a :href="item.link">{{ item.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="header-bottom__right">
            <div class="header-bottom__right_call">
              <a href="tel:797" class="short-tel-link">
                <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.596 17.2332L16.5759 12.6689C16.0831 12.2206 15.3224 12.2441 14.8581 12.722L11.9028 15.7611C11.1915 15.6253 9.76142 15.1795 8.28935 13.7112C6.81729 12.2379 6.37147 10.8042 6.23933 10.0978L9.27608 7.14136C9.75524 6.67703 9.77747 5.91755 9.32918 5.42358L4.76603 0.404832C4.31774 -0.090374 3.55578 -0.137301 3.04821 0.297393L0.368364 2.59559C0.154718 2.81047 0.0275172 3.0945 0.0102278 3.39706C-0.00829647 3.70579 -0.361493 11.019 5.30941 16.6923C10.2566 21.6382 16.4536 22 18.1603 22C18.4098 22 18.5629 21.9926 18.6037 21.9901C18.9062 21.9728 19.1903 21.8456 19.4039 21.6308L21.7009 18.9497C22.1381 18.4422 22.09 17.6815 21.596 17.2332Z" fill="#11bb91"/>
                </svg>
                797
              </a>
              ⸺ &nbsp; звоните бесплатно или&nbsp;
              <a href="#" @click="isOpenModalEmailUs = true">
                напишите нам
              </a>
            </div>
            <div class="mobile-menu__lang">
              <ul class="mobile-menu__lang-list">
                <li>
                  <a class="mobile-menu__lang-link" href="javascript:void(0);" @click="setLang('kk')">Каз</a>
                </li>
                <li>
                  <a class="mobile-menu__lang-link" href="javascript:void(0);" @click="setLang('ru')">Рус</a>
                </li>
              </ul>
            </div>
            <div class="mobile-menu__btn">
              <div class="hamburger" v-on:click="hamburgerMenu" :class="{open: hamburger}">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="mobile-block">
      <div class="mobile-block__panel">
        <a href="#" class="mobile-block__panel_title">Частным лицам</a>
        <ul>
          <li v-for="(item, index) in dropdown_links" :key="index + Math.random()">
            <a :href="item.link">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </section>
  </header>
</template>

<script>
// Utils
import setUserName from '@/mixin/setUserName'
import EmailUsModal from '@/components/subscription/Modals/EmailUsModal'
import { mapActions } from 'vuex'

export default {
  name: 'Header',
  components: { EmailUsModal },
  mixins: [setUserName],
  data: () => ({
    hamburger: false,
    main_links: [
      { title: 'Оплатить', link: 'https://polisonline.kz/oplatit/' },
      { title: 'Активировать', link: 'https://polisonline.kz/aktivirovat/' },
      { title: 'Проверить', link: 'https://id.mkb.kz/' }
    ],
    dropdown_links: [
      { title: 'ОГПО Авто', link: 'https://797.polisonline.kz/ogpo' },
      { title: 'КАСКО лайт', link: 'https://polisonline.kz/kasko-light/' },
      { title: 'Страхование квартиры', link: 'https://polisonline.kz/strahovanie-kvartiry/' },
      { title: 'Страхование частного дома', link: 'https://polisonline.kz/strahovanie-chastnogo-doma/' },
      { title: 'Страхование от несчастного случая', link: 'https://polisonline.kz/strahovanie-ot-neschastnogo-sluchaya/' },
      { title: 'Страхование от потери работы', link: 'https://polisonline.kz/strahovanie-ot-poteri-raboty/' },
      { title: 'Личный врач для всех', link: 'https://polisonline.kz/lichnyy-vrach-dlya-vseh/' },
      { title: 'Все будет хорошо!', link: 'https://polisonline.kz/vse-budet-horosho/' },
      { title: 'Медстрахование туристов', link: 'https://polisonline.kz/puteshestvennik-online/' },
      { title: 'Отпуск без забот', link: 'https://polisonline.kz/877-otpusk-bez-zabot/' }
    ],
    dropdown_links_bisness: [
      { title: 'ОГПО Авто Бизнесу', link: 'https://polisonline.kz/ogpo-avto-biznesu/' },
      { title: 'Перевозчику', link: 'https://polisonline.kz/perevozchiku/' },
      { title: 'По экологии', link: 'https://polisonline.kz/po-ekologii/' },
      { title: 'Аудитору', link: 'https://polisonline.kz/auditoru/' },
      { title: 'Нотариусу', link: 'https://polisonline.kz/notariusu/' },
      { title: 'Для опасных объектов', link: 'https://polisonline.kz/dlya-opasnyh-obektov/' },
      { title: 'Туроператору', link: 'https://www.osthalyk.kz/#/' }
    ],
    isOpenModalEmailUs: false
  }),
  created () {
    this.setLang(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru')
  },
  methods: {
    hamburgerMenu () {
      this.hamburger = !this.hamburger
      this.hamburger ? document.body.classList.add('mobile-menu') : document.body.classList.remove('mobile-menu')
    },
    setModalStatus (val) {
      this.isOpenModalEmailUs = val
    },
    ...mapActions(['setLanguage']),
    setLang (lang) {
      this.setLanguage(lang)
    }
  }
}
</script>

<style scoped>

</style>
