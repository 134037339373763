<template>
  <div class="email-us-modal">
    <div class="email-us-body" :class="loading ? 'bg-loading' : ''">
      <div class="close" v-on:click="status = false">&times;</div>
      <form action="" class="email-us-info">
        <h3 class="text-center">Напишите нам</h3>
        <p class="text-center">Если у Вас есть вопросы и пожелания, Вы можете написать нам. Мы ответим Вам в самое ближайшее время!</p>
        <text-field
          label="Имя"
          v-model="form.name"
          inputType="text"
          idFor="user_name"
        />
        <text-field
          mask="+7(###) ### ## ##"
          label="Мобильный телефон"
          v-model="form.phoneNumber"
          inputType="text"
          idFor="mobiles"
        />
        <text-area-field
          label="Сообщение"
          v-model="form.message"
          idFor="message"
        />
        <span v-if="isError" style="color: red;">Сообщение не отправлено, попробуйте позднее</span>
      </form>
      <button class="btn sm outline green border-radius center" :disabled="isError" @click="sendMessage">Отправить</button>
      <success
        v-if="isSuccessSend"
        :description="`Спасибо за Ваш запрос </br> Мы свяжемся с Вами в ближайшее время`"
        titleBtn="Хорошо"
        @modalStatus="setStatusModal"
      />
    </div>
  </div>
</template>

<script>
// Field
import TextField from '@/components/fields/TextField'
import TextAreaField from '@/components/fields/TextAreaField'
import Success from '@/components/subscription/Modals/Success'
// Utils
import axios from 'axios'

export default {
  name: 'EmailUsModal',
  data: () => ({
    form: {
      phoneNumber: '',
      name: '',
      message: ''
    },
    isSuccessSend: false,
    loading: false,
    isError: false,
    status: null
  }),
  components: {
    TextAreaField,
    TextField,
    Success
  },
  methods: {
    sendMessage () {
      this.loading = true
      axios.post('https://797.polisonline.kz/api/SendToCallback', this.form)
        .then(() => {
          this.isSuccessSend = true
        })
        .catch(e => {
          console.log(e)
          this.isError = true
          setTimeout(() => {
            this.status = false
          }, 3000)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setStatusModal (val) {
      this.status = val
    }
  },
  watch: {
    status: {
      deep: true,
      handler: function (newValue) {
        this.$emit('modalStatus', newValue)
      }
    }
  }
}
</script>

<style scoped>
.email-us-modal {
  background-color: rgba(0, 0, 0, .3);
  backdrop-filter: blur(8px);
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 100000;
  left: 0;
  top: 0;
}
.email-us-body {
  background-color: #f7f7f7;
  position: relative;
  border-radius: 6px;
  padding: 30px;
  width: 500px;
  margin: 0 auto;
}
.email-us-body .close {
  position: absolute;
  line-height: 17px;
  transition: .2s;
  font-size: 30px;
  cursor: pointer;
  color: #99a3b3;
  padding: 20px;
  right: 0;
  top: 0;
}
.email-us-body .close:hover {
  color: #000;
}
.email-us-info {
  margin: 30px 0;
}
.email-us-info h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.email-us-info p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0;
}
.email-us-info textarea {
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  background: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #8B8B8B;
  display: block;
  padding: 10px;
  height: 90px;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
}
</style>
