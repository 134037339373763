<template>
  <div class="form-location" v-if="fullValidate || !valid.readyAfter">
    <div class="form-panel group">
      <div class="subtitle" v-html="title"></div>
      <div v-if="fullValidate || !insuredInfo">
        <div v-show="form.address_country !== 'KAZ'">
          <select-field
            idFor="insured_country"
            label="Страна"
            v-model="form.address_country"
            :options="countries"
            v-if="fullValidate || !validate.after.address_country"
          />
        </div>
        <select-field
          idFor="insured_state"
          label="Регион"
          :options="currentStatesList"
          :disabled="!form.address_country"
          v-model="form.address_state"
          v-if="fullValidate || !validate.after.address_state"
        />
        <select-field
          idFor="insured_region"
          label="Район"
          :options="currentRegionsList"
          :disabled="!form.address_state"
          v-model="form.address_region"
          v-if="fullValidate || !validate.after.address_region"
        />
        <select-field
          class="border"
          idFor="insured_city"
          label="Город"
          :options="currentCitiesList"
          :disabled="!form.address_region"
          v-model="form.address_city"
          v-if="fullValidate || !validate.after.address_city"
        />
      </div>
      <div v-if="insuredInfo">
        <select-field
          class="border"
          idFor="insured_country"
          label="Город"
          :options="listCities"
          v-if="!valid.readyAfter"
          @input="addAddress"
        />
      </div>
      <text-field
        label="Микрорайон/улица"
        v-model="form.address_street"
        inputType="text"
        idFor="insured_street_name"
        v-if="fullValidate || (!validate.after.address_street || (autoInit && !valid.readyAfter))"
      />
      <div v-if="!insuredInfo">
        <text-field
          label="Дом"
          v-model="form.address_street_no"
          inputType="text"
          idFor="insured_street_no"
          v-if="fullValidate || (!validate.after.address_street_no || (autoInit && !valid.readyAfter))"
        />
        <text-field
          label="Квартира"
          v-model="form.address_flat_no"
          inputType="text"
          idFor="insured_flat_no"
          v-if="insurance_type !== 3016 && (fullValidate || (!validate.after.address_flat_no || (autoInit && !valid.readyAfter)))"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import filterOptionsList from '@/utils/filterOptionsList'
// Fields
import SelectField from '@/components/fields/SelectField'
import TextField from '@/components/fields/TextField'
// VUEX Getters
import { mapGetters } from 'vuex'

export default {
  name: 'Location',
  props: {
    value: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Место нахождения'
    },
    fullValidate: {
      type: Boolean,
      default: false
    },
    insurance_type: {
      type: Number,
      default: null
    },
    insuredInfo: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {
      address_street_no: null,
      address_country: 'KAZ',
      address_flat_no: null,
      address_region: null,
      address_street: null,
      address_state: null,
      address_city: null
    },
    autoInit: false,
    validate: {
      after: {
        address_street_no: null,
        address_country: null,
        address_flat_no: null,
        address_region: null,
        address_street: null,
        address_state: null,
        address_city: null
      },
      before: {
        address_street_no: null,
        address_country: null,
        address_flat_no: null,
        address_region: null,
        address_street: null,
        address_state: null,
        address_city: null
      }
    },
    valid: {
      readyAfter: null,
      readyBefore: null
    }
  }),
  components: {
    SelectField,
    TextField
  },
  computed: {
    ...mapGetters('dictionary', {
      countryStates: 'countryStates',
      stateRegions: 'stateRegions',
      regionCities: 'regionCities',
      countries: 'countries',
      regions: 'regions',
      states: 'states',
      cities: 'cities'
    }),
    // Currents
    currentStatesList () {
      return filterOptionsList(this.states, this.countryStates, this.form.address_country)
    },
    currentRegionsList () {
      return filterOptionsList(this.regions, this.stateRegions, this.form.address_state)
    },
    currentCitiesList () {
      return filterOptionsList(this.cities, this.regionCities, this.form.address_region)
    },
    currentStatesCount () {
      return this.currentStatesList?.length
    },
    currentRegionsCount () {
      return this.currentRegionsList?.length
    },
    currentCitiesCount () {
      return this.currentCitiesList?.length
    },
    listCities () {
      return this.$store.getters['dictionary/cityList']
    }
  },
  created () {
    if (this.value) {
      this.initData(this.value)
    }
  },
  mounted () {
    if (this.value) {
      this.validateAfter(this.value)
    }
  },
  methods: {
    initData (val) {
      if (val) {
        this.validateBefore(val)
        this.form.address_street = val.address_street
        this.form.address_street_no = val.address_street_no
        this.form.address_country = val.address_country
        this.form.address_flat_no = val.address_flat_no
        this.form.address_region = val.address_region ? `${val.address_region}` : ''
        this.form.address_state = val.address_state
        this.form.address_city = val.address_city ? `${val.address_city}` : ''
        this.autoInit = !!(val.address_region && val.address_state && val.address_city)
      }
    },
    validateAfter (val) {
      this.validate.after.address_street = !!val.address_street
      this.validate.after.address_street_no = !!val.address_street_no
      this.validate.after.address_country = !!val.address_country
      this.validate.after.address_flat_no = !!val.address_flat_no
      this.validate.after.address_region = !!val.address_region
      this.validate.after.address_state = !!val.address_state
      this.validate.after.address_city = !!val.address_city
      this.valid.readyAfter = !!(
        this.validate.after.address_country &&
        this.validate.after.address_region &&
        this.validate.after.address_state &&
        this.validate.after.address_city
      )
    },
    validateBefore (val) {
      this.validate.before.address_street = !!val.address_street
      this.validate.before.address_street_no = !!val.address_street_no
      this.validate.before.address_country = !!val.address_country
      this.validate.before.address_flat_no = !!val.address_flat_no
      this.validate.before.address_region = !!val.address_region
      this.validate.before.address_state = !!val.address_state
      this.validate.before.address_city = !!val.address_city
      if (!this.insuredInfo || (this.fullValidate && !this.insuredInfo)) {
        if (this.insurance_type === 3016) {
          this.valid.readyBefore = !!(
            this.form.address_street &&
           this.form.address_street_no &&
           this.form.address_country &&
           this.form.address_region &&
           this.form.address_state &&
           this.form.address_city
          )
        } else {
          this.valid.readyBefore = !!(
            this.form.address_street &&
            this.form.address_street_no &&
            this.form.address_flat_no &&
            this.form.address_country &&
            this.form.address_region &&
            this.form.address_state &&
            this.form.address_city
          )
        }
      } else if (this.insuredInfo) {
        this.valid.readyBefore = !!(
          // this.form.address_street &&
          this.form.address_region &&
          this.form.address_state &&
          this.form.address_city
        )
      }
    },
    addAddress (val) {
      const cityObj = this.listCities.find(item => item.city === val)
      if (cityObj) {
        this.form.address_region = `${cityObj.region}`
        this.form.address_state = `${cityObj.state}`
        this.form.address_city = `${cityObj.city}`
      }
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (newValue) {
        this.initData(newValue)
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler (changed) {
        this.$emit('changed', changed)
      }
    },
    valid: {
      deep: true,
      immediate: true,
      handler (validater) {
        this.$emit('validater', validater)
      }
    },
    currentStatesList: {
      handler () {
        if (this.currentStatesList && this.currentStatesList.length === 1) {
          this.form.address_state = this.currentStatesList[0].value
        } else {
          if (!this.currentStatesList.some(item => String(item.value) === String(this.form.address_state))) {
            this.form.address_state = null
          }
        }
      }
    },
    currentRegionsList: {
      handler () {
        if (this.currentRegionsList && this.currentRegionsList.length === 1) {
          this.form.address_region = this.currentRegionsList[0].value
        } else {
          if (!this.currentRegionsList.some(item => String(item.value) === String(this.form.address_region))) {
            this.form.address_region = null
          }
        }
      }
    },
    currentCitiesList: {
      handler () {
        if (this.currentCitiesList && this.currentCitiesList.length === 1) {
          this.form.address_city = this.currentCitiesList[0].value
        } else {
          if (!this.currentCitiesList.some(item => String(item.value) === String(this.form.address_city))) {
            this.form.address_city = null
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
