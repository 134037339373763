<template>
  <section v-if="insuranceProducts && insuranceProducts.length > 0">
    <slider :programs="insuranceProducts" :value="activeItem" v-on:val="isActive"/>
    <subscription :item="insuranceProducts[activeItem]" />
    <products/>
    <services/>
  </section>
</template>

<script>
// Widgets
import Subscription from '@/components/subscription/Subscription'
import Services from '@/components/subscription/Services'
import Products from '@/components/subscription/Products'
import Slider from '@/components/subscription/Slider'
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Index',
  metaInfo: {
    title: 'Страховая компания «Халык»',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    Slider,
    Products,
    Services,
    Subscription
  },
  data: () => ({
    activeItem: 0,
    visible: false,
    programs: [
      {
        title: 'Страхование по подписке',
        description: 'Уникальная возможность купить страховой полис за небольшую ежемесячную плату от 90 тенге и <br> защитить свой дом, квартиру и автомобиль от возможных финансовых убытков.',
        list: [
          'Оформление за 2 минуты',
          'Выгодные программы для каждого',
          'Возможность выбрать сумму выплаты'
        ],
        image: {
          url: require('@/assets/images/product4.png'),
          alt: 'general_image'
        },
        description_subscription: 'Это выгодное страхование для Вас и вашей семьи. Страхование по подписке, позволит <br> Вам разделить стоимость страхового полиса на ежемесячные платежи. <br> Тем самым, Вы получаете надежную защиту вашей квартиры, дома и автомобиля.<br> Реальные выплаты, онлайн поддержка 24/7.',
        quote: 'Приобрел страховку от несчастного случая <br/> за 12 000 тенге. <br/> Получил травму левой ноги во время игры в футбол. Врачи сказали, что требуется операция. Собрав все необходимые документы, получил выплату 300 000 тенге по программе «Самое главное».<br/><br/> Мурагер, 29 лет г. Нур-Султан'
      },
      {
        title: 'КАСКО по подписке',
        description: 'Программа «КАСКО по подписке» - надежная дополнительная защита <br> к полису обязательного страхования автомобиля.',
        list: [
          'Оформление полиса в течение 2-х минут',
          'Стоимость от 690 тенге в месяц',
          'Возможность выбрать размер покрытия'
        ],
        image: {
          url: require('@/assets/images/product2.png'),
          alt: 'casco_auto'
        },
        link_to_design: 'https://polisonline.kz/kasko-light/',
        link_to_description: { name: 'CascoLite', params: { clickTab: 2 } },
        description_subscription: 'Это выгодное страхование для Вас и вашей семьи. Страхование по подписке, позволит <br> Вам разделить стоимость страхового полиса на ежемесячные платежи. <br> Тем самым, Вы получаете надежную защиту вашей квартиры, дома и автомобиля.<br> Реальные выплаты, онлайн поддержка 24/7.',
        quote: 'Мой автомобиль ударили на парковке, у виновника не было страхового полиса. <br/> На момент происшествия у меня была дополнительная страховка «Автоплюс онлайн» за 100 тенге. <br/> При обращении в свою страховую компания с вопросом о возможных выплатах, сказали что могу обратиться по этой дополнительной страховке и получить выплаты. Не теряя времени, собрала все документы и получила максимальную выплату. Благодарю! <br/><br/> Асем 27 лет г. Атырау'
      },
      {
        title: 'Страхование квартиры по подписке',
        description: 'Программа «Квартирный ответ» защитит не только имущество <br> в вашей квартире, но и сохранит добрые отношения с соседями.',
        list: [
          'Оформление полиса в течение 2-х минут',
          'Стоимость от 490 тенге в месяц',
          'Возможность выбрать размер покрытия'
        ],
        image: {
          url: require('@/assets/images/product3.png'),
          alt: 'apartment_response'
        },
        link_to_design: 'https://polisonline.kz/strahovanie-kvartiry/',
        link_to_description: { name: 'Apartment', params: { clickTab: 2 } },
        description_subscription: 'Это выгодное страхование для Вас и вашей семьи. Страхование по подписке, позволит <br> Вам разделить стоимость страхового полиса на ежемесячные платежи. <br> Тем самым, Вы получаете надежную защиту вашей квартиры, дома и автомобиля.<br> Реальные выплаты, онлайн поддержка 24/7.',
        quote: 'Купил страховку от потери работы <br/> за 12 000 тенге.<br/> Попал под сокращение, обратился в страховую компанию и написал заявление. <br/> Без длительных ожидании получил в срок выплату в размере своей заработной платы. Так как в следующем месяце был в статусе безработного, обратился повторно и получил вторую выплату по программе «Не горюй!». <br/><br/> Райымбек, 27 лет. Уральск'
      },
      {
        title: 'Страхование дома по подписке',
        description: 'Программа «Мой дом по подписке» позволит защитить ваш дом при <br> пожаре, потопе и других непредвиденных событиях.',
        list: [
          'Оформление полиса в течение 2-х минут',
          'Стоимость от 390 тенге в месяц',
          'Возможность выбрать размер покрытия'
        ],
        image: {
          url: require('@/assets/images/product1.png'),
          alt: 'my_home'
        },
        link_to_design: 'https://polisonline.kz/strahovanie-chastnogo-doma/',
        link_to_description: { name: 'MyHouse', params: { clickTab: 2 } },
        description_subscription: 'Это выгодное страхование для Вас и вашей семьи. Страхование по подписке, позволит <br> Вам разделить стоимость страхового полиса на ежемесячные платежи. <br> Тем самым, Вы получаете надежную защиту вашей квартиры, дома и автомобиля.<br> Реальные выплаты, онлайн поддержка 24/7.',
        quote: 'При покупке обязательной автостраховки приобрел дополнительно “Каско” <br/> за 6 000 тенге. <br/> Инцидент произошел во дворе, машина супруги стояла на парковке. Виновник случайно поцарапал машину и скрылся с места ДТП. Через день обратились в органы дорожной полиции, по камерам нашли виновника, полиса у него не оказалось. Обратились в страховую компанию, страховые агенты предоставили консультацию, все подсказали. Прошли оценку, вопросов никаких не было, по регламенту все выплатили. <br/><br/> Евгений, 38 лет г. Уральск'
      }
    ]
  }),
  computed: {
    ...mapGetters(['insuranceProducts'])
  },
  created () {
    if (!this.insuranceProducts) this.getInsuranceProducts()
  },
  methods: {
    ...mapActions(['getInsuranceProducts']),
    isActive (id) {
      this.activeItem = id
    }
  }
}
</script>

<style scoped>

</style>
