import axios from 'axios'
import { convertTariffs, convertTariffUrl } from '@/utils/tariifs'

export default {
  state: {
    apartmentTariff: null,
    cascoTariff: null,
    houseTariff: null,
    archiveSub: null,
    activeSub: null,
    status: {
      apartmentTariff: 0,
      cascoTariff: 0,
      houseTariff: 0,
      archiveSub: 0,
      activeSub: 0
    }
  },
  mutations: {
    SET_APARTMENT (state, payload) { state.apartmentTariff = payload },
    SET_ARCHIVE (state, payload) { state.archiveSub = payload },
    SET_ACTIVE (state, payload) { state.activeSub = payload },
    SET_CASCO (state, payload) { state.cascoTariff = payload },
    SET_HOUSE (state, payload) { state.houseTariff = payload },
    // Status
    SET_APARTMENT_STATUS (state, payload) { state.status.apartmentTariff = payload },
    SET_ARCHIVE_STATUS (state, payload) { state.status.archiveSub = payload },
    SET_ACTIVE_STATUS (state, payload) { state.status.activeSub = payload },
    SET_CASCO_STATUS (state, payload) { state.status.cascoTariff = payload },
    SET_HOUSE_STATUS (state, payload) { state.status.houseTariff = payload },
    // Reset All
    RESET_ALL (state) {
      state.apartmentTariff = null
      state.cascoTariff = null
      state.houseTariff = null
      state.archiveSub = null
      state.activeSub = null
      // Status
      state.status.apartmentTariff = 0
      state.status.cascoTariff = 0
      state.status.houseTariff = 0
      state.status.archiveSub = 0
      state.status.activeSub = 0
    }
  },
  actions: {
    getApartmentTariff ({ commit }, params) {
      return axios.get(convertTariffUrl(params, 'subscription/apartment-tariffs'))
        .then(res => {
          commit('SET_APARTMENT', convertTariffs(res.data))
          commit('SET_APARTMENT_STATUS', 1)
        })
        .catch(() => {
          commit('SET_APARTMENT_STATUS', 2)
        })
    },
    getCascoTariff ({ commit }, params) {
      return axios.get(convertTariffUrl(params, 'subscription/casco-tariffs'))
        .then(res => {
          commit('SET_CASCO', convertTariffs(res.data))
          commit('SET_CASCO_STATUS', 1)
        })
        .catch(() => {
          commit('SET_CASCO_STATUS', 2)
        })
    },
    getHouseTariff ({ commit }, params) {
      return axios.get(convertTariffUrl(params, 'subscription/house-tariffs'))
        .then(res => {
          commit('SET_HOUSE', convertTariffs(res.data))
          commit('SET_HOUSE_STATUS', 1)
        })
        .catch(() => {
          commit('SET_HOUSE_STATUS', 2)
        })
    },
    getArchiveSub ({ commit }) {
      return axios.get('subscription/archive')
        .then(res => {
          const data = res.data
          data.forEach(obj => {
            obj.status = 'Отключен'
            obj.checked = false
            obj.loading = false
          })
          commit('SET_ARCHIVE', res.data)
          commit('SET_ARCHIVE_STATUS', 1)
        })
        .catch(() => {
          commit('SET_ARCHIVE_STATUS', 2)
        })
    },
    getActiveSub ({ commit }) {
      return axios.get('subscription/active')
        .then(async res => {
          const data = res.data
          data.forEach(obj => {
            obj.status = 'Активен'
            obj.checked = false
          })
          commit('SET_ACTIVE', data)
          commit('SET_ACTIVE_STATUS', 1)
        })
        .catch(() => {
          commit('SET_ACTIVE_STATUS', 2)
        })
    },
    resetAll ({ commit, state }) {
      commit('RESET_ALL', state)
    },
    setChangedArchiveSub ({ commit }, data) {
      commit('SET_ARCHIVE', data)
    },
    setChangedActiveSub ({ commit }, data) {
      commit('SET_ACTIVE', data)
    }
  },
  getters: {
    apartmentTariff: state => state.apartmentTariff,
    houseTariff: state => state.houseTariff,
    cascoTariff: state => state.cascoTariff,
    archiveSub: state => state.archiveSub,
    activeSub: state => state.activeSub,
    subStatuses: state => state.status
  }
}
