<template>
  <header>
    <div class="header-top">
      <div class="header-left">
        <div class="header-left__links">
          <ul>
            <li><a href="#">Язык</a></li>
            <li><a href="#">Оплатить</a></li>
            <li><a href="#">ОГПО ВТС</a></li>
            <li><a href="#">Вопросы и ответы</a></li>
            <li><a href="#">О Компании</a></li>
          </ul>
        </div>
      </div>
      <div class="header-right">
        <div class="header-right__links">
          <ul>
            <li><a href="#">Страховой случай</a></li>
            <li><a href="#">Личный кабинет</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="header-bottom__left">
        <router-link :to="{ name: 'Index' }">
          <img :src="require(`@/assets/images/logo.svg`)" alt="Лого">
        </router-link>
      </div>
      <div class="header-bottom__middle">
        <ul>
          <li>
            <a href="javascript:void(0);">Частным лицам</a>
            <ul class="header-bottom__dropdown">
              <li class="header-bottom__dropdown-item">
                <a href="#">ОГПО Авто</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">КАСКО лайт</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <router-link :to="{ name: 'Apartments' }">
                  Страхование квартиры
                </router-link>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">Страхование частного дома</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">Страхование от несчастного случая</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">Страхование от потери работы</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">Личный врач для всех</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">Все будет хорошо!</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">Путешественник Online</a>
              </li>
              <li class="header-bottom__dropdown-item">
                <a href="#">Путешественник Covid protect online</a>
              </li>
            </ul>
          </li>
          <li><a href="#">Бизнесу</a></li>
          <li><a href="#">Сервисы</a></li>
        </ul>
      </div>
      <div class="header-bottom__right">
        <div class="header-bottom__right_call">
          <a href="tel:797" class="short-tel-link">797</a>⸺ &nbsp; &nbsp; звоните бесплатно или&nbsp;<a href="#">напишите
          нам</a>
        </div>
        <div class="mobile-menu__btn">
          <div class="hamburger" v-on:click="hamburgerMenu" :class="{open: hamburger}">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <section class="mobile-block">
      <div class="mobile-block__panel">
        <a href="#" class="mobile-block__panel_title">Частным лицам</a>
        <ul>
          <li>
            <a href="#">ОГПО Авто</a>
          </li>
          <li>
            <a href="#">КАСКО лайт</a>
          </li>
          <li>
            <a href="#">Страхование квартиры</a>
          </li>
          <li>
            <a href="#">Страхование частного дома</a>
          </li>
          <li>
            <a href="#">Страхование от несчастного случая</a>
          </li>
          <li>
            <a href="#">Страхование от потери работы</a>
          </li>
          <li>
            <a href="#">Личный врач для всех</a>
          </li>
          <li>
            <a href="#">Все будет хорошо!</a>
          </li>
          <li>
            <a href="#">Путешественник Online</a>
          </li>
          <li>
            <a href="#">Путешественник Covid protect online</a>
          </li>
        </ul>
        <a href="#" class="mobile-block__panel_title">Частным лицам</a>
        <ul>
          <li>
            <a href="#">ОГПО Авто</a>
          </li>
          <li>
            <a href="#">КАСКО лайт</a>
          </li>
          <li>
            <a href="#">Страхование квартиры</a>
          </li>
          <li>
            <a href="#">Страхование частного дома</a>
          </li>
          <li>
            <a href="#">Страхование от несчастного случая</a>
          </li>
          <li>
            <a href="#">Страхование от потери работы</a>
          </li>
          <li>
            <a href="#">Личный врач для всех</a>
          </li>
          <li>
            <a href="#">Все будет хорошо!</a>
          </li>
          <li>
            <a href="#">Путешественник Online</a>
          </li>
          <li>
            <a href="#">Путешественник Covid protect online</a>
          </li>
        </ul>
        <a href="#" class="mobile-block__panel_title">Частным лицам</a>
        <ul>
          <li>
            <a href="#">ОГПО Авто</a>
          </li>
          <li>
            <a href="#">КАСКО лайт</a>
          </li>
          <li>
            <a href="#">Страхование квартиры</a>
          </li>
          <li>
            <a href="#">Страхование частного дома</a>
          </li>
          <li>
            <a href="#">Страхование от несчастного случая</a>
          </li>
          <li>
            <a href="#">Страхование от потери работы</a>
          </li>
          <li>
            <a href="#">Личный врач для всех</a>
          </li>
          <li>
            <a href="#">Все будет хорошо!</a>
          </li>
          <li>
            <a href="#">Путешественник Online</a>
          </li>
          <li>
            <a href="#">Путешественник Covid protect online</a>
          </li>
        </ul>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    hamburger: false
  }),
  methods: {
    hamburgerMenu () {
      this.hamburger = !this.hamburger
      this.hamburger ? document.body.classList.add('mobile-menu') : document.body.classList.remove('mobile-menu')
    }
  }
}
</script>

<style scoped>

</style>
