<template>
  <field-layout
    :visible="visible"
    :error="error"
    :id-for="idFor"
    :label="label"
    :visible_error="visible_error"
  >
    <DatePicker
      ref="input"
      format="DD.MM.YYYY"
      range-separator=" - "
      :range="range"
      :get-year-panel="getYearPanel"
      v-bind:lang="lang"
      v-model="inputValue"
      v-bind:first-day-of-week="1"
      v-bind:editable="true"
      :disabled="disabled"
      v-bind:disabled-date="dateDisabled"
      v-on:focus="onFocus"
      @blur="onBlur"
      :id="idFor"
    >
    </DatePicker>
  </field-layout>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import FieldLayout from '@/components/fields/FieldLayout'
import 'vue2-datepicker/index.css'

function chunk (arr, size) {
  if (!Array.isArray(arr)) {
    return []
  }
  const result = []
  const len = arr.length
  let i = 0
  size = size || len
  while (i < len) {
    result.push(arr.slice(i, (i += size)))
  }
  return result
}

export default {
  name: 'DateField',
  components: {
    DatePicker,
    FieldLayout
  },
  props: {
    value: null,
    idFor: String,
    label: String,
    placeholder: String,
    required: Boolean,
    minDate: Date,
    maxDate: Date,
    range: Boolean,
    disabled: Boolean,
    asterisk: Boolean,
    error: {
      type: String,
      default: ''
    }
  },
  inheritAttrs: false,
  data () {
    return {
      isFocused: false,
      visible: false,
      visible_error: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        if (value) {
          this.$emit('input', new Date(value.getTime()))
        }
      }
    },
    rootClass () {
      const result = []

      if (
        (this.inputValue &&
          (!Array.isArray(this.inputValue) ||
            (this.inputValue[0] && this.inputValue[1]))) ||
        this.isFocused
      ) {
        result.push('is-caption-lifted')
      }

      if (this.error) {
        result.push('error')
      }

      return result
    },
    lang () {
      return {
        formatLocale: {
          weekdays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          months: [
            'Янв',
            'Фев',
            'Мар',
            'Апр',
            'Май',
            'Июн',
            'Июл',
            'Авг',
            'Сен',
            'Окт',
            'Нояб',
            'Дек'
          ],
          monthsShort: [
            'Янв',
            'Фев',
            'Мар',
            'Апр',
            'Май',
            'Июн',
            'Июл',
            'Авг',
            'Сен',
            'Окт',
            'Нояб',
            'Дек'
          ],
          firstDayOfWeek: 1
        }
        /*
            pickers: ['след. 7 дней', 'след. 30 дней', 'пред. 7 дней', 'пред. 30 дней'],
            placeholder: {
                date: '',
                dateRange: ''
            }
            */
      }
    },
    minYear () {
      if (this.minDate) {
        return this.minDate.getFullYear()
      } else {
        return null
      }
    },
    maxYear () {
      if (this.maxDate) {
        return this.maxDate.getFullYear()
      } else {
        return null
      }
    }
  },
  beforeMount () {
    if (this.value) {
      this.visible = true
    }
  },
  methods: {
    dateDisabled (date) {
      if (this.minDate) {
        if (date < this.minDate) {
          return true
        }
      }

      if (this.maxDate) {
        if (date > this.maxDate) {
          return true
        }
      }

      return false
    },
    getYearPanel (calendar) {
      const years = []
      if (this.minYear && this.maxYear) {
        const num = this.maxYear - this.minYear + 1
        for (let i = 0; i < num; i++) {
          const year = this.minYear + i
          years.push(year)
        }
      } else {
        const firstYear = Math.floor(calendar.getFullYear() / 10) * 10
        for (let i = 0; i < 10; i++) {
          const year = firstYear + i
          years.push(year)
        }
      }
      return chunk(years, 2)
    },
    onFocus () {
      this.visible = true
      this.visible_error = true
    },
    onBlur () {
      this.visible_error = false
    }
  },
  mounted () {
    if (this.upLabel) {
      this.visible = this.upLabel
    }
    if (this.value) {
      this.visible = true
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.visible = !!newValue
      }
    }
  }
}
</script>

<style scoped></style>
