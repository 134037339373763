import axios from 'axios'
export default {
  namespaced: true,
  state: {
    countryStates: {},
    regionCities: {},
    stateRegions: {},
    countries: [],
    cityList: [],
    regions: [],
    states: [],
    cities: []
  },
  mutations: {
    SET_COUNTRY_STATES (state, payload) { state.countryStates = payload },
    SET_STATE_REGIONS (state, payload) { state.stateRegions = payload },
    SET_REGION_CITIES (state, payload) { state.regionCities = payload },
    SET_COUNTRIES (state, payload) { state.countries = payload },
    SET_CITY_LIST (state, payload) { state.cityList = payload },
    SET_REGIONS (state, payload) { state.regions = payload },
    SET_STATES (state, payload) { state.states = payload },
    SET_CITIES (state, payload) { state.cities = payload }
  },
  actions: {
    getUnionAddressDictionary ({ commit }) {
      return axios.get('/info/union-dictionary').then(res => {
        commit('SET_COUNTRY_STATES', res.data.countryStates)
        commit('SET_STATE_REGIONS', res.data.stateRegions)
        commit('SET_REGION_CITIES', res.data.regionCities)
        commit('SET_COUNTRIES', res.data.countries)
        commit('SET_REGIONS', res.data.regions)
        commit('SET_STATES', res.data.states)
        commit('SET_CITIES', res.data.cities)
      })
    },
    getCityList ({ commit }) {
      return axios.get('/info/city-list').then(res => {
        commit('SET_CITY_LIST', res.data)
      })
    }
  },
  getters: {
    countryStates: state => state.countryStates,
    stateRegions: state => state.stateRegions,
    regionCities: state => state.regionCities,
    countries: state => state.countries,
    cityList: state => state.cityList,
    regions: state => state.regions,
    states: state => state.states,
    cities: state => state.cities
  }
}
