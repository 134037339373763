<template>
  <div class="gift-friend" :class="loading ? 'bg-loading' : ''">
    <div class="gift-friend__before m-auto" v-if="!isSendFriend">
      <h1 class="text-center">{{ this.$route.params.amount | formatMoney }} ₸</h1>
      <h2 class="text-center">Подарите другу дополнительную страховку!</h2>
      <h3 class="text-center">
        Введите сотовый номер друга, ему придет смс с дополнительным бонусом “Автоплюс online”
      </h3>
      <div class="col-7 m-auto">
        <input
          v-mask="`+7 (###) ### ## ##`"
          type="text"
          v-model="phone_number"
          id="mobile"
          class="gift-friend_input"
        >
        <button
          class="btn outline green border-radius block"
          :disabled="isValidatePhone"
          @click="sendGift"
        >
          Отправить
        </button>
      </div>
    </div>
    <div class="gift-friend__after m-auto" v-if="isSendFriend">
      <img
        class="gift-friend__icon-success"
        :src="require('@/assets/images/icon-success2.svg')"
        alt="gift-friend__icon-success"
      />
      <h2 class="text-center">Уважаемый клиент! <br/> Дополнительная страховка другу была отправлена.</h2>
      <h3 class="text-center">На сотовый номер друга, направлена СМС со ссылкой на приложение.</h3>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'GiftFriend',
  data: () => ({
    phone_number: null,
    isSendFriend: false,
    loading: false
  }),
  methods: {
    sendGift () {
      this.loading = true
      axios.post('https://797.polisonline.kz/api/isHaveBasketFriend/', { Phone: this.phone_number })
        .then(res => {
          console.log('res', res.data)
          axios.post('https://797.polisonline.kz/api/isHaveBasketFriend/', { Phone: this.phone_number, invoiceID: this.$route.params.insuranceType })
            .then(res => {
              console.log('res', res.data)
              this.isSendFriend = true
            })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'authUser'
    }),
    isValidatePhone () {
      let value = null
      if (this.phone_number) {
        value = this.phone_number.length !== 0 && this.phone_number.length !== 18
      }
      return value
    }
  }
}
</script>

<style lang="css">
.gift-friend {
  background: #FFFFFF;
  box-shadow: 2px 12px 16px 13px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-bottom: 50px;
}
.gift-friend__before, .gift-friend__after {
  padding: 5%;
  max-width: 500px;
}
.gift-friend__before > h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 32px;
  color: #11BB91;
  mix-blend-mode: normal;
  margin: 30px 0 50px;
}
.gift-friend__before > h2, .gift-friend__after > h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  color: #11BB91;
  margin-bottom: 15px;
}
.gift-friend__before > h3, .gift-friend__after > h3 {
  line-height: 26px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 28px;
  color: #99A3B3;
}
.gift-friend_input {
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
  background: #F1F2F6;
  border-radius: 3px;
  border: none;
  outline: none;
  padding: 10px;
  display: block;
  width: 100%;
  margin: 33px auto;
  box-sizing: border-box;
  transition: background-color 5000s;
  font-weight: 500;
  color: #57667F;
  line-height: 1.3333;
  font-size: 20px;
  height: 46px;
}
img.gift-friend__icon-success {
  width: 170px;
  height: 170px;
  margin: 0 auto 30px;
}

@media (max-width: 766px) {
  .gift-friend__before > h1 {
    font-size: 36px;
    line-height: 38px;
    margin: 20px 0 30px;
  }
  .gift-friend__before > h2, .gift-friend__after > h2 {
    font-size: 20px;
    line-height: 22px;
  }
  .gift-friend__before > h3, .gift-friend__after > h3 {
    line-height: 15px;
    font-size: 16px;
  }
  .reviews-rating-info > h3, .reviews-rating-info > h3.text-center {
    line-height: 20px;
    font-size: 18px;
  }
  .gift-friend__before, .gift-friend__after {
    margin: 0 auto 30px;
  }
  img.gift-friend__icon-success {
    width: 130px;
    height: 130px;
    margin: 0 auto 20px;
  }
}
</style>
