<template>
    <div class="feedback-block">
      <h3 class="feedback-title">Остались вопросы?</h3>
      <text-field
        label="Имя"
        v-model="form.name"
        inputType="text"
        idFor="name"
        :upLabel="!!form.name"
      />
      <text-field
        inputType="text"
        label="Номер телефона"
        mask="+7 (###) ### ## ##"
        v-model="form.phone_number"
        idFor="mobile"
        :upLabel="!!form.phone_number"
      />
      <button class="btn orange border-radius block" @click="sendMessage">
        Отправить
      </button>
      <success
        v-if="isSuccessSend"
        :description="`Спасибо за Ваш запрос </br> Мы свяжемся с Вами в ближайшее время`"
        titleBtn="Хорошо"
        @modalStatus="setStatusModal"
      />
    </div>
</template>

<script>
// Fields
import TextField from '@/components/fields/TextField'
import Success from '@/components/subscription/Modals/Success'
import axios from 'axios'

export default {
  name: 'Feedback',
  components: {
    TextField,
    Success
  },
  data: () => ({
    form: {
      name: '',
      phone_number: ''
    },
    isSuccessSend: false,
    loading: false
  }),
  methods: {
    sendMessage () {
      this.loading = true
      axios.post('https://797.polisonline.kz/api/SendToCallback', this.form)
        .then(() => {
          this.isSuccessSend = true
          this.form.name = null
          this.form.phone_number = null
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setStatusModal (val) {
      this.isSuccessSend = val
    }
  }
}
</script>

<style lang="css">
  .feedback-block {
    background: linear-gradient(90.71deg, #00805F 0.6%, #11BB91 99.22%);
    border-radius: 12px;
    max-width: 530px;
    max-height: 300px;
    margin: 20px auto 50px;
    padding: 14px 90px;
    box-sizing: border-box;
  }
  .feedback-block .form-control {
    border: none;
  }
  .feedback-title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 570px) {
    .feedback-block {
      padding: 16px;
      border-radius: 0;
    }
  }
</style>
