import axios from 'axios'

export default {
  state: {
    authUser: JSON.parse(localStorage.getItem('authUser')) || null,
    accessToken: localStorage.getItem('accessToken') || null,
    interval: null,
    enc: null
  },
  mutations: {
    SET_ACCESS_TOKEN (state, data) {
      localStorage.setItem('enc', data.enc)
      localStorage.setItem('accessToken', data.access_token)
      state.accessToken = data.access_token
      state.enc = data.enc
      axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`
      const date = new Date()
      localStorage.setItem('sessionTime', date.toString())
    },
    SET_AUTH_USER (state, user) {
      localStorage.setItem('authUser', JSON.stringify(user))
      state.authUser = JSON.parse(JSON.stringify(user))
    },
    LOGOUT (state) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('authUser')
      state.accessToken = null
      state.authUser = null
      clearInterval(state.interval)
    }
  },
  actions: {
    signIn ({ commit }, payload) {
      return axios.post('/auth/login', payload)
        .then(res => {
          if (res.status === 200) {
            commit('SET_ACCESS_TOKEN', res.data)
          }
        })
        .catch(e => {
          return e
        })
    },
    getAuthUser ({ commit }) {
      return axios.get('/user')
        .then(res => {
          commit('SET_AUTH_USER', res.data)
          return res
        })
        .catch(e => {
          return e
        })
    },
    logOut ({ commit, state }) {
      return axios.post('/auth/logout')
        .then(res => {
          if (res.status === 200) {
            commit('LOGOUT', state)
          }
          return res
        })
        .catch(e => {
          return e
        })
    },
    otp ({ commit }, payload) {
      return axios.post('/auth/login-by-otp', payload)
    },
    login ({ commit }, payload) {
      return axios.post('/auth/validate-otp-code', payload)
        .then(res => {
          if (res.status === 200) {
            commit('SET_ACCESS_TOKEN', res.data)
          }
        })
    },
    getSession ({ commit, state }) {
      const date = localStorage.getItem('sessionTime')
      if (date) {
        const sessionDate = new Date(date)
        sessionDate.setMinutes(sessionDate.getMinutes() + 30)
        const curDate = new Date()
        if (curDate >= sessionDate) {
          axios.post('/auth/logout')
            .then(() => commit('LOGOUT', state))
            .catch(e => console.log(e))
        } else {
          let count = Math.round((sessionDate - curDate) / 1000)
          state.interval = setInterval(() => {
            count--
            if (count <= 0) {
              axios.post('/auth/logout')
                .then(() => commit('LOGOUT', state))
                .catch(e => console.log(e))
            }
          }, 1000)
        }
      } else {
        axios.post('/auth/logout')
          .then(() => commit('LOGOUT', state))
          .catch(e => console.log(e))
      }
    }
  },
  getters: {
    authUser: state => state.authUser,
    accessToken: state => state.accessToken,
    enc: state => state.enc
  }
}
