<template>
  <div v-if="houseProducts">
    <section class="product-information-block">
      <div class="product-information">
        <div class="product-information__main">
          <div class="container">
            <div class="product-information__row">
              <div class="product-information__content">
                <p><router-link :to="{ name: 'Index' }">Главная</router-link> > <b>Подписка</b></p>
                <h4 class="product-information__subtitle" v-html="houseProducts.title"></h4>
                <div class="product-information__description" v-html="houseProducts.description"></div>
<!--                <p>-->
<!--                  Программа «Мой дом по подписке» позволит защитить ваш дом при <br>-->
<!--                  пожаре, потопе и других непредвиденных событиях.-->
<!--                </p>-->
<!--                <ul>-->
<!--                  <li>Оформление полиса в течение 2-х минут</li>-->
<!--                  <li>Стоимость от 390 тенге в месяц</li>-->
<!--                  <li>Возможность выбрать размер покрытия</li>-->
<!--                </ul>-->
                <div class="row">
                  <div class="col-7">
                    <span v-on:click.prevent="tab = 1" class="btn outline border-radius block mb-10" :class="tab === 1 ? 'active' : ''">Оформить по подписке</span>
                    <span v-on:click.prevent="tab = 2" class="btn outline border-radius block" :class="tab === 2 ? 'active' : ''">Описание</span>
                  </div>
                  <div class="col-5"></div>
                </div>
              </div>
              <div class="product-information__image">
                <img :src="houseProducts.imageProduct.url" :alt="houseProducts.imageProduct.alt">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="product-tabs" ref="form">
      <transition-group name="fade">
        <div class="product-tabs__info" :key="2" v-show="tab === 2">
          <div class="container">
            <product-information product_name="house"/>
            <product-tariffs :info="houseProducts.tariffs"/>
            <questions :info="houseProducts.questions"/>
            <product-other/>
          </div>
          <services/>
          <feedback/>
        </div>
        <div class="product-tabs__form" :key="1" v-show="tab === 1">
          <div class="container">
            <my-house-form/>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
// Widgets
import ProductInformation from '@/components/subscription/ProductInformation/ProductInformation'
import ProductTariffs from '@/components/subscription/ProductTariffs/ProductTariffs'
import ProductOther from '@/components/subscription/ProductOther/ProductOther'
import MyHouseForm from '@/components/subscription/PolicyForms/MyHouseForm'
import Questions from '@/components/subscription/Questions/Questions'
import Services from '@/components/subscription/Services'
import Feedback from '@/components/subscription/Feedback'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MyHouse',
  metaInfo: {
    title: 'Страхование по подписке',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    ProductInformation,
    ProductTariffs,
    ProductOther,
    MyHouseForm,
    Questions,
    Services,
    Feedback
  },
  data: () => ({
    tab: 1
  }),
  created () {
    if (!this.houseProducts) this.getInsuranceProducts()
  },
  methods: {
    ...mapActions(['getInsuranceProducts'])
  },
  computed: {
    ...mapGetters(['houseProducts'])
  }
}
</script>

<style scoped></style>
