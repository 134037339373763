import { mapGetters } from 'vuex'

export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      authUser: 'authUser'
    }),
    setFullName () {
      let name = ''
      if (this.authUser.last_name) name += this.capitalize(this.authUser.last_name)
      if (this.authUser.first_name) name += ' ' + this.capitalize(this.authUser.first_name)
      if (this.authUser.middle_name) name += ' ' + this.capitalize(this.authUser.middle_name)
      if (this.authUser.full_name) name = this.authUser.full_name
      return name
    }
  },
  methods: {
    capitalize (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    }
  }
}
