<template>
    <field-layout
      :visible="visible"
      :error="error"
      :id-for="idFor"
      :label="label"
      :visible_error="visible_error"
      :loader="loader"
      :disabled="disabled"
    >
      <input
        v-mask="mask"
        :type="inputType"
        :loader="loader"
        :value="value"
        :id="idFor"
        :disabled="disabled"
        class="input"
        @input="updateVal"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter.prevent="$emit('enter')"
      >
    </field-layout>
</template>

<script>
import FieldLayout from '@/components/fields/FieldLayout'

export default {
  name: 'TextField',
  components: {
    FieldLayout
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    idFor: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    loader: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    upLabel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    visible: false,
    visible_error: false
  }),
  beforeMount () {
    if (this.value) {
      this.visible = true
    }
  },
  methods: {
    updateVal (e) {
      this.$emit('input', e.target.value)
    },
    onFocus () {
      this.visible = true
      this.visible_error = true
    },
    onBlur (e) {
      this.visible = !!this.$emit('input', e.target.value).value
      this.visible_error = false
    }
  },
  mounted () {
    if (this.upLabel) {
      this.visible = this.upLabel
    }
    if (this.value) {
      this.visible = true
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.visible = !!newValue
        this.val = this.value
        this.onFocus()
      }
    },
    upLabel: {
      deep: true,
      handler: function (newValue) {
        this.visible = !!newValue
      }
    }
  }
}
</script>

<style scoped></style>
