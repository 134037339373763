<template>
  <div class="breadcrumbs-block">
    <ul class="breadcrumbs">
      <li><router-link :to="{ name: 'Index' }">Главная</router-link></li>
      <li>{{ authUser ? $route.meta.title : 'Авторизация' }}</li>
    </ul>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'Breadcrumbs',
  computed: {
    ...mapGetters({
      authUser: 'authUser'
    })
  }
}
</script>

<style scoped>

</style>
