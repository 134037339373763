<template>
  <div class="question-item" :class="item === last_item && 'last_item'">
    <div class="question-item__title mb-10" @click="$emit('visibleAnswer', item)">
      <p class="mr-10">{{ item.question }}</p>
      <div class="question-item__icon">
        <div class="question-item__icon-plus-svg" v-if="!item.isOpen">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.86377 1.54297V6.5013H11.9061" stroke="#11BB91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path opacity="0.5" d="M1.8208 6.5H6.86317V11.4583" stroke="#11BB91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <span :class="!item.isOpen ? 'question-item__icon-plus-1' : 'question-item__icon-minus-1'"></span>
        <span :class="item.isOpen && 'question-item__icon-minus-2'"></span>
      </div>
    </div>
    <span v-show="item.isOpen && item.answer">{{ item.answer }}</span>
  </div>
</template>

<script>

export default {
  name: 'QuestionItem',
  props: {
    item: {
      type: Object
    },
    last_item: {
      type: Object
    }
  }
}
</script>

<style lang="css">
.question-item__title {
  padding-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
}
.question-item__title > p {
  font-weight: 600;
  font-size: 15px;
}
.questions .question-item {
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.questions .question-item:before {
  background: #C8C7CC;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: "";
}
.questions .question-item.last_item:before {
  display: none;
}
.question-item__icon {
  position: relative;
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.question-item__icon-plus-svg {
  position: absolute;
  top: 14px;
  left: 9px;
  z-index: 9;
}
.question-item__icon-plus-1, .question-item__icon-minus-1 {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  background-color: #FFFFFF;
  border: 1px #F1F2F6 solid;
}
.question-item__icon-minus-2 {
  width: 12px;
  height: 2px;
  background-color: #11BB91;
  border-radius: 2px;
  position: absolute;
  top: 20px;
  left: 10px;
}
</style>
