<template>
  <div class="star-rating">
    <label
      class="star-rating__star"
      v-for="(rating, index) in ratings"
      :key="index + Math.random()"
      :class="{'is-selected': ((value >= rating) && value != null), 'is-disabled': disabled}"
      @click="set(rating)"
      @mouseover="star_over(rating)"
      @mouseout="star_out"
    >
      <input
        class="star-rating star-rating__checkbox"
        type="radio"
        :value="rating"
        :name="name"
        v-model="value"
        :disabled="disabled"
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <path d="M14.4998 1.6665L18.6198 10.0132L27.8332 11.3598L21.1665 17.8532L22.7398 27.0265L14.4998 22.6932L6.25984 27.0265L7.83317 17.8532L1.1665 11.3598L10.3798 10.0132L14.4998 1.6665Z" stroke="#9AA3B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    name: String,
    disabled: Boolean
  },
  data: () => ({
    value: 1,
    temp_value: null,
    ratings: [1, 2, 3, 4, 5]
  }),
  methods: {
    star_over (index) {
      if (!this.disabled) {
        this.temp_value = this.value
        this.value = index
      }
    },
    star_out () {
      if (!this.disabled) {
        this.value = this.temp_value
      }
    },
    set (value) {
      if (!this.disabled) {
        this.temp_value = value
        this.value = value
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.$emit('changeRating', newValue)
      }
    }
  }
}
</script>
<style lang="css">
  .star-rating__checkbox {
    clip: rect(0 0 0 0);
    position: absolute;
    overflow: hidden;
    margin: -1px;
    height: 1px;
    padding: 0;
    width: 1px;
    border: 0;
  }
  .star-rating {
    align-items: center;
    margin-bottom: 30px;
    display: flex;
  }
  .star-rating__star {
    transition: color 0.2s ease-out;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    border-radius: 16px;
    margin-right: 16px;
    font-size: 1.5em;
    line-height: 1;
    color: #ABABAB;
    display: flex;
    height: 56px;
    padding: 3px;
    width: 56px;
    /*background: #F1F2F6;*/
  }
  .star-rating__star.is-selected svg path {
    stroke: #FCB016;
    fill: #FCB016;
  }
  .star-rating__star:last-child {
    margin-right: 0;
  }
  .star-rating__star:hover {
    cursor: pointer;
  }
  .star-rating__star.is-selected {
    color: #FCB016;
  }
  .star-rating__star.is-disabled:hover {
    cursor: default;
  }
</style>
