export default {
  mounted () {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      'https://epay.homebank.kz/payform/payment-api.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
