<template>
  <div class="otp-block">
    <div class="otp-block__form">
      <input
        v-mask="mask"
        :disabled="disabled"
        :value="value"
        class="input otp-block__input"
        maxlength="4"
        type="text"
        @input="updateVal"
        @focus="onFocus"
        @blur="onBlur"
      >
    </div>
  </div>

</template>

<script>
export default {
  name: 'OtpField',
  props: {
    value: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateVal (e) {
      this.$emit('input', e.target.value)
    },
    onFocus () {
      this.visible = true
      this.visible_error = true
    },
    onBlur (e) {
      this.visible = !!this.$emit('input', e.target.value).value
      this.visible_error = false
    }
  }
}
</script>

<style scoped>
  .otp-block__input {
    background-image: linear-gradient(to left, #E0E6EF 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-repeat: repeat-x;
    background-position-x: 40px;
    background-size: 45px 1px;
    letter-spacing: 25px;
    margin-right: -25px;
    padding-left: 15px;
    line-height: 40px;
    min-width: 190px;
    font-weight: 800;
    font-size: 34px;
    color: #071222;
    width: 220px;
    border: 0;
  }
  .otp-block__input:active,
  .otp-block__input:hover,
  .otp-block__input:focus{
    outline: none;
  }

  .otp-block__form {
    position: sticky;
    left: 0;
  }

  .otp-block {
    position: relative;
    overflow: hidden;
    margin: auto;
    width: 190px;
  }
</style>
