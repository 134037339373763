<template>
  <section class="next-payment">
    <services-header/>
    <services-body
      v-if="authUser"
      :status="subStatuses.activeSub"
      empty="Вы пока ничего не застраховали, но всё ещё впереди!"
    >
      <div class="container">
        <div class="row">
          <div class="col-7">
            <div class="subscription-information" v-if="activeSub">
              <sub-item-info :item="activeSub[activeItem]"/>
            </div>
          </div>
          <div class="col-5">
            <sub-mini-slider :value="activeItem" v-on:val="isActive" :info="activeSub" v-if="activeSub"/>
          </div>
        </div>
        <div class="calendar" v-if="activeSub">
          <p class="page-subtitle">
            <b>Календарь платежей</b>
            <small>Дата предстоящего платежа</small></p>
          <div class="calendar-block">
            <calendar v-for="(item, index) in setCalendar" :key="index + Math.random()" :item="item" />
          </div>
        </div>
      </div>
    </services-body>
    <services/>
  </section>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'
// Widgets
import SubMiniSlider from '@/components/subscription/SubscriptionsMiniSlider/SubscriptionsMiniSlider'
import SubItemInfo from '@/components/subscription/SubscriptionItemInformation'
import ServicesHeader from '@/components/subscription/ServicesHeader'
import ServicesBody from '@/components/subscription/ServicesBody'
import Services from '@/components/subscription/Services'
import Calendar from '@/components/subscription/Calendar'

export default {
  name: 'NextPayment',
  metaInfo: {
    title: 'Следующая оплата',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    SubMiniSlider,
    ServicesHeader,
    ServicesBody,
    SubItemInfo,
    Services,
    Calendar
  },
  data: () => ({
    activeItem: 0
  }),
  methods: {
    isActive (id) {
      this.activeItem = id
    }
  },
  computed: {
    ...mapGetters({
      subStatuses: 'subStatuses',
      activeSub: 'activeSub',
      authUser: 'authUser'
    }),
    setCalendar () {
      const itemSliderInfo = this.activeSub[this.activeItem]
      const sumSub = +itemSliderInfo.premMonth
      const calendar = [
        { month: 'Январь', digitMonth: 1, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Февраль', digitMonth: 2, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Март', digitMonth: 3, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Апрель', digitMonth: 4, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Май', digitMonth: 5, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Июнь', digitMonth: 6, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Июль', digitMonth: 7, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Август', digitMonth: 8, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Сентябрь', digitMonth: 9, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Октябрь', digitMonth: 10, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Ноябрь', digitMonth: 11, price: sumSub, isPaid: false, dayNextPayment: null },
        { month: 'Декабрь', digitMonth: 12, price: sumSub, isPaid: false, dayNextPayment: null }
      ]

      const dataSubArr = itemSliderInfo.dateGiven.split(' ')[0].split('-')
      const yearSub = +dataSubArr[0]
      const monthSub = +dataSubArr[1]

      const dataNextPaymentArr = itemSliderInfo.dateNextWriteDowns.split(' ')[0].split('-')
      const yearNextPayment = +dataNextPaymentArr[0]
      const monthNextPayment = +dataNextPaymentArr[1]
      const dayNextPayments = +dataNextPaymentArr[2]

      calendar.forEach(obj => {
        obj.isPaid = ((yearNextPayment === yearSub) && (monthNextPayment > obj.digitMonth) && (monthSub <= obj.digitMonth)) ||
          ((yearNextPayment > yearSub) && (monthNextPayment > obj.digitMonth))
        if (monthNextPayment === obj.digitMonth) obj.dayNextPayment = dayNextPayments
      })
      return [...calendar]
    }
  }
}
</script>

<style lang="css">
.page-subtitle > b {
  line-height: 16px;
  display: block;
}
.next-payment > .container {
  margin-bottom: 50px;
}
.calendar {
  margin: 20px 0 40px;
  width: 50%;
}

@media (max-width: 1400px) {
  .calendar {
    width: 60%;
  }
}

@media (max-width: 1200px) {
  .calendar {
    width: 75%;
  }
}

@media (max-width: 1100px) {
  .calendar {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .calendar {
    width: 90%;
  }
}

@media (max-width: 766px) {
  .calendar {
    width: 100%;
  }
}
</style>
