<template>
  <div class="product-form pr" :class="formLoader ? 'bg-loading' : ''">
    <form action="">
      <div class="section-title">
        <h2 class="bold">Заполните заявление на страхование</h2><br>
      </div>
      <div class="form-panel">
        <text-field
          mask="+7(###) ### ## ##"
          label="Мобильный телефон"
          v-model="form.phone_number"
          :disabled="!newClient"
          inputType="text"
          idFor="mobiles"
          :upLabel="!newClient"
        />
      </div>
      <person
        v-if="step >= 1"
        :iin="form.holder.individual_id_number"
        :phone="form.phone_number"
        @ready="readyHolder"
        @location="getLocation"
        @document="getDocument"
        @user="getUser"
        @form="getForm"
      />

      <h3 v-if="!!form.insureds.length">Застрахованные</h3>
      <template v-for="(insured, index) in form.insureds">
        <insured-person
          v-if="step >= 2"
          :reset="checkReset(form.holder.individual_id_number)"
          :iin="insured.individual_id_number"
          :phone="form.phone_number"
          :index="index"
          :uuid="insured.uuid"
          :key="`insured_${insured.uuid}`"
          :can_delete="!!form.insureds.length"
          @removeItem="removeInsured"
          @ready="readyInsured"
          @location="getLocationInsured"
          @document="getDocumentInsured"
          @user="getUserInsured"
          @form="getFormInsured"
        />
      </template>

      <div class="form-panel" v-if="step >= 2 && form.insureds.length < 2">
        <add-person-button @click.native="addNewInsured" />
      </div>

      <div class="form-panel" v-if="step >= 2">
        <car-info-fields :value="form.vehicle" @changed="changeVehicleProperty" @ready="readyVehicle" />
      </div>
        <div class="form-panel" v-if="step >= 4">
          <date-field
            id="start_date"
            label="Дата начала полиса"
            ref="beginDate"
            v-model="form.start_date"
            :minDate="new Date()"
            :maxDate="tomorrow"
            :disabled="true"
          />            <!--        :maxDate="new Date(new Date().setFullYear(new Date().getFullYear() + 2))"-->
        </div>
        <tariff
          v-if="step >= 5"
          :value="cascoTariff"
          :insurance_type="form.insurance_type"
          @changed="changedTariff"
        />
        <final-amount
          v-if="step >= 5"
          :value="finalAmount"
          @onSubmit="onSubmit"
        />
    </form>
    <confirm v-if="step === 6" :value="finalAmount.res" :temp_token="temp_token" @modalStatus="getModalStatus" @productAuth="productAuth"/>
    <otp
      v-if="step === 7"
      @code="otpCode"
      @resend="otpResend"
      @close="otpClose"
      :error="otp.error"
      :loader="otp.loading"
      :phone="form.phone_number"
    />
  </div>
</template>

<script>
// Modal
import Confirm from '@/components/subscription/Modals/Confirm'
import Otp from '@/components/subscription/Modals/Otp'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Fields
import TextField from '@/components/fields/TextField'
import DateField from '@/components/fields/DateField'
// FormBox
import AddPersonButton from '@/components/formBox/AddPersonButton'
import InsuredPerson from '@/components/formBox/InsuredPerson'
import CarInfoFields from '@/components/formBox/CarInfoFields'
import FinalAmount from '@/components/formBox/FinalAmount'
import Person from '@/components/formBox/Person'
import Tariff from '@/components/formBox/Tariff'
// Utils
import { convertFormForBackCasco } from '@/utils/person'
import { getPeopleByIin } from '@/api/info'
import { uuidv4 } from '@/utils/uuidv4'
import axios from 'axios'
// Mixins
import ePayPayment from '@/mixin/ePayPayment'
import commonToForms from '@/mixin/commonToForms'

export default {
  name: 'CascoLiteForm',
  mixins: [commonToForms, ePayPayment],
  components: {
    AddPersonButton,
    InsuredPerson,
    CarInfoFields,
    FinalAmount,
    TextField,
    DateField,
    Confirm,
    Tariff,
    Person,
    Otp
  },
  data: () => ({
    form: {
      insurance_type: 2225,
      insureds: [],
      vehicle: {
        uuid: uuidv4(),
        license_plate: '',
        model: '',
        territory: '',
        type: null,
        year: null
      }
    }
  }),
  computed: {
    ...mapGetters({
      cascoTariff: 'cascoTariff'
    })
  },
  methods: {
    ...mapActions({
      getCascoTariff: 'getCascoTariff'
    }),
    readyInsured (val) {
      if (val && this.step === 2 && this.form.vehicle.license_plate) {
        this.step = 5
      } else {
        this.step = 2
      }
    },
    changedTariff (val) {
      this.form.tariff = this.cascoTariff[val].title
      this.finalAmount.toPayment = this.cascoTariff[val].toPayment
      if (this.cascoTariff[val].discount) {
        this.finalAmount.cost = this.cascoTariff[val].price * 3
        this.finalAmount.discount = this.finalAmount.cost - this.finalAmount.toPayment
      } else this.finalAmount.cost = this.cascoTariff[val].price
    },
    // Get Insureds
    getFormInsured (val) {
      if (val.item) {
        this.form.insureds[val.index].individual_id_number = val.item.individual_id_number
        this.form.insureds[val.index].new_client = val.item.new_client
        this.form.insureds[val.index].checked = val.item.checked
      }
    },
    getUserInsured (val) {
      if (val.item) {
        this.form.insureds[val.index].last_name = val.item.last_name
        this.form.insureds[val.index].first_name = val.item.first_name
        this.form.insureds[val.index].s_name = val.item.s_name
        this.form.insureds[val.index].gender = val.item.gender
        this.form.insureds[val.index].birth_date = val.item.birth_date
      }
    },
    getLocationInsured (val) {
      if (val.item) {
        this.form.insureds[val.index].address_region = +val.item.address_region
        this.form.insureds[val.index].address_state = val.item.address_state
        this.form.insureds[val.index].address_city = +val.item.address_city
        this.form.insureds[val.index].address_street_full = val.item.address_street
        this.form.insureds[val.index].address_street_no = val.item.address_street_no
        this.form.insureds[val.index].address_flat_no = val.item.address_flat_no
      }
    },
    getDocumentInsured (val) {
      if (val.item) {
        this.form.insureds[val.index].doc_issue_date = val.item.doc_issue_date
        this.form.insureds[val.index].doc_number = val.item.doc_number
        this.form.insureds[val.index].doc_type = val.item.doc_type
      }
    },
    // Check
    checkReset (val) {
      if (val) {
        const check = val.length === 12
        if (!check) {
          this.emptyVehicle()
          this.form.insureds = []
        }
        return check
      }
    },
    // Submit
    onSubmit () {
      const isChecked = this.checkClients()
      if (isChecked) this.getCalculation()
    },
    // Import Data
    importTariff () {
      getPeopleByIin(this.form.holder.individual_id_number)
        .then(res => {
          const data = res.data
          if (data.manID !== 0) {
            this.getCascoTariff([data.manID, this.temp_token])
          } else this.getCascoTariff(['', this.temp_token])
        })
    },
    removeInsured: function (changed) {
      const filteredInsureds = this.form.insureds.filter(obj => obj.uuid !== changed.item.uuid)
      this.form.insureds = [...filteredInsureds]
    },
    addNewInsured: function () {
      const newInsured = {
        uuid: uuidv4(),
        individual_id_number: '',
        gender: null,
        doc_type: null,
        doc_number: null,
        doc_issue_date: null,
        address_region: null,
        address_state: '',
        address_city: null,
        address_street_full: '',
        address_street_no: '',
        address_flat_no: ''
      }
      this.form.insureds.push({ ...newInsured })
    },
    changeVehicleProperty (changed) {
      for (const i in changed) {
        this.form.vehicle[i] = changed[i]
      }
      this.step = 5
    },
    readyVehicle (val) {
      if (!val) {
        this.step = 4
      } else this.step = 5
    },
    emptyVehicle () {
      this.form.vehicle.license_plate = ''
      this.form.vehicle.model = ''
      this.form.vehicle.territory = ''
      this.form.vehicle.type = null
      this.form.vehicle.year = null
    },
    async sendDraft () {
      const { data } = await axios.post('/policy-draft', convertFormForBackCasco(this.form))
      const token = localStorage.getItem('accessToken')
      if (!token) this.temp_token = data.temp_token
    },
    getCalculation () {
      let id = null
      this.formLoader = true
      axios.post(this.checkToken() + '/insurance-products/calculations/subscription/casco-lite', convertFormForBackCasco(this.form))
        .then(res => {
          id = res.data.id
          if (id) {
            axios.get(this.checkToken() + '/insurance-products/calculations/' + id)
              .then(res => {
                this.finalAmount.res = res.data
                this.step = 6
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setTimeout(() => {
            this.formLoader = false
          }, 1000)
        })
    }
  },
  watch: {
    'form.phone_number': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue.length === 17) {
          this.step = 1
        } else {
          this.step = 0
        }
      }
    }
  }
}
</script>

<style scoped></style>
