<template>
  <section class="steps-slider-block" v-if="steps">
    <carousel
      :items="1"
      :autoHeight="true"
      :nav="false"
      :responsive="{766:{autoHeight:true}}"
      :lazyLoad="true"
      @mouseover.native="getActive"
      @touchmove.native="getActive"
      @click.native="getActive"
      id="steps-slider"
    >
      <div class="steps-slider-item"
           v-for="(item, index) in steps"
           :key="index + Math.random()"
           :data-item="index"
      >
        <h4 class="steps-slider-item__title">Шаг {{ item.id }}</h4>
        <div class="steps-slider-item__description" v-html="item.content"></div>
      </div>
    </carousel>
  </section>

</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'StepsSlider',
  components: { carousel },
  props: {
    steps: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    getActive () {
      setTimeout(() => {
        this.$emit('val', parseInt(document.getElementById('steps-slider')?.getElementsByClassName('active')[0]?.childNodes[0]?.dataset.item))
      }, 100)
    }
  }
}
</script>

<style scoped>
  .steps-slider-block {
    margin: 10px 5px 16px 5px;
    padding: 20px;
    background: #F4F4F4;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  .steps-slider-item {
    margin-bottom: 30px;
  }
  .steps-slider-item__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    color: #2A353D;
    margin-bottom: 20px;
  }
  .steps-slider-item__description {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #2A353D;
    margin-bottom: 10px;
  }
</style>
