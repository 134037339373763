<template>
  <section class="subscription-product-block">
    <div class="row" v-if="['ReSubscribe', 'CheckSubscription'].includes($route.name)">
      <div class="col-6"></div>
      <div class="col-5">
        <router-link :to="{ name: setName }">
          <button class="btn border-radius block">
            {{ setTitleBtn }}
          </button>
        </router-link>
        <br/>
      </div>
    </div>
    <div class="container">
      <carousel
        :items="5"
        :margin="20"
        :dots="false"
        :center="true"
        :autoWidth="true"
        :responsive="{766:{items:1}}"
        @mouseover.native="getActive"
        @touchmove.native="getActive"
        id="miniSlider"
      >
        <sub-mini-slider-item
          v-for="(card, index) in info"
          :key="index + Math.random()"
          :item="card"
          :index="index"
        />
      </carousel>
    </div>
  </section>
</template>

<script>
// Utils
import carousel from 'vue-owl-carousel'
// Widgets
import SubMiniSliderItem from '@/components/subscription/SubscriptionsMiniSlider/SubscriptionsMiniSliderItem'

export default {
  name: 'SubscriptionsMiniSlider',
  components: { carousel, SubMiniSliderItem },
  props: {
    info: Array
  },
  methods: {
    getActive () {
      setTimeout(() => {
        this.$emit('val', parseInt(document.getElementById('miniSlider')?.getElementsByClassName('center')[0]?.childNodes[0]?.dataset.item))
      }, 200)
    }
  },
  computed: {
    setTitleBtn () {
      return ['ReSubscribe'].includes(this.$route.name) ? 'К действующим' : 'К архивным'
    },
    setName () {
      return this.$route.name === 'ReSubscribe' ? 'CheckSubscription' : 'ReSubscribe'
    }
  }
}
</script>

<style lang="css">
  .subscription-product-block .owl-stage-outer:before, .subscription-product-block .owl-stage-outer:after {
    position: absolute;
    height: 100%;
    content: '';
    width: 35px;
    z-index: 1;
    top: 0;
  }
  .subscription-product-block .owl-stage-outer:before {
    background: linear-gradient(90deg,rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 0) 100%);
    left: 0;
  }
  .subscription-product-block .owl-stage-outer:after {
    background: linear-gradient(270deg,rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 0) 100%);
    right: 0;
  }
  .subscription-product-block .container {
    position: relative;
  }
  .subscription-product-block .owl-nav .owl-prev,
  .subscription-product-block .owl-nav .owl-next {
    background-color: transparent !important;
    color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    height: 470px;
    width: 55px;
    top: 50px
  }
  .subscription-product-block .owl-nav .owl-next {
    /*background-image: url("../images/slider-next.svg") !important;*/
    background-repeat: no-repeat !important;
    background-position: center !important;
    -webkit-filter: invert(.1);
    filter: invert(.1);
    transition: .2s;
    right: -60px;
  }
  .subscription-product-block .owl-nav .owl-next:hover {
    -webkit-filter: invert(.8);
    filter: invert(.8);
  }
  .subscription-product-block .owl-nav .owl-prev {
    /*background-image: url("../images/slider-prev.svg") !important;*/
    background-repeat: no-repeat !important;
    background-position: center !important;
    -webkit-filter: invert(.1);
    filter: invert(.1);
    transition: .2s;
    left: -60px;
  }
  .subscription-product-block .owl-nav .owl-prev:hover {
    -webkit-filter: invert(.8);
    filter: invert(.8);
  }

  @media(max-width:992px) {
    .subscription-product-block .owl-nav {
      display: none !important;
    }
  }
</style>
