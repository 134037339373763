import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import store from '@/store'
import kk from './languages/kk'
import ru from './languages/ru'

Vue.use(VueI18n)

const languages = { ru, kk }

export default new VueI18n({
  locale: localStorage.getItem('lang') || 'ru',
  messages: languages
})
