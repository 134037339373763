import i18n from '@/locales/index'
export default {
  state: {
    selectedLang: localStorage.getItem('lang') || 'ru'
  },
  mutations: {
    SET_LANG (state, payload) {
      localStorage.setItem('lang', payload)
      state.selectedLang = payload
      i18n.locale = payload
      document.documentElement.setAttribute('lang', payload)
    }
  },
  actions: {
    setLanguage ({ commit }, langId) {
      if (langId) {
        commit('SET_LANG', langId)
      }
    }
  },
  getters: {
    selectedLanguage (state) {
      return state.selectedLang
    },
    getTranslationFieldSuffix (state) {
      return state.selectedLang === 'ru' ? '' : `_${state.selectedLang}`
    },
    getAltTranslationFieldSuffix (state) {
      const suffix = state.selectedLang === 'ru' ? '' : `_${state.selectedLang}`
      if (suffix === '_kk') {
        return '_kz'
      }
      return suffix
    }
  }
}
