<template>
  <section class="slider-block" v-if="programs">
    <carousel
      :items="1"
      :autoHeight="true"
      :nav="false"
      :responsive="{766:{autoHeight:true}}"
      @mouseover.native="getActive"
      @touchmove.native="getActive"
      @click.native="getActive"
      :lazyLoad="true"
      id="slider"
    >
      <template slot="prev">
        <span class="prev">
          <img :src="require('@/assets/images/slider-prev.svg')" alt="slider-prev.svg">
        </span>
      </template>
      <div class="slider-item"
           v-for="(item, index) in programs"
           :key="index + Math.random()"
           :data-item="index"
      >
        <div class="slider-item__main mb-10 mt-10">
          <div class="container">
            <div class="slider-item__row pr">
              <div class="slider-item__content">
                <h4 class="slider-item__subtitle" v-html="item.title"></h4>
                <div class="slider-item__description" v-html="item.description"></div>
                <div class="row slider-item__btn-web" v-if="item.link_to_design && item.link_to_description">
                  <div class="col-7">
                    <a :href="item.link_to_design" target="_blank" class="btn orange border-radius block mb-10">Оформить на год</a>
                    <router-link :to="item.link_to_description" class="btn orange border-radius block">Оформить по подписке</router-link>
                  </div>
                  <div class="col-5"></div>
                </div>
              </div>
              <div class="slider-item__image">
                <img :src="item.imageProduct.url" :alt="item.imageProduct.alt">
              </div>
            </div>
            <div class="row slider-item__btn-adaptive" v-if="item.link_to_design && item.link_to_description">
              <a :href="item.link_to_design" target="_blank" class="btn orange border-radius block mb-10">Оформить на год</a>
              <router-link :to="item.link_to_description" class="btn orange border-radius block">Оформить по подписке</router-link>
            </div>
            <span class="footnote">* стоимость зависит от выбранного тарифа</span>
          </div>
        </div>
      </div>
      <template slot="next">
        <span class="next">
          <img :src="require('@/assets/images/slider-next.svg')" alt="slider-next.svg">
        </span>
      </template>
    </carousel>
  </section>

</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'Slider',
  components: { carousel },
  props: {
    programs: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    getActive () {
      setTimeout(() => {
        this.$emit('val', parseInt(document.getElementById('slider')?.getElementsByClassName('active')[0]?.childNodes[0]?.dataset.item))
      }, 100)
    }
  }
}
</script>

<style scoped>

</style>
