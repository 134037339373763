<template>
  <section class="change-tariff">
    <services-header/>
    <services-body
      v-if="authUser"
      :status="subStatuses.activeSub"
      empty="Вы пока ничего не застраховали, но всё ещё впереди!"
    >
      <div class="container">
        <div class="row">
          <div class="col-7" :class="authUser ? 'dashed-line' : ''">
            <div class="subscription-information" v-if="authUser && activeSub">
              <p class="page-subtitle"><b>Информация о подписке</b></p>
              <sub-item-info :item="activeSub[activeItem]"/>
            </div>
          </div>
          <div class="col-5">
            <sub-mini-slider v-on:val="isActive" :info="activeSub" v-if="authUser && activeSub"/>
          </div>
        </div>
      </div>
      <range-slider v-if="authUser && activeSub" :item="activeSub[activeItem]" @changeTariff="changeTariff"/>
    </services-body>
    <services/>
    <success
      @modalStatus="changeTariff"
      :title="'Тариф успешно изменён'"
      :description="'Тариф начнёт действовать с ' + getDate(activeSub[activeItem].dateNextWriteDowns)"
      v-if="responseStatus"
    />
  </section>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'
// Widgets
import SubMiniSlider from '@/components/subscription/SubscriptionsMiniSlider/SubscriptionsMiniSlider'
import SubItemInfo from '@/components/subscription/SubscriptionItemInformation'
import ServicesHeader from '@/components/subscription/ServicesHeader'
import ServicesBody from '@/components/subscription/ServicesBody'
import RangeSlider from '@/components/subscription/RangeSlider'
import Services from '@/components/subscription/Services'
// Modals
import Success from '@/components/subscription/Modals/Success'
// Utils
import moment from 'moment'

export default {
  name: 'ChangeTariff',
  metaInfo: {
    title: 'Сменить тариф',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    ServicesHeader,
    ServicesBody,
    SubMiniSlider,
    SubItemInfo,
    RangeSlider,
    Services,
    Success
  },
  data: () => ({
    activeItem: 0,
    responseStatus: false
  }),
  computed: {
    ...mapGetters({
      subStatuses: 'subStatuses',
      activeSub: 'activeSub',
      authUser: 'authUser'
    })
  },
  methods: {
    isActive (id) {
      this.activeItem = id
    },
    changeTariff (val) {
      this.responseStatus = val
    },
    getDate (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  }
}
</script>

<style lang="css">
.change-tariff > .container {
  margin-bottom: 50px;
}
</style>
