<template>
  <section class="tariff-block" :class="tariffLoading ? 'bg-loading' : ''" v-if="this.tariff.rangeValue">
    <div class="container">
      <div class="tariff-block_title">Поменять тариф</div>
      <vue-slide-bar
        class="main"
        v-model="tariff.value"
        :data="tariff.data"
        :range="tariff.range"
        :processStyle="custom.processStyle"
        :lineHeight="custom.lineHeight"
        :tooltipStyles="custom.tooltipStyles"
        @callbackRange="callbackRange"
      >
        <template slot="tooltip">
          <span class="price">{{tariff.value}} тг/мес</span>
          <span class="tooltip">
            <span></span>
          </span>
        </template>
      </vue-slide-bar>
      <div class="tariff_row">
        <div class="tariff_column">
          <div class="item-slider__panel">
            <div class="item-slider__title">Покрытие соседям</div>
            <vue-slide-bar
              v-model="tariff.value"
              :data="tariff.data"
              :range="tariff.range"
              :processStyle="custom.processStyle"
              :lineHeight="custom.lineHeight"
              :tooltipStyles="custom.tooltipStyles"
              :isDisabled="true"
              :draggable="false"
              @callbackRange="callbackRange"
            >
              <template slot="tooltip">
                <span class="tooltip">
                  <span></span>
                </span>
              </template>
            </vue-slide-bar>
          </div>
          <div class="item-slider__panel">
            <div class="item-slider__title">Покрытие держателю подписки</div>
            <vue-slide-bar
              v-model="tariff.value"
              :data="tariff.data"
              :range="tariff.range"
              :processStyle="custom.processStyle"
              :lineHeight="custom.lineHeight"
              :tooltipStyles="custom.tooltipStyles"
              :isDisabled="true"
              :draggable="false"
              @callbackRange="callbackRange"
            >
              <template slot="tooltip">
                <span class="tooltip">
                  <span></span>
                </span>
              </template>
            </vue-slide-bar>
          </div>
        </div>
        <div class="tariff_column pl-15">
          <div class="tariff-info">
            <div class="tariff-info__title">Тариф {{ tariff.rangeValue.label }}</div>
            <div class="tariff_row">
              <table>
                <tr>
                  <td><small>Подписка</small></td>
                  <td><small>Тариф</small></td>
                </tr>
                <tr>
                  <td>{{ item.insrTypeName.replace(/ по подписке/g, '') }}</td>
                  <td>{{ tariff.rangeValue.label }}</td>
                </tr>
                <tr>
                  <td><small>Дата подписки</small></td>
                  <td><small>Сумма подписки</small></td>
                </tr>
                <tr>
                  <td>{{ getDate(item.dateGiven) }}</td>
                  <td>{{ tariff.value }} тенге в месяц</td>
                </tr>
              </table>
            </div>
            <div class="tariff_row">
              <table>
                <tr>
                  <td><strong>Покрытие Вам:</strong></td>
                  <td>
                    <strong v-if="['3014'].includes(item.insrType)">{{ this.tariff.rangeValue.priceD | formatMoney }} тенге</strong>
                    <strong v-else>{{ this.tariff.rangeValue.priceD * 2 | formatMoney }} тенге</strong>
                  </td>
                </tr>
                <tr v-if="['3014'].includes(item.insrType)">
                  <td><strong>Покрытие соседям:</strong></td>
                  <td><strong>{{ this.tariff.rangeValue.priceS | formatMoney }} тенге</strong></td>
                </tr>
                <tr v-if="['3014'].includes(item.insrType)">
                  <td><strong>Покрытие ИТОГО:</strong></td>
                  <td><strong>{{ (this.tariff.rangeValue.priceD + this.tariff.rangeValue.priceS) | formatMoney }} тенге</strong></td>
                </tr>
              </table>
            </div>
            <button
              :disabled="item.premMonth === tariff.value"
              class="btn orange border-radius block"
              @click="openSMSModal"
            >
              Выбрать тариф
            </button>
          </div>
        </div>
      </div>
      <sms-modal
        v-if="isSMSModal"
        :loading="loading"
        :visible="isSMSModal"
        :phone="getFilteredPhone"
        :error="errorSms"
        @close="closeSMSModal"
        @code="checkCode"
        @resend="otpResend"
      />
    </div>
  </section>
</template>

<script>
// Utils
import VueSlideBar from 'vue-slide-bar'
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { generateOtp, verifyOtp } from '@/api/info'
// Modals
import SmsModal from '@/components/subscription/Modals/SMSModal'

export default {
  name: 'RangeSlider',
  components: { VueSlideBar, SmsModal },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: () => ({
    tariffLoading: false,
    success: false,
    loading: false,
    errorSms: false,
    isSMSModal: false,
    tariff: {
      value: null,
      data: [],
      range: [],
      rangeValue: {}
    },
    custom: {
      val: 1,
      lineHeight: 10,
      processStyle: {
        backgroundColor: '#42b883'
      },
      tooltipStyles: {
        backgroundColor: '#42b883',
        borderColor: '#42b883'
      }
    }
  }),
  computed: {
    ...mapGetters({
      apartmentTariff: 'apartmentTariff',
      houseTariff: 'houseTariff',
      cascoTariff: 'cascoTariff',
      authUser: 'authUser'
    }),
    getFilteredPhone () {
      const phone = this.authUser.phone_number
      return '+7 (' + phone.substring(0, 3) + ') ***-**-' + phone.substring(phone.length - 2)
    }
  },
  methods: {
    async generateOtp () {
      this.loading = true
      generateOtp('destroy', this.item.policyId) // !!! поменять на новый параметр
        .then(res => {
          console.log(res)
        })
        .catch(e => {
          console.log('Ошибка генерации отп при смене тарифа', e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    otpResend (val) {
      if (val) {
        this.generateOtp()
      }
    },
    openSMSModal () {
      this.isSMSModal = true
      this.generateOtp()
    },
    closeSMSModal () {
      this.isSMSModal = false
    },
    callbackRange (val) {
      this.tariff.rangeValue = val
    },
    getTariffs (insrType) {
      const type = parseInt(insrType)
      let tariffs = ''
      if (type === 2225) {
        tariffs = this.cascoTariff
      } else if (type === 3014) {
        tariffs = this.apartmentTariff
      } else if (type === 3016) {
        tariffs = this.houseTariff
      }
      this.tariff.range = []
      this.tariff.data = []
      tariffs.forEach(item => {
        const data = {
          label: item.title,
          price: item.price,
          priceS: item.to,
          priceD: item.from,
          total: item.total
        }
        this.tariff.range.push(data)
        this.tariff.data.push(item.price)
      })
      this.tariff.range.sort((a, b) => a.price - b.price)
      this.tariff.data.sort((a, b) => a - b)
      setTimeout(() => {
        this.selectedTariff()
      }, 100)
    },
    getDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    selectedTariff () {
      const foundObj = this.tariff.range.filter(item => this.tariff.value === item.price)
      this.tariff.rangeValue = {
        label: foundObj[0].label,
        price: foundObj[0].price,
        priceD: foundObj[0].priceD,
        priceS: foundObj[0].priceS,
        total: foundObj[0].total
      }
    },
    checkCode (code) {
      this.loading = true
      verifyOtp('destroy', this.item.policyId, code) // !!! поменять на новый параметр
        .then(res => {
          if (res.status === 200) {
            this.errorSms = false
            this.isSMSModal = false
            this.sendNewTariff()
          }
        })
        .catch(e => {
          this.errorSms = true
          console.log('Ошибка при верификации отп при отмене', e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendNewTariff () {
      this.tariffLoading = true
      axios.post('/subscription/update-tariff', {
        policy_id: this.item.policyId,
        tariff: this.tariff.rangeValue.label
      })
        .then(res => {
          if (res.status === 200) {
            this.success = true
            this.$emit('changeTariff', true)
          }
        })
        .finally(() => {
          this.tariffLoading = false
        })
    }
  },
  watch: {
    'item.insrType': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        this.getTariffs(newValue)
      }
    },
    'item.premMonth': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.tariff.value = newValue
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
