import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Vue from 'vue'

import CheckSubscription from '@/views/subscription/CheckSubscription'
import ChangeTariff from '@/views/subscription/ChangeTariff'
import NextPayment from '@/views/subscription/NextPayment'
import Unsubscribe from '@/views/subscription/Unsubscribe'
import ReSubscribe from '@/views/subscription/ReSubscribe'
import ChangeCard from '@/views/subscription/ChangeCard'
import Apartment from '../views/subscription/Apartments'
import CascoLite from '@/views/subscription/CascoLite'
import MyHouse from '@/views/subscription/MyHouse'
import Index from '../views/subscription/Index.vue'
import Reviews from '@/views/subscription/Reviews'
import SuccessPayment from '@/views/subscription/SuccessPayment'
import FailPayment from '@/views/subscription/FailPayment'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/kvartinyy-otvet-po-podpiske',
    component: Apartment,
    name: 'Apartment',
    props: true
  },
  {
    path: '/casco-lite-po-podpiske',
    component: CascoLite,
    name: 'CascoLite',
    props: true
  },
  {
    path: '/moy-dom-po-podpiske',
    component: MyHouse,
    name: 'MyHouse',
    props: true
  },
  {
    path: '/check-subscription',
    name: 'CheckSubscription',
    component: CheckSubscription,
    meta: { title: 'Проверить подписку' }
  },
  {
    path: '/change-card',
    name: 'ChangeCard',
    component: ChangeCard,
    meta: { title: 'Сменить карту' }
  },
  {
    path: '/next-payment',
    name: 'NextPayment',
    component: NextPayment,
    meta: { title: 'Следующая оплата' }
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: Unsubscribe,
    meta: { title: 'Отменить подписку' }
  },
  {
    path: '/change-tariff',
    name: 'ChangeTariff',
    component: ChangeTariff,
    meta: { title: 'Сменить тариф' }
  },
  {
    path: '/re-subscribe',
    name: 'ReSubscribe',
    component: ReSubscribe,
    meta: { title: 'Возобновить подписку' }
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews,
    meta: { title: 'Отзывы' }
  },
  {
    path: '/success-payment',
    name: 'SuccessPayment',
    component: SuccessPayment,
    props: true
  },
  {
    path: '/fail-payment',
    name: 'FailPayment',
    component: FailPayment,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView()
  }
})

export default router
