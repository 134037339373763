<template>
  <section class="check-subscription">
    <services-header/>
    <services-body
      v-if="authUser"
      :status="subStatuses.activeSub"
      empty="Вы пока ничего не застраховали, но всё ещё впереди!"
    >
      <div class="container">
        <div class="row">
          <div class="col-7" :class="activeSub ? 'dashed-line' : ''">
            <div class="subscription-information" v-if="authUser && activeSub">
              <p class="page-subtitle"><b>Информация о подписке</b></p>
              <sub-item-info :item="activeSub[activeItem]"/>
            </div>
          </div>
          <div class="col-5">
            <sub-mini-slider
              :value="activeItem"
              v-on:val="isActive"
              :info="activeSub"
              v-if="authUser && activeSub"
            />
          </div>
        </div>
      </div>
    </services-body>
    <services/>
  </section>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'
// Widgets
import SubMiniSlider from '@/components/subscription/SubscriptionsMiniSlider/SubscriptionsMiniSlider'
import SubItemInfo from '@/components/subscription/SubscriptionItemInformation'
import ServicesHeader from '@/components/subscription/ServicesHeader'
import ServicesBody from '@/components/subscription/ServicesBody'
import Services from '@/components/subscription/Services'

export default {
  name: 'CheckSubscription',
  metaInfo: {
    title: 'Проверить подписку',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    ServicesHeader,
    SubMiniSlider,
    ServicesBody,
    SubItemInfo,
    Services
  },
  data: () => ({
    activeItem: 0
  }),
  methods: {
    isActive (id) {
      this.activeItem = id
    }
  },
  computed: {
    ...mapGetters({
      subStatuses: 'subStatuses',
      activeSub: 'activeSub',
      authUser: 'authUser'
    })
  }
}
</script>

<style scoped>
  .check-subscription > .container {
    margin-bottom: 50px;
  }
</style>
