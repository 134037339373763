import axios from 'axios'

export function convertTariffs (data) {
  const tariffs = []
  data.forEach(item => {
    const tariff = {
      title: item.tarif === 'LIGHT' ? 'Легкий' : item.tarif === 'OPTIMAL' ? 'Оптимальный' : item.tarif === 'STANDARD' ? 'Стандартный' : '',
      price: item.premMonth,
      to: item.insSum / 2,
      from: item.insSum / 2,
      total: item.insSum,
      toPayment: item.premMonthForMen && item.premMonthForMen * 3 === item.premThreeMonth ? item.premThreeMonth : !item.premMonthForMen ? item.premThreeMonth : item.premMonth,
      discount: item.premMonthForMen * 3 === item.premThreeMonth || !item.premMonthForMen
    }
    tariffs.push(tariff)
    tariffs.sort((a, b) => a.toPayment - b.toPayment)
  })
  return tariffs
}

export function convertTariffUrl (params, realUrl) {
  const clientId = `?clientId=${params[0]}`
  const tempToken = params[1]
  if (tempToken) axios.defaults.headers.common['temp-token'] = `${tempToken}`
  const prefix = tempToken ? 'web/' : ''

  return prefix + realUrl + clientId
}
