<template>
  <div class="feedback-modal">
    <div class="feedback-body">
      <div class="close" v-on:click="statusFeedback = false">&times;</div>
      <div class="feedback-info">
        <h3 v-if="rating<3" class="feedback-text text-center">Извините за низкий уровень сервиса, мы примем меры!</h3>
        <h3 v-if="rating===3" class="feedback-text text-center">Спасибо за отзыв, будем становиться лучше!</h3>
        <h3 v-if="rating>3" class="feedback-text text-center">Спасибо за высокую оценку нашей работы!</h3>
      </div>
      <router-link :to="{ name: 'Index' }">
        <button class="btn sm outline green border-radius center" v-on:click="statusFeedback = false">Закрыть</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackRatingModal',
  props: {
    rating: null
  },
  data: () => ({
    statusFeedback: null
  }),
  watch: {
    statusFeedback: {
      deep: true,
      handler: function (newValue) {
        this.$emit('modalStatus', newValue)
      }
    }
  }
}
</script>

<style scoped>
  .feedback-modal {
    background-color: rgba(0, 0, 0, .3);
    backdrop-filter: blur(8px);
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 10;
    left: 0;
    top: 0;
  }
  .feedback-body {
    background-color: #f7f7f7;
    position: relative;
    border-radius: 6px;
    padding: 30px;
    width: 500px;
  }
  .feedback-body .close {
    position: absolute;
    line-height: 17px;
    transition: .2s;
    font-size: 30px;
    cursor: pointer;
    color: #99a3b3;
    padding: 20px;
    right: 0;
    top: 0;
  }
  .feedback-body .close:hover {
    color: #000000;
  }
  .feedback-info {
    margin: 30px 0;
  }
  .feedback-text {
    font-size: 20px;
    display: block;
    height: 80px;
  }
</style>
