<template>
  <section class="product-tabs__info_card pr">
    <product-item-information
      v-for="(item, index) in setArrInfo"
      :key="index + Math.random()"
      :product_info_item="item"
      :last_item="setArrInfo[setArrInfo.length - 1]"
      @visibleProductInformation="visibleProductInformation"
    />
  </section>
</template>

<script>
// Widgets
import ProductItemInformation from '@/components/subscription/ProductInformation/ProductItemInformation'

export default {
  name: 'ProductInformation',
  components: {
    ProductItemInformation
  },
  props: {
    product_name: String
  },
  data: () => ({
    product_info: {
      casco: [
        {
          title: 'Преимущества программы',
          description:
            '<p><b>Убережем от безответственного вождения других водителей</b></br>' +
            'В случае, если у другого водителя отсутствует ОГПО Авто и он нанес ущерб вашему автомобилю, мы покроем расходы по программе КАСКО по подписке.</p></br>' +
            '<p><b>Дополнительная защита к обязательной страховке</b></br>' +
            'КАСКО подписке является дополнительной защитой к обязательному страхованию. В случае ДТП по вашей вине покрытие ущерба потерпевшей стороне будет осуществляться по вашему полису ОГПО ВТС, а на ремонт вашей машины средства будут выделены от нашей страховой компании по программе КАСКО по подписке.</p></br>' +
            '<p><b>Доплатим за ремонт вашего автомобиля</b></br>' +
            'Максимальный размер выплаты, установленный законодательством по обязательной автостраховке (ОГПО Авто), составляет 600 МРП. Если же ущерб превысит указанную сумму – наша страховка покроет расходы, даже если виноваты в ДТП не вы.</p></br>' +
            '<p><b>Возместим расходы на ремонт вашего автомобиля</b></br>' +
            'Даже если ДТП произошло по вашей вине, мы покроем причиненный ущерб. Высокие и быстрые выплаты на восстановление ущерба нашим клиентам. Онлайн подача документов для получения выплаты.</p>',
          isOpen: false
        },
        {
          title: 'Описание продукта',
          description:
            '<p>Программа «КАСКО по подписке» - надежная дополнительная защита к полису обязательного страхования автомобиля, доступная в новом формате.</br>' +
            'Приобретая полис, Вы получаете защиту своего авто вне зависимости от вашей вины при ДТП!</p></br>' +
            '<p><b>Если Вы оформляете подписку впервые, то на первые три месяца, мы дарим Вам скидку:</b></p>' +
            '<p>При тарифе «Легкий» - 570 ₸/мес вместо 2 070 тенге;</p>' +
            '<p>При тарифе «Стандартный» - 870 ₸/мес вместо 2 970 тенге;</p>' +
            '<p>При тарифе «Оптимальный» - 1 170 ₸/мес вместо 3 870 тенге.</p>' +
            '</br><br>Если Вы являетесь виновником ДТП, обязательная страховка покроет ущерб пострадавшему, а КАСКО по подписке возместит расходы на ремонт вашего авто.</br>' +
            'Договор вступает в силу в день оплаты, страховая защита начинается на следующий день и заканчивается одновременно с датой окончания действия Договора.</br>' +
            'Ежемесячно, с карты, которую вы использовали для покупки полиса, будет происходить списание и подписка будет продлеваться автоматически.</p>',
          isOpen: false
        },
        {
          title: 'Страховые выплаты по программе',
          description:
            '<p>При тарифе «Легкий» - за 690 ₸/мес выплатим до 200 000 тенге;</p></br>' +
            '<p>При тарифе «Стандартный» - за 990 ₸/мес выплатим до 300 000 тенге;</p></br>' +
            '<p>При тарифе «Оптимальный» - за 1290 ₸/мес выплатим до 400 000 тенге.</p>',
          isOpen: false
        }
        // {
        //   title: 'Как оформить подписку:',
        //   description: [
        //     {
        //       id: 1,
        //       url: 'https://www.adorama.com/alc/wp-content/uploads/2021/05/bird-wings-flying-feature.gif',
        //       alt: 'Ввести свои данные',
        //       content: 'Ввести свои данные'
        //     },
        //     {
        //       id: 2,
        //       url: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/5eeea355389655.59822ff824b72.gif',
        //       alt: 'Выбрать подходящий тариф',
        //       content: 'Выбрать подходящий тариф'
        //     },
        //     {
        //       id: 3,
        //       url: 'https://lukeroberts.tv/blog/wp-content/uploads/2019/05/gif1.gif',
        //       alt: 'Привязать карту',
        //       content: 'Привязать карту'
        //     },
        //     {
        //       id: 4,
        //       url: 'https://user-images.githubusercontent.com/6876788/96633009-d1818000-1318-11eb-9f1d-7f914f4ccb16.gif',
        //       alt: 'Дождаться списания средств с карты',
        //       content: 'Дождаться списания средств с карты'
        //     },
        //     {
        //       id: 5,
        //       url: 'https://ec.europa.eu/eurostat/statistics-explained/images/7/7d/Test_animated_image.gif',
        //       alt: 'Получить уведомление об активной подписке',
        //       content: 'Получить уведомление об активной подписке'
        //     }
        //   ],
        //   isOpen: false
        // }
      ],
      apartment: [
        {
          title: 'Преимущества программы',
          description:
            '<p><b>«Квартирный ответ по подписке» будет полезен, если нужно:</b></p>' +
            '- сделать ремонт вашей квартиры после залива;</br>' +
            '- возместить убытки соседям в случае залива вами их квартиры;</br>' +
            '- купить новую технику в случае кражи, пожара или другого страхового события.</br>' +
            '</br><p><b>Что можно застраховать:</b></p>' +
            '- внутреннюю отделку, инженерные сети и оборудование;</br>' +
            '- бытовую, аудио- и видеотехнику;</br>' +
            '- мебель;</br>' +
            '- гражданскую ответственность.</br>' +
            '</br><p><b>От каких рисков страхует программа:</b></p>' +
            '- пожар, удар молнии, взрыв;</br>' +
            '- воздействие жидкости;</br>' +
            '- противоправные действия третьих лиц (кража);</br>' +
            '- внешние механические воздействия (падение предметов).',
          isOpen: false
        },
        {
          title: 'Описание продукта',
          description:
            '<p><b>Если Вы оформляете подписку впервые, то на первые три месяца, мы дарим Вам скидку:</b></p>' +
            '<p>При тарифе «Легкий» - 270 ₸/мес вместо 1 470 тенге;</p>' +
            '<p>При тарифе «Стандартный» - 570 ₸/мес вместо 2 670 тенге;</p>' +
            '<p>При тарифе «Оптимальный» - 870 ₸/мес вместо 3 870 тенге.</p>' +
            '<p></br>Программа «Квартирный ответ по подписке» - удобное решение, которое поможет не только возместить ущерб, причиненный имуществу Вашей квартиры, но и сохранить добрые отношения с соседями.' +
            '</br>Мы убережем Ваше имущество от финансовых потерь.</br>Со страховым продуктом «Квартирный ответ» ваша квартира будет защищена от пожара, потопа, кражи. Дополнительно будет застрахована ваша ответственность перед соседями.</p>',
          isOpen: false
        },
        {
          title: 'Страховые выплаты по программе',
          description:
            '<p>При тарифе «Легкий» - за 490 ₸/мес выплатим до 500 000 Вам и до 500 000 соседям;</p></br>' +
            '<p>При тарифе «Стандартный» - за 890 ₸/мес выплатим до 1 000 000 и до 1 000 000 соседям;</p></br>' +
            '<p>При тарифе «Оптимальный» - за 1290 ₸/мес выплатим до 1 500 000 и до 1 500 000 соседям.</p>',
          isOpen: false
        }
        // {
        //   title: 'Как оформить подписку:',
        //   description: [
        //     {
        //       id: 1,
        //       url: 'https://www.adorama.com/alc/wp-content/uploads/2021/05/bird-wings-flying-feature.gif',
        //       alt: 'Ввести свои данные',
        //       content: 'Ввести свои данные'
        //     },
        //     {
        //       id: 2,
        //       url: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/5eeea355389655.59822ff824b72.gif',
        //       alt: 'Выбрать подходящий тариф',
        //       content: 'Выбрать подходящий тариф'
        //     },
        //     {
        //       id: 3,
        //       url: 'https://lukeroberts.tv/blog/wp-content/uploads/2019/05/gif1.gif',
        //       alt: 'Привязать карту',
        //       content: 'Привязать карту'
        //     },
        //     {
        //       id: 4,
        //       url: 'https://user-images.githubusercontent.com/6876788/96633009-d1818000-1318-11eb-9f1d-7f914f4ccb16.gif',
        //       alt: 'Дождаться списания средств с карты',
        //       content: 'Дождаться списания средств с карты'
        //     },
        //     {
        //       id: 5,
        //       url: 'https://ec.europa.eu/eurostat/statistics-explained/images/7/7d/Test_animated_image.gif',
        //       alt: 'Получить уведомление об активной подписке',
        //       content: 'Получить уведомление об активной подписке'
        //     }
        //   ],
        //   isOpen: false
        // }
      ],
      house: [
        {
          title: 'Преимущества программы',
          description:
            '<p><b>Когда пригодится:</b></p>' +
            '- отремонтировать свой дом после залива,</br>' +
            '- восстановить дом после пожара, взрыва,</br>' +
            '- купить новую технику после кражи.</br>' +
            '</br><p><b>От каких рисков страхует программа:</b></p>' +
            '- пожар, удар молнии, взрыв;</br>' +
            '- воздействие жидкости;</br>' +
            '- противоправные действия третьих лиц;</br>' +
            '- внешние механические воздействия (падение предметов).</br>' +
            '</br><p><b>Что можно застраховать:</b></p>' +
            '- внутреннюю отделку, инженерные сети и оборудование;</br>' +
            '- бытовую, аудио- и видеотехнику, мебель.',
          isOpen: false
        },
        {
          title: 'Описание продукта',
          description:
            '<p>Программа «Мой дом и все, что в нем online» позволит возместить ущерб, причиненный имуществу Вашего дома при пожаре, потопе и других непредвиденных неприятностях.</br>' +
            'С программой «Мой дом» Вы защитите имущество вашего дома при пожаре, потопе, краже и других непредвиденных собятиях всего от 390 тенге в месяц.</p>' +
            '<p></br>Оформляете подписку впервые? Получите скидку на первые три месяца:</p>' +
            '<p>При тарифе «Легкий» - 270 ₸/мес вместо 1 170 тенге;</p>' +
            '<p>При тарифе «Стандартный» - 570 ₸/мес вместо 2 370 тенге;</p>' +
            '<p>При тарифе «Оптимальный» - 870 ₸/мес вместо 3 570 тенге.</p>' +
            '<p></br>Простая и выгодная страховка, которая убережет Вас от финансовых потерь.</br>' +
            'Договор вступает в силу в день оплаты, страховая защита начинается на 3 (третий) календарный день и заканчивается одновременно с датой окончания действия Договора.</br>' +
            'Ежемесячно, с карты, которую вы использовали для покупки полиса, будет происходить списание и подписка будет продлеваться автоматически.</p>',
          isOpen: false
        },
        {
          title: 'Страховые выплаты по программе',
          description:
            '<p>При тарифе «Легкий» - за 390 ₸/мес выплатим до 1 000 000 тенге;</p></br>' +
            '<p>При тарифе «Стандартный» - за 790 ₸/мес выплатим до 2 000 000 тенге;</p></br>' +
            '<p>При тарифе «Оптимальный» - за 1190 ₸/мес выплатим до 3 000 000 тенге.</p>',
          isOpen: false
        }
        // {
        //   title: 'Как оформить подписку:',
        //   description: [
        //     {
        //       id: 1,
        //       url: 'https://www.adorama.com/alc/wp-content/uploads/2021/05/bird-wings-flying-feature.gif',
        //       alt: 'Ввести свои данные',
        //       content: 'Ввести свои данные'
        //     },
        //     {
        //       id: 2,
        //       url: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/5eeea355389655.59822ff824b72.gif',
        //       alt: 'Выбрать подходящий тариф',
        //       content: 'Выбрать подходящий тариф'
        //     },
        //     {
        //       id: 3,
        //       url: 'https://lukeroberts.tv/blog/wp-content/uploads/2019/05/gif1.gif',
        //       alt: 'Привязать карту',
        //       content: 'Привязать карту'
        //     },
        //     {
        //       id: 4,
        //       url: 'https://user-images.githubusercontent.com/6876788/96633009-d1818000-1318-11eb-9f1d-7f914f4ccb16.gif',
        //       alt: 'Дождаться списания средств с карты',
        //       content: 'Дождаться списания средств с карты'
        //     },
        //     {
        //       id: 5,
        //       url: 'https://ec.europa.eu/eurostat/statistics-explained/images/7/7d/Test_animated_image.gif',
        //       alt: 'Получить уведомление об активной подписке',
        //       content: 'Получить уведомление об активной подписке'
        //     }
        //   ],
        //   isOpen: false
        // }
      ]
    }
  }),
  computed: {
    setArrInfo () {
      let arr = ''
      if (this.product_name === 'apartment') arr = this.product_info.apartment
      if (this.product_name === 'casco') arr = this.product_info.casco
      if (this.product_name === 'house') arr = this.product_info.house
      return arr
    }
  },
  methods: {
    visibleProductInformation (item) {
      const foundIndex = this.setArrInfo.findIndex(obj => obj === item)
      this.setArrInfo[foundIndex].isOpen
        ? this.setArrInfo[foundIndex].isOpen = false
        : this.setArrInfo[foundIndex].isOpen = true
      this.setArrInfo
        .filter(obj => obj !== this.setArrInfo[foundIndex])
        .map(obj => {
          obj.isOpen = false
        })
    }
  }
}
</script>

<style lang="css">
</style>
