<template>
  <div class="product-form pr" :class="formLoader ? 'bg-loading' : ''">
    <form action="">
      <div class="section-title">
        <h2 class="bold">Заполните заявление на страхование</h2><br>
      </div>
      <div class="form-panel">
        <text-field
          mask="+7(###) ### ## ##"
          label="Мобильный телефон"
          v-model="form.phone_number"
          :disabled="!newClient"
          inputType="text"
          idFor="mobiles"
          :upLabel="!newClient"
        />
      </div>
      <person
        v-if="step >= 1"
        :iin="form.holder.individual_id_number"
        :phone="form.phone_number"
        @ready="readyHolder"
        @location="getLocation"
        @document="getDocument"
        @user="getUser"
        @form="getForm"
      />
      <div class="form-panel" v-if="step >= 2">
        <div class="form-control__checkbox">
          <label class="checkbox" for="scales">
            <input
              type="checkbox"
              class="checkbox-input"
              id="scales" name="scales"
              v-model="form.owner_is_holder"
              checked
            >
            <span class="checkbox-text"><b>Данные страхователя и застрахованного совпадают</b></span>
          </label>
        </div>
      </div>
      <div v-if="checkReset(form.holder.individual_id_number)">
        <person
          v-if="(step >= 2 && !form.owner_is_holder) || (step <= 4 && !form.owner_is_holder)"
          :reset="checkReset(form.holder.individual_id_number)"
          :show="true"
          @ready="readyInsurer"
          @document="getDocumentOwner"
          @location="getLocationOwner"
          @form="getFormOwner"
          @user="getUserOwner"
        />
      </div>
      <location
        v-if="step >= 4"
        title="Данные о застрахованном имуществе"
        :value="insured_property"
        @changed="changedProperty"
        @validater="insuredProperty"
        :full-validate="true"
        :insurance_type="form.insurance_type"
      />
      <div class="form-panel" v-if="step >= 4">
        <date-field
          id="start_date"
          label="Дата начала полиса"
          ref="beginDate"
          v-model="form.start_date"
          :minDate="new Date()"
          :maxDate="tomorrow"
          :disabled="true"
        />            <!--        :maxDate="new Date(new Date().setFullYear(new Date().getFullYear() + 2))"-->
      </div>
      <tariff
        v-if="step >= 5 && this.form.start_date"
        :value="houseTariff"
        :insurance_type="form.insurance_type"
        @changed="changedTariff"
      />
      <final-amount
        v-if="step >= 5 && this.form.start_date"
        :value="finalAmount"
        @onSubmit="onSubmit"
      />
    </form>
    <confirm v-if="step === 6" :value="finalAmount.res" :temp_token="temp_token" @modalStatus="getModalStatus" @productAuth="productAuth"/>
    <otp
      v-if="step === 7"
      @code="otpCode"
      @resend="otpResend"
      @close="otpClose"
      :error="otp.error"
      :loader="otp.loading"
      :phone="form.phone_number"
    />
  </div>
</template>

<script>
// Modal
import Confirm from '@/components/subscription/Modals/Confirm'
import Otp from '@/components/subscription/Modals/Otp'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Fields
import TextField from '@/components/fields/TextField'
import DateField from '@/components/fields/DateField'
// FormBox
import Location from '@/components/formBox/items/Location'
import FinalAmount from '@/components/formBox/FinalAmount'
import Person from '@/components/formBox/Person'
import Tariff from '@/components/formBox/Tariff'
// Utils
import { convertFormForBack } from '@/utils/person'
import { getPeopleByIin } from '@/api/info'
import axios from 'axios'
// Mixins
import ePayPayment from '@/mixin/ePayPayment'
import commonToForms from '@/mixin/commonToForms'

export default {
  name: 'MyHouseForm',
  mixins: [commonToForms, ePayPayment],
  components: {
    FinalAmount,
    TextField,
    DateField,
    Location,
    Confirm,
    Tariff,
    Person,
    Otp
  },
  data: () => ({
    form: {
      insurance_type: 3016,
      owner_is_holder: true,
      property_owner: '',
      insured_property: {
        street_name: null,
        street_no: null,
        country: null,
        flat_no: null,
        region: null,
        state: null,
        city: null
      }
    },
    insured_property: {
      address_street_no: null,
      address_country: 'KAZ',
      // address_flat_no: null,
      address_region: null,
      address_street: null,
      address_state: null,
      address_city: null
    }
  }),
  computed: {
    ...mapGetters({
      houseTariff: 'houseTariff'
    })
  },
  methods: {
    ...mapActions({
      getHouseTariff: 'getHouseTariff'
    }),
    readyInsurer (val) {
      if ((val && this.step === 4) || (val && this.step === 2)) {
        this.step = 4
      } else {
        this.step = 2
      }
    },
    insuredProperty (val) {
      if ((val.readyBefore && this.step === 4) || (val.readyBefore && this.step === 2)) {
        this.step = 5
      } else {
        this.step = 4
      }
    },
    changedProperty (val) {
      this.insured_property.address_street_no = val.address_street_no
      this.insured_property.address_country = val.address_country
      this.insured_property.address_flat_no = val.address_flat_no
      this.insured_property.address_street = val.address_street
      this.insured_property.address_region = val.address_region
      this.insured_property.address_state = val.address_state
      this.insured_property.address_city = val.address_city
    },
    changedTariff (val) {
      this.form.tariff = this.houseTariff[val].title
      this.finalAmount.toPayment = this.houseTariff[val].toPayment
      if (this.houseTariff[val].discount) {
        this.finalAmount.cost = this.houseTariff[val].price * 3
        this.finalAmount.discount = this.finalAmount.cost - this.finalAmount.toPayment
      } else this.finalAmount.cost = this.houseTariff[val].price
    },
    // Get Owner
    getFormOwner (val) {
      if (val && !this.form.owner_is_holder) {
        this.form.property_owner.individual_id_number = val.individual_id_number
        this.form.property_owner.checked = val.checked
        this.form.property_owner.uuid = val.uuid
      }
    },
    getUserOwner (val) {
      if (val && !this.form.owner_is_holder) {
        this.form.property_owner.first_name = val.first_name
        this.form.property_owner.birth_date = val.birth_date
        this.form.property_owner.last_name = val.last_name
        this.form.property_owner.s_name = val.s_name
        this.form.property_owner.gender = val.gender
      }
    },
    getLocationOwner (val) {
      if (val && !this.form.owner_is_holder) {
        this.form.property_owner.address_region = val.address_region
        this.form.property_owner.address_state = val.address_state
        this.form.property_owner.address_city = val.address_city
        this.form.property_owner.address_street_full = val.address_street
        this.form.property_owner.address_street_no = val.address_street_no
        this.form.property_owner.address_flat_no = val.address_flat_no
      }
    },
    getDocumentOwner (val) {
      if (val && !this.form.owner_is_holder) {
        this.form.property_owner.doc_issue_date = val.doc_issue_date
        this.form.property_owner.doc_number = val.doc_number
        this.form.property_owner.doc_type = val.doc_type
      }
    },
    // Check
    checkReset (val) {
      if (val) {
        const check = val.length === 12
        if (!check) {
          this.form.owner_is_holder = true
        }
        return check
      }
    },
    // Submit
    onSubmit () {
      const isChecked = this.checkClients()
      if (isChecked) this.realEstateCalculation()
    },
    realEstateCalculation () {
      this.formLoader = true
      let id = null
      axios.post(this.checkToken() + '/insurance-products/calculations/subscription/my-house', convertFormForBack(this.form))
        .then(res => {
          id = res.data.id
          if (id) {
            axios.get(this.checkToken() + '/insurance-products/calculations/' + id)
              .then(res => {
                this.finalAmount.res = res.data
                this.step = 6
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setTimeout(() => {
            this.formLoader = false
          }, 1000)
        })
    },
    sendDraft () {
      axios.post('/policy-draft', convertFormForBack(this.form))
        .then(res => {
          const token = localStorage.getItem('accessToken')
          if (!token) this.temp_token = res.data.temp_token
        })
        .catch(err => {
          console.log(err)
        })
    },
    // Import Data
    importTariff () {
      getPeopleByIin(this.form.holder.individual_id_number)
        .then(res => {
          const data = res.data
          if (data.manID !== 0) {
            this.getHouseTariff([data.manID, this.temp_token])
          } else this.getHouseTariff(['', this.temp_token])
        })
    }
  },
  watch: {
    'form.owner_is_holder': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if ((newValue && this.step === 4) || (newValue && this.step === 2)) {
          this.step = 4
        }
        if (!newValue) {
          this.form.property_owner = Object({
            individual_id_number: null,
            first_name: null,
            last_name: null,
            s_name: null,
            birth_date: null,
            gender: null,
            doc_type: null,
            doc_number: null,
            doc_issue_date: null,
            address_region: null,
            address_state: null,
            address_city: null,
            address_street_full: null,
            address_street_no: null,
            address_flat_no: null,
            new_client: null,
            checked: null,
            uuid: null
          })
        } else {
          this.form.property_owner = ''
        }
      }
    },
    'form.phone_number': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue.length === 17) {
          this.step = 1
        } else {
          this.step = 0
        }
      }
    },
    insured_property: {
      deep: true,
      immediate: true,
      handler: function () {
        this.form.insured_property.street_name = this.insured_property.address_street
        this.form.insured_property.street_no = this.insured_property.address_street_no
        this.form.insured_property.flat_no = this.insured_property.address_flat_no
        this.form.insured_property.country = this.insured_property.address_country
        this.form.insured_property.region = this.insured_property.address_region
        this.form.insured_property.state = this.insured_property.address_state
        this.form.insured_property.city = this.insured_property.address_city
      }
    }
  }
}
</script>

<style scoped></style>
