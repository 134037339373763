<template>
  <section class="product-other">
    <p class="product-other-title">С этим продуктом также приобретают</p>
    <carousel
      :margin="20"
      :responsive="{
        0:{items:2, center: true, dots: true, nav: false, autoWidth: true, loop: true},
        766:{items:2, center: true, dots: true, nav: false, autoWidth: true, loop: true},
        1000:{items:3, center: false, dots: false, nav: false, autoWidth: false, loop: false}
      }"
    >
    <product-other-item v-for="(item, index) in products" :key="index + Math.random()" :item="item"/>
    </carousel>
  </section>
</template>

<script>
import ProductOtherItem from '@/components/subscription/ProductOther/ProductOtherItem'
import carousel from 'vue-owl-carousel'

export default {
  name: 'ProductOther',
  components: {
    ProductOtherItem,
    carousel
  },
  data: () => ({
    products: [
      {
        icon: {
          url: require('@/assets/images/product-other1.png'),
          alt: 'product-my-home'
        },
        title: 'Страхование дома <br> по подписке',
        link: { name: 'MyHouse' }
      },
      {
        icon: {
          url: require('@/assets/images/product-other2.png'),
          alt: 'product-apartment'
        },
        title: 'Страхование квартиры <br> по подписке',
        link: { name: 'Apartment' }
      },
      {
        icon: {
          url: require('@/assets/images/product-other3.png'),
          alt: 'product-casco'
        },
        title: 'КАСКО <br> по подписке',
        link: { name: 'CascoLite' }
      }
    ]
  })
}
</script>

<style lang="css">
  .product-other {
    margin: 0 auto 30px;
    max-width: 570px;
  }
  .product-other-title {
    font-family: Manrope, sans-serif;
    margin-bottom: 30px;
    font-style: normal;
    text-align: center;
    line-height: 111%;
    font-weight: 500;
    font-size: 27px;
    color: #000;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #11BB91 !important;
  }
</style>
