<template>
  <section class="questions-block">
    <p class="question-title">Часто задаваемые вопросы</p>
    <div class="questions pr">
      <question-item v-for="(item, index) in questions"
                 :key="index + Math.random()" :item="item" :last_item="questions[questions.length - 1]" @visibleAnswer="visibleAnswer"/>
    </div>
  </section>
</template>

<script>
// Widgets
import QuestionItem from '@/components/subscription/Questions/QuestionItem'

export default {
  name: 'Questions',
  components: {
    QuestionItem
  },
  props: {
    info: {
      type: Array
    }
  },
  data: () => ({
    questions: []
  }),
  created () {
    this.questions = this.info
  },
  methods: {
    visibleAnswer (item) {
      const foundIndex = this.questions.findIndex(obj => obj === item)
      this.questions[foundIndex].isOpen
        ? this.questions[foundIndex].isOpen = false
        : this.questions[foundIndex].isOpen = true
      this.questions
        .filter(obj => obj !== this.questions[foundIndex])
        .map(obj => {
          obj.isOpen = false
        })
    }
  }
}
</script>

<style lang="css">
  .questions {
    /*box-shadow: 0 4px 4px rgba(0, 0, 0, .1);*/
    padding: 15px 25px 0 15px;
    margin: 0 auto 50px auto;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px #CFCFCF solid;
    max-width: 500px;
    font-size: 14px;
  }
  .question-title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 111%;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
  }
</style>
