<template>
  <field-layout
    :visible="visible"
    :error="error"
    :id-for="idFor"
    :label="label"
    :visible_error="visible_error"
  >
    <v-select
      :value="value"
      :id="idFor"
      :reduce="item => item.value"
      :options="options"
      :get-option-label="(option) => option.title"
      :disabled="disabled"
      @input="setSelected"
      @search:focus="onFocus"
      @search:blur="onBlur"
    ></v-select>
  </field-layout>
</template>

<script>
import FieldLayout from '@/components/fields/FieldLayout'

export default {
  name: 'SelectField',
  components: {
    FieldLayout
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: null,
    idFor: {
      type: String,
      default: ''
    },
    options: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    error: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    visible: false,
    visible_error: false,
    val: ''
  }),
  beforeMount () {
    if (this.value) {
      this.visible = true
    }
  },
  methods: {
    setSelected (value) {
      if (value) {
        this.val = value
      }
      this.$emit('input', this.val)
    },
    onFocus () {
      this.visible = true
      this.visible_error = true
    },
    onBlur () {
      this.visible = !!this.val
      this.visible_error = false
    }
  },
  mounted () {
    if (this.upLabel) {
      this.visible = this.upLabel
    }
    if (this.value) {
      this.val = this.value
      this.visible = true
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.visible = !!newValue
        this.val = this.value
      }
    }
  }
}
</script>

<style scoped>
>>> {
  --vs-selected-bg: #11bb91;
  --vs-dropdown-option--active-bg: #11bb91;
}
</style>
