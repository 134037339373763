<template>
  <div class="subscription-information-item pr" :class="item.loading ? 'bg-loading' : ''">
    <img
      v-if="item.isSuccessReSubscribe"
      class="subscription-information-item__icon-success"
      :src="require('@/assets/images/icon-success.svg')"
      alt="icon-success"
    />
    <img
      v-if="item.isFailReSubscribe"
      class="subscription-information-item__icon-fail"
      :src="require('@/assets/images/icon-fail.svg')"
      alt="icon-fail"
    />
    <h1 class="subscription-information-item__title">{{ setFullName }}</h1>
    <div class="subscription-information-item__main">
      <table>
        <tr>
          <td><span>Подписка</span></td>
          <td><span>Тариф</span></td>
        </tr>
        <tr>
          <td><p>{{ subscriptionTitle }}</p></td>
          <td><p>{{ item.tarif }}</p></td>
        </tr>
        <tr>
          <td v-if="item.status === 'Активен'"><span>Дата подписки</span></td>
          <td v-else><span>Дата окончания действия</span></td>
          <td><span>Сумма подписки</span></td>
        </tr>
        <tr>
          <td v-if="item.status === 'Активен'"><p>{{ setDate(item.dateGiven) }}</p></td>
          <td v-else><p>{{ setDate(item.dateNextWriteDowns) }}</p></td>
          <td><p>{{ item.premMonth | formatMoney }} тенге в месяц</p></td>
        </tr>
        <tr v-if="(item.insrType).includes('2225')">
          <td><span>Гос номер</span></td>
          <td><span>Марка авто</span></td>
        </tr>
        <tr v-if="(item.insrType).includes('2225')">
          <td><p>{{ item.variableParams.CASKO_VEHICLE_NO }}</p></td>
          <td><p>{{ item.variableParams.CASKO_MODEL_MAKE }}</p></td>
        </tr>
      </table>
    </div>
    <span v-if="(item.insrType).includes('3014')"> Адрес квартиры </span>
    <span v-if="(item.insrType).includes('3016')"> Адрес дома </span>
    <p v-if="(item.insrType).includes('3014') || (item.insrType).includes('3016')">{{ item.variableParams.IMU_ADDRESS }}</p>
    <span v-if="(item.insrType).includes('2225') && item.variableParams.CASKO_DRIVER">Страхователь</span>
    <p v-if="(item.insrType).includes('2225') && item.variableParams.CASKO_DRIVER">{{ item.variableParams.CASKO_DRIVER }}</p>
    <span v-if="item.variableParams.CASKO_INSURER">Застрахованный</span>
    <p>{{ item.variableParams.CASKO_INSURER }}</p>
    <div class="subscription-information-item__extra">
      <table>
        <tr>
          <td><p>Покрытие</p></td>
          <td><p>{{ item.insSum | formatMoney }} тенге</p></td>
        </tr>
        <tr v-if="title_btn">
          <td><p>Статус</p></td>
          <td :class="item.status === 'Активен' ? 'status_active' : 'status_inactive'"><p>{{ item.status }}</p></td>
        </tr>
        <tr v-if="title_btn && !item.isSuccessReSubscribe && !item.isFailReSubscribe">
          <td></td>
          <td>
              <input
                type="checkbox"
                class="custom-checkbox"
                :id="'custom-checkbox_id' + index"
                v-model="item.checked"
                @input="updateVal"
              >
              <label :for="'custom-checkbox_id' + index"> {{ title_btn }} </label>
          </td>
        </tr>
      </table>
    </div>
    <br/>
    <p v-if="item.isFailReSubscribe" class="subscription-information-item__error-title">По данному договору возникла ошибка. В ближайшее время с Вами свяжется наш менеджер</p>
  </div>
</template>

<script>
// Utils
import setUserName from '@/mixin/setUserName'
import moment from 'moment'

export default {
  name: 'SubscriptionItemInformation',
  mixins: [setUserName],
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    index: Number,
    title_btn: String
  },
  computed: {
    subscriptionTitle () {
      return this.item.insrTypeName.replace(/ по подписке/g, '')
    }
  },
  methods: {
    updateVal (e) {
      this.$emit('input', e.target.checked, this.item.policyId)
    },
    setDate (val) {
      return moment(val).format('DD.MM.YYYY')
    }
  }
}
</script>

<style scoped>
.subscription-information-item {
  padding: 30px;
  background: #F9F9F9;
  border-radius: 5px;
  margin: 10px 0;
}
.subscription-information-item__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  margin: 0 0 14px 0;
}
.subscription-information-item__main > table,
.subscription-information-item__extra > table {
  width: 100%;
  border-collapse: collapse;
}
.subscription-information-item__main > table > tr > td > span,
.subscription-information-item > span {
  font-size: 12px;
  line-height: 140%;
}
.subscription-information-item__main > table > tr > td > p,
.subscription-information-item > p {
  font-size: 16px;
  line-height: 140%;
  margin: 0 0 5px 0;
}
.subscription-information-item__main > table > tr > td,
.subscription-information-item__extra > table > tr > td {
  vertical-align: initial;
  width: 50%;
}
.subscription-information-item__extra > table > tr > td > p{
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  margin: 5px 0;
}
.subscription-information-item__extra > table > tr > td > input[type="checkbox"]:checked {
  background-color: #FFFFFF;
  color: #2a353d;
}
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.subscription-information-item__extra > table > tr > td > label, .status_active {
  color: #11BB91;
}
.status_inactive {
  color: #FF4B2B;
}
img.subscription-information-item__icon-success, img.subscription-information-item__icon-fail {
  position: absolute;
  right: 15px;
  width: 80px;
}
.subscription-information-item__error-title {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #FF4B2B;
}
@media (max-width: 1200px) {
  .subscription-information-item {
    padding: 16px;
  }
  .subscription-information-item__title {
    font-size: 14px;
    line-height: 120%;
    margin: 0 0 10px 0;
  }
  .subscription-information-item__main > table > tr > td > span, .subscription-information-item > span {
    font-size: 10px;
  }
  .subscription-information-item__main > table > tr > td > p, .subscription-information-item > p {
    font-size: 13px;
    line-height: 100%;
  }
  .subscription-information-item__extra > table > tr > td > p {
    font-size: 14px;
  }
}
</style>
