export default {
  data: () => ({
    iconsInfo: [
      {
        insrType: '3014',
        icon: {
          url: require('@/assets/images/icon-product1.svg'),
          alt: 'apartment-icon.svg',
          background: '#FFFFFF'
        },
        btnBackground: '#017C66'
      },
      {
        insrType: '3016',
        icon: {
          url: require('@/assets/images/icon-product2.svg'),
          alt: 'house-icon.svg',
          background: ''
        },
        btnBackground: '#12AB6B'
      },
      {
        insrType: '2225',
        icon: {
          url: require('@/assets/images/icon-product3.svg'),
          alt: 'casco-icon.svg',
          background: ''
        },
        btnBackground: '#00C1C1'
      }
    ]
  }),
  computed: {
    getIconUrl () {
      return this.iconsInfo[this.iconsInfo.findIndex(obj => obj.insrType === this.item.insrType)].icon.url
    },
    getIconAlt () {
      return this.iconsInfo[this.iconsInfo.findIndex(obj => obj.insrType === this.item.insrType)].icon.alt
    },
    getIconBackground () {
      return this.iconsInfo[this.iconsInfo.findIndex(obj => obj.insrType === this.item.insrType)].icon.background
    },
    gitBtnBackground () {
      return this.iconsInfo[this.iconsInfo.findIndex(obj => obj.insrType === this.item.insrType)].btnBackground
    }
  }
}
