<template>
  <div class="success-modal">
    <div class="success-body">
      <div class="close" v-on:click="status = false">&times;</div>
      <div class="text-center">
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M53.3203 8.5C49.3639 6.88814 45.0357 6 40.5 6C21.7223 6 6.5 21.2223 6.5 40C6.5 58.7777 21.7223 74 40.5 74C59.2777 74 74.5 58.7777 74.5 40C74.5 37.9527 74.319 35.9477 73.9723 34" stroke="#11BB91" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M75.5 10L37.7857 48L27.5 37.6364" stroke="#11BB91" stroke-width="5" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="success-info">
        <h3 class="text-center" v-html="title"></h3>
        <p class="text-center" v-html="description"></p>
      </div>
      <button class="btn sm outline green border-radius center" v-on:click="status = false">{{ titleBtn }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    titleBtn: {
      type: String,
      default: 'Понятно'
    }
  },
  data: () => ({
    status: null
  }),
  watch: {
    status: {
      deep: true,
      handler: function (newValue) {
        this.$emit('modalStatus', newValue)
      }
    }
  }
}
</script>

<style scoped>
  .success-modal {
    background-color: rgba(0, 0, 0, .3);
    backdrop-filter: blur(8px);
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 100000;
    left: 0;
    top: 0;
  }
  .success-body {
    background-color: #f7f7f7;
    position: relative;
    border-radius: 6px;
    padding: 30px;
    width: 500px;
  }
  .success-body .close {
    position: absolute;
    line-height: 17px;
    transition: .2s;
    font-size: 30px;
    cursor: pointer;
    color: #99a3b3;
    padding: 20px;
    right: 0;
    top: 0;
  }
  .success-body .close:hover {
    color: #000;
  }
  .success-info {
    margin: 30px 0;
  }
  .success-info h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  .success-info p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
  }
</style>
