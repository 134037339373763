<template>
  <section class="form-person">
    <div class="form-panel">
      <text-field
        :label="this.full_name ? this.full_name : 'ИИН'"
        :loader="loadingClient"
        v-model="form.individual_id_number"
        v-if="this.step >=1"
        mask="############"
        inputType="text"
        idFor="iin"
        :error="error"
      />
    </div>
    <div>
      <user
        v-if="step >= 2"
        :value="this.user"
        @changed="changedUser"
        @validater="validateUser"
      />
      <location
        v-if="step >= 3"
        :value="this.location"
        @changed="changedLocation"
        @validater="validateLocation"
        :insuredInfo="insuredInfo"
      />
      <document
        v-if="step >= 4"
        :value="this.document"
        @changed="changedDocument"
        @validater="validateDocument"
      />
    </div>
  </section>
</template>

<script>
// Api
import { getPeopleByIin } from '@/api/info'
// Field
import TextField from '@/components/fields/TextField'
// FormBox
import Location from '@/components/formBox/items/Location'
import Document from '@/components/formBox/items/Document'
import User from '@/components/formBox/items/User'
// Plugin
import moment from 'moment'
// Utils
import { uuidv4 } from '@/utils/uuidv4'

export default {
  name: 'Person',
  props: {
    iin: null,
    phone: null,
    show: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    step: 0,
    form: {
      individual_id_number: null,
      new_client: false,
      checked: false,
      uuid: null
    },
    location: {
      address_street_no: null,
      address_country: 'KAZ',
      address_flat_no: null,
      address_region: null,
      address_street: null,
      address_state: null,
      address_city: null
    },
    document: {
      doc_issue_date: null,
      doc_number: null,
      doc_type: null
    },
    user: {
      first_name: null,
      birth_date: null,
      last_name: null,
      s_name: null,
      gender: null
    },
    valid: {
      user: {
        after: null,
        before: null
      },
      location: {
        after: null,
        before: null
      },
      document: {
        after: null,
        before: null
      }
    },
    error: null,
    full_name: null,
    loadingClient: false,
    insuredInfo: false
  }),
  components: {
    TextField,
    Location,
    Document,
    User
  },
  created () {
    this.form.uuid = uuidv4()
  },
  mounted () {
    if (this.iin) {
      this.form.individual_id_number = this.iin
    }
    if (this.show) {
      this.step = 1
    }
  },
  methods: {
    // Response
    individualNumberChecker (iin) {
      let s = 0
      const nn = iin.split('')
      for (let i = 0; i < 11; i++) {
        s = s + (i + 1) * nn[i]
      }
      let k = s % 11
      if (k === 10) {
        s = 0
        for (let i = 0; i < 11; i++) {
          let t = (i + 3) % 11
          if (t === 0) {
            t = 11
          }
          s = s + t * nn[i]
        }
        k = s % 11
        if (k === 10) {
          return [
            false, 'ИИН не должен использоваться, ошибки при формировании контрольного разряда'
          ]
        }
      }
      if (k !== parseInt(iin.substr(11, 1))) {
        return [
          false, 'Контрольный разряд ИИН неверный, должно быть ' + k + ', а стоит ' + iin.substr(11, 1)
        ]
      } else {
        return [
          true, null
        ]
      }
    },
    getUser (iin) {
      if (iin) {
        this.loadingClient = true
        getPeopleByIin(iin)
          .then(res => {
            const data = res.data
            // User
            this.full_name = data.title
            this.user.last_name = data.lName
            this.user.first_name = data.fName
            this.user.s_name = data.sName
            this.user.birth_date = moment(data.birthDate.date)._d
            this.user.gender = data.sex
            this.form.checked = true
            // Document
            const document = data.documents[0]
            if (document) {
              this.document.doc_type = document.doc_type
              this.document.doc_number = document.doc_number
              this.document.doc_issue_date = moment(document.doc_issue_date.date)._d
            }
            // Address
            const address = data.addresses.find(item => item.address_type === 'H') || data.addresses[0]
            if (address) {
              this.location.address_city = address.address_city
              this.location.address_country = address.address_country
              this.location.address_region = address.address_region
              this.location.address_state = address.address_state
              this.location.address_street_no = address.address_street_no
              this.location.address_street = address.address_street
              this.location.address_flat_no = address.address_flat_no
            }
            // Check Information
            this.insuredInfo = !!data.bin
          })
          .finally(() => {
            this.loadingClient = false
            this.step = 2
          })
      }
    },
    // Remove
    removeLocation () {
      this.location.address_street_no = null
      this.location.address_country = null
      this.location.address_flat_no = null
      this.location.address_region = null
      this.location.address_street = null
      this.location.address_state = null
      this.location.address_city = null
      this.valid.location.before = null
      this.valid.location.after = null
    },
    removeDocument () {
      this.document.doc_issue_date = null
      this.document.doc_number = null
      this.document.doc_type = null
      this.valid.document.before = null
      this.valid.document.after = null
    },
    removeUser () {
      this.user.birth_date = null
      this.user.first_name = null
      this.user.last_name = null
      this.user.s_name = null
      this.user.gender = null
      this.valid.user.before = null
      this.valid.user.after = null
    },
    removeForm () {
      this.form.individual_id_number = null
      this.form.new_client = null
      this.form.checked = null
      this.form.uuid = null
    },
    resetAll () {
      this.removeUser()
      this.removeDocument()
      this.removeLocation()
      this.removeForm()
      this.error = null
      this.full_name = null
      this.loadingClient = false
      this.insuredInfo = false
      this.insurerInfo = false
      this.form.individual_id_number = null
      this.form.checked = null
      this.form.new_client = null
      this.form.uuid = null
    },
    // Changed
    changedLocation (val) {
      this.location.address_street_no = val.address_street_no
      this.location.address_country = val.address_country
      this.location.address_flat_no = val.address_flat_no
      this.location.address_street = val.address_street
      this.location.address_region = val.address_region
      this.location.address_state = val.address_state
      this.location.address_city = val.address_city
    },
    changedDocument (val) {
      this.document.doc_issue_date = val.doc_issue_date
      this.document.doc_number = val.doc_number
      this.document.doc_type = val.doc_type
    },
    changedUser (val) {
      this.user.first_name = val.first_name
      this.user.birth_date = val.birth_date
      this.user.last_name = val.last_name
      this.user.s_name = val.s_name
      this.user.gender = val.gender
    },
    validateUser (val) {
      this.valid.user.after = val.readyAfter
      this.valid.user.before = val.readyBefore
    },
    validateLocation (val) {
      this.valid.location.after = val.readyAfter
      this.valid.location.before = val.readyBefore
    },
    validateDocument (val) {
      this.valid.document.after = val.readyAfter
      this.valid.document.before = val.readyBefore
    }
  },
  watch: {
    phone: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (!!newValue && newValue.length === 17) {
          this.step = 1
        } else if (newValue === false) {
          this.step = 0
        }
      }
    },
    'form.individual_id_number': function (val) {
      if (val.length === 12) {
        const check = this.individualNumberChecker(val)
        if (check[0]) {
          this.getUser(val)
        } else {
          this.error = check[1]
        }
      }
      if (val.length <= 11) {
        this.insuredInfo = false
        this.full_name = null
        this.error = null
        this.removeUser()
        this.removeLocation()
        this.removeDocument()
        this.step = 1
      }
    },
    'valid.user.before': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue === true) {
          this.step = 3
        } else if (newValue === false) {
          this.step = 2
        }
      }
    },
    'valid.location.before': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue === true) {
          this.step = 4
        } else if (newValue === false) {
          this.step = 3
        }
      }
    },
    'valid.document.before': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue === true) {
          this.step = 5
        } else if (newValue === false) {
          this.step = 4
        }
      }
    },
    step: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue === 5) {
          this.$emit('ready', true)
        } else {
          this.$emit('ready', false)
        }
      }
    },
    reset: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.resetAll()
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler (changed) {
        this.$emit('form', changed)
      }
    },
    user: {
      deep: true,
      immediate: true,
      handler (changed) {
        this.$emit('user', changed)
      }
    },
    location: {
      deep: true,
      immediate: true,
      handler (changed) {
        this.$emit('location', changed)
      }
    },
    document: {
      deep: true,
      immediate: true,
      handler (changed) {
        this.$emit('document', changed)
      }
    }
  }
}
</script>

<style scoped>

</style>
