<template>
  <modal-layout :visible="visible" :loading="loading">
    <p class="modal-title-sms"> Введите код из SMS, отправленный <br/> на номер {{ phone }}</p>
    <form class="modal-sms-input" name="one-time-code" action="#">
          <fieldset>
<!--            https://developer.apple.com/documentation/security/password_autofill/enabling_password_autofill_on_an_html_input_element, Autocomplete not to put on other input-->
            <div class="mb-10 pr">
              <input
                type="number"
                pattern="[0-9]*"
                v-mask="'#'"
                v-model="form.number_one"
                @keyup="setNextInput($event)"
                autocomplete="one-time-code"
                id="modal-sms-input-1"
                required
                autofocus
              >
              <input
                type="number"
                pattern="[0-9]*"
                v-mask="'#'"
                v-model="form.number_two"
                @keyup="setNextInput($event)"
                @keyup.delete="clearInput($event)"
                @focus="isFillPreviousInput($event)"
                id="modal-sms-input-2"
                required
              >
              <input
                type="number"
                pattern="[0-9]*"
                v-mask="'#'"
                v-model="form.number_three"
                @keyup="setNextInput($event)"
                @keyup.delete="clearInput($event)"
                @focus="isFillPreviousInput($event)"
                id="modal-sms-input-3"
                required
              >
              <input
                type="number"
                pattern="[0-9]*"
                v-mask="'#'"
                v-model="form.number_four"
                @keyup.delete="clearInput($event)"
                @focus="isFillPreviousInput($event)"
                @keyup="checkCode"
                id="modal-sms-input-4"
                required
              >
            </div>
            <span v-if="error">Введённый код неверен</span>
            <p v-show="timer > 0" class="modal-subtitle-sms">Получить новый код можно через <br/> <b>{{ timer }} сек</b></p>
            <br/>
            <p v-if="isSigned">Вводя код, Вы подписываете <a href="#">Заявление(-я)</a> на страхование и <a href="#" @click="isDeclaration = true">Декларацию</a>
              страхователя, подтверждаете ознакомление с
              <a :href="rules" target="_blank">Правилами страхования</a> и получение копии <a :href="rules" target="_blank">Правил страхования</a>,
              а также соглашаетесь c тарифами и <a href="#">условиями</a> АО «Страховая компания «Халык»</p>
            <br/>
            <button v-show="timer <= 0" class="btn sm outline green border-radius block mb-10 mt-10" v-on:click="startTimer()">
              Отправить код повторно
            </button>
            <button v-show="timer <= 0" class="btn sm outline green border-radius block" v-on:click="closeModal">
              Вернутся назад
            </button>
          </fieldset>
        </form>
    <declaration-modal v-if="isDeclaration" :text="declaration" @modalStatus="setStatusDeclaration"/>
  </modal-layout>
</template>

<script>
import DeclarationModal from '@/components/subscription/Modals/DeclarationModal'
import ModalLayout from '@/components/subscription/Modals/ModalLayout'
import axios from 'axios'

export default {
  name: 'SMSModal',
  components: {
    DeclarationModal,
    ModalLayout
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isSigned: {
      type: Boolean,
      default: false
    },
    phone: String,
    insrType: {
      type: Number,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {
      number_one: null,
      number_two: null,
      number_three: null,
      number_four: null,
      code: null
    },
    isDeclaration: false,
    declaration: '',
    rules: '',
    timer: 300,
    interval: null
  }),
  mounted () {
    let url = ''
    if (this.insrType === 3016) url = '/insurance-products/14'
    if (this.insrType === 3014) url = '/insurance-products/13'
    if (this.insrType === 2225) url = '/insurance-products/12'

    if (url) {
      axios.get(url)
        .then(res => {
          this.rules = res.data.rules[0].url
          this.declaration = res.data.declaration
        })
    }
  },
  methods: {
    setStatusDeclaration (val) {
      this.isDeclaration = val
    },
    startTimer: function () {
      if (this.timer === 0) {
        this.$emit('resend', true)
      }
      this.interval = setInterval(() => {
        if (this.timer === 1) {
          clearInterval(this.interval)
        }
        this.timer = this.timer - 1
      }, 1000)
    },
    setNextInput: function (event) {
      if (event.target.value && !['Delete', 'Backspace'].includes(event.code)) {
        event.target.nextElementSibling.focus()
      }
    },
    clearInput: function (event) {
      event.target.previousElementSibling.focus()
      event.target.previousElementSibling.select()
    },
    isFillPreviousInput: function (event) {
      if (!event.target.previousElementSibling.value) {
        event.target.previousElementSibling.focus()
      }
    },
    checkCode: function () {
      if (this.form.number_one && this.form.number_two && this.form.number_three && this.form.number_four) {
        this.form.code = [this.form.number_one, this.form.number_two, this.form.number_three, this.form.number_four].join('')
        clearInterval(this.interval)
        this.$emit('code', this.form.code)
      }
    },
    closeModal: function () {
      this.resetCode()
      this.$emit('close')
    },
    resetCode: function () {
      this.form.number_one = null
      this.form.number_two = null
      this.form.number_three = null
      this.form.number_four = null
      this.form.code = null
    }
  },
  watch: {
    'form.code': {
      deep: true,
      handler: function (newValue) {
        if (this.form.number_one && this.form.number_two && this.form.number_three && this.form.number_four) {
          this.$emit('code', newValue)
        }
      }
    },
    loading: {
      deep: true,
      handler: function (newValue) {
        if (!newValue) {
          this.startTimer()
        }
      }
    }
  }
}
</script>

<style lang="css">
  .modal-title-sms, .modal-subtitle-sms {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  .modal-title-sms {
    color: #071222;
  }
  .modal-subtitle-sms {
    color: #99A3B3;
    margin-top: 40px;
  }
  .modal-subtitle-sms > b {
    color: #2AA65C
  }
  .modal-sms-input {
    position: relative;
    width: 320px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .modal-sms-input fieldset {
    border: 0;
    padding: 0;
    text-align: center;
  }
  .modal-sms-input fieldset div {
    box-shadow: 0 12px 16px rgb(0 0 0 / 5%);
    border-radius: 16px;
    padding-bottom: 10px;
  }
  input[type="number"] {
    width: .82em;
    line-height: 1;
    margin: .1em;
    padding: 8px 0 4px;
    font-size: 3.65em;
    text-align: center;
    appearance: textfield;
    -webkit-appearance: textfield;
    border: none;
    border-bottom: 2px solid #E0E6EF;
    outline: none;
    color: #071222;
    background: #FFFFFF;
    font-weight: 700;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .modal-sms-input fieldset > span {
    color: #ff5722;
    position: absolute;
    width: 100%;
    left: 0;
  }
</style>
