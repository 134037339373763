<template>
    <modal-layout :visible="visible">
      <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M49.3203 5.5C45.3639 3.88814 41.0357 3 36.5 3C17.7223 3 2.5 18.2223 2.5 37C2.5 55.7777 17.7223 71 36.5 71C55.2777 71 70.5 55.7777 70.5 37C70.5 34.9527 70.319 32.9477 69.9723 31" stroke="#11D392" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M71.5 7L33.7857 45L23.5 34.6364" stroke="#11D392" stroke-width="5" stroke-linecap="round"/>
      </svg>
      <br/>
      <p class="modal-title"> Подписка успешно отменена</p>
      <p class="modal-subtitle"> Возобновить подписку Вы можете в личном кабинете</p>
      <button class="btn sm outline green border-radius col-7" v-on:click="$emit('close')">Хорошо</button>
    </modal-layout>
</template>

<script>
import ModalLayout from '@/components/subscription/Modals/ModalLayout'

export default {
  name: 'SuccessUnsubscribeModal',
  components: { ModalLayout },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="css"></style>
