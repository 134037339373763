<template>
  <div class="final-amount">
    <ul>
      <li>
          <span>Стоимость
            <span v-if="value.discount">за три месяца</span>
          </span>
        <b>{{ value.cost | formatMoney }} ₸/м</b>
      </li>
      <li v-if="value.discount">
        <span>Скидка на первые три месяца </span>
        <b>-{{ value.discount | formatMoney }} ₸/м</b>
      </li>
      <li>
          <span>Итого
            <span v-if="value.discount">за три месяца</span>
          </span>
        <b>{{ value.toPayment | formatMoney }} ₸</b>
      </li>
    </ul>
    <hr>
    <button class="btn green border-radius block" v-on:click.prevent="$emit('onSubmit')">Оплатить</button>
  </div>
</template>

<script>
export default {
  name: 'FinalAmount',
  props: {
    value: Object,
    visible: Boolean
  }
}
</script>

<style scoped></style>
