<template>
  <div class="columns">
    <text-field
      :label="carName"
      rules="required|min:7"
      name="car_number"
      :value="license_plate"
      @input="validateCarNumber"
      :error="carError"
      :disabled="loader"
      :loader="loader"
      v-model="form.license_plate"
      inputType="text"
      idFor="car_number"
    />
    <input type="hidden" name="car_model" v-model="form.model"/>
    <input type="hidden" name="car_territory" v-model="form.territory"/>
    <input type="hidden" name="car_type" v-model="form.type"/>
    <input type="hidden" name="car_year"  v-model="form.year"/>
  </div>
</template>

<script>
// Fields
import TextField from '@/components/fields/TextField'
// Utils
import { getCarByLicensePlate } from '@/api/info'

export default {
  name: 'CarInfoFields',
  components: {
    TextField
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    value: Object,
    canDelete: {
      type: Boolean,
      default: false
    },
    availableTypes: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    license_plate: this?.value?.license_plate || '',
    form: {
      license_plate: null,
      model: null,
      territory: null,
      type: null,
      year: null
    },
    loader: false,
    fullInfo: null,
    carError: null
  }),
  computed: {
    carName () {
      const { fullInfo, carError, loader } = this

      if (carError || !fullInfo || loader) {
        return 'Регистрационный номер авто'
      }

      return `${fullInfo.make} ${fullInfo.model} ${fullInfo.editionDate}`
    }
  },
  mounted () {
    const { value } = this
    if (value) {
      this.form = { ...value }
    }
  },
  methods: {
    async validateCarNumber (val) {
      // if (!val) {
      //   return this.resetForm()
      // }

      if (
        val.toLowerCase().match(/[\d]{3}[A-Z]{3}[\d]{2}/i) || // новые номера
        val.toLowerCase().match(/[\d]{2}[A-Z]{3}[\d]{2}/i) || // номера с прицепом
        val.toLowerCase().match(/[\d]{2}[A-Z]{2}[\d]{2}/i) || // номера с прицепом
        val.toLowerCase().match(/[A-Z]{1}[\d]{3}[a-zA-Z]{3}/i) // старые номера
      ) {
        this.carError = ''

        try {
          if (this.license_plate !== this.form.license_plate) {
            this.loader = true

            const { data } = await getCarByLicensePlate(val)

            this.loader = false

            // Проверка на типы
            if (this.availableTypes.length !== 0 && !this.availableTypes.includes(data.carType)) {
              // throw { message: '' }
              this.carError = 'Неверная марка автомобиля'
              this.$emit('ready', false)
            }

            if (this.license_plate !== data.regNumber) {
              this.form.license_plate = data.regNumber
              this.license_plate = data.regNumber
              this.form.model = data.model
              this.form.territory = data.territory
              this.form.type = data.carType
              this.form.year = data.editionDate

              this.$emit('changed', this.form)
            }

            this.fullInfo = data
          }
          this.$emit('ready', true)
        } catch (err) {
          const errorMessage = err?.response?.data?.message || err?.message

          // this.resetForm()

          this.$emit('error', {
            title: `Ошибка при получение данных ТС, ${val}`,
            message: (err?.response?.data?.message || err?.message),
            code: err?.response?.data?.error_code || 0
          })

          this.carError = errorMessage
          this.loader = false
          this.$emit('ready', false)
        } finally {}
      }
    },
    removeItem () {
      this.$emit('removeItem', this.index)
    },
    resetForm () {
      // this.carError = null
      // this.form.license_plate = null
      // this.form.model = null
      // this.form.territory = null
      // this.form.type = null
      // this.form.year = null
      // this.fullInfo = null
      //
      // this.$emit('changed', this.form)
    }
  }
}
</script>
