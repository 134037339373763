<template>
  <section class="unsubscribe">
    <services-header/>
    <services-body
      v-if="authUser"
      :status="subStatuses.activeSub"
      empty="Вы пока ничего не застраховали, но всё ещё впереди!"
    >
      <div class="container">
        <div class="row">
          <div class="col-7">
            <div class="subscription-information" v-if="authUser && activeSub">
              <sub-item-info
                v-for="(card, index) in activeSub"
                :key="index + Math.random()"
                :item="card"
                :index="index"
                @input="setChecked"
                :title_btn="'Выбрать'"
              />
              <br/>
              <div class="row">
                <div class="col-7 pr">
                  <button
                    class="btn orange border-radius block"
                    v-on:click="isConfirmModal = true"
                    :disabled="!isDisabled"
                  >
                    Отменить
                  </button>
                  <confirm-unsubscribe-modal
                    :visible="isConfirmModal"
                    :info="filteredCheckedSub"
                    @close="isConfirmModal = false"
                    @sms="openSMSModal"
                    v-if="isConfirmModal"
                  />
                  <sms-modal
                    v-if="isSMSModal"
                    :loading="loading"
                    :visible="isSMSModal"
                    :phone="getFilteredPhone"
                    :error="errorSms"
                    @close="closeSMSModal"
                    @code="checkCode"
                    @resend="otpResend"
                  />
                  <success-unsubscribe-modal
                    v-if="isAgreeUnsubscribe"
                    :visible="isAgreeUnsubscribe"
                    @close="isAgreeUnsubscribe = false"
                  />
                </div>
                <div class="col-5"></div>
              </div>
            </div>
          </div>
          <div class="col-5"></div>
        </div>
      </div>
    </services-body>
    <services/>
  </section>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Modal
import ConfirmUnsubscribeModal from '@/components/subscription/Modals/ConfirmUnsubscribeModal'
import SuccessUnsubscribeModal from '@/components/subscription/Modals/SuccessUnsubscribeModal'
import SmsModal from '@/components/subscription/Modals/SMSModal'
// Widgets
import SubItemInfo from '@/components/subscription/SubscriptionItemInformation'
import ServicesHeader from '@/components/subscription/ServicesHeader'
import ServicesBody from '@/components/subscription/ServicesBody'
import Services from '@/components/subscription/Services'
import axios from 'axios'
import { generateOtp, verifyOtp } from '@/api/info'

export default {
  name: 'Unsubscribe',
  metaInfo: {
    title: 'Отменить подписку',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    ConfirmUnsubscribeModal,
    SuccessUnsubscribeModal,
    ServicesHeader,
    ServicesBody,
    SubItemInfo,
    Services,
    SmsModal
  },
  data: () => ({
    isAgreeUnsubscribe: false,
    isConfirmModal: false,
    isSMSModal: false,
    countCheckedSub: 0,
    loading: false,
    errorSms: false,
    isDisabled: false
  }),
  methods: {
    ...mapActions(['getActiveSub', 'getArchiveSub', 'setChangedActiveSub']),
    setChecked (val, id) {
      this.activeSub.map(obj => {
        if (obj.policyId === id) {
          obj.checked = val
        }
      })
      this.setChangedActiveSub(this.activeSub)
    },
    async generateOtp () {
      this.loading = true
      generateOtp('destroy', this.filteredCheckedSub[0].policyId)
        .then(res => {
          console.log(res)
        })
        .catch(e => {
          console.log('Ошибка генерации отп при отмене', e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    otpResend (val) {
      if (val) {
        this.generateOtp()
      }
    },
    openSMSModal () {
      this.isConfirmModal = false
      this.isSMSModal = true
      this.generateOtp()
    },
    closeSMSModal () {
      this.isSMSModal = false
    },
    checkCode (code) {
      this.loading = true
      verifyOtp('destroy', this.filteredCheckedSub[0].policyId, code)
        .then(res => {
          this.loading = false
          if (res.status === 200) {
            this.errorSms = false
            this.loading = true
            for (const item of this.filteredCheckedSub) {
              axios.delete(`subscription/${item.policyId}`)
                .catch(e => {
                  console.log(`ошибка при отмене ${item.policyId}`, e)
                })
            }
            this.getActiveSub()
            this.getArchiveSub()
            this.loading = false
            this.isSMSModal = false
            this.isAgreeUnsubscribe = true
          }
        })
        .catch(e => {
          this.errorSms = true
          console.log('Ошибка при верификации отп при отмене', e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      subStatuses: 'subStatuses',
      activeSub: 'activeSub',
      authUser: 'authUser'
    }),
    getFilteredPhone () {
      const phone = this.authUser.phone_number
      return '+7 (' + phone.substring(0, 3) + ') ***-**-' + phone.substring(phone.length - 2)
    },
    filteredCheckedSub () {
      return this.activeSub.filter(obj => obj.checked === true)
    }
  },
  watch: {
    activeSub: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.activeSub) {
          this.isDisabled = this.activeSub.some(obj => obj.checked === true)
        }
      }
    }
  }
}
</script>

<style lang="css">
.unsubscribe > .container {
  margin-bottom: 50px;
}
</style>
