import axios from 'axios'

export function convertTariffsProduct (data) {
  const tariffsProduct = []
  data.forEach(item => {
    const tariffObj = {
      title: item.title,
      coating: item.subtitle,
      price: item.price,
      icon: {
        url: item.images,
        alt: item.media[0]?.custom_properties?.file_name
      }
    }
    tariffsProduct.push(tariffObj)
    tariffsProduct.sort((a, b) => a.price - b.price)
  })
  return tariffsProduct
}

export function convertQuestionsProduct (data) {
  const questionsProduct = []
  data.forEach(item => {
    const questionObj = {
      question: item.question,
      answer: item.answer,
      isOpen: false
    }
    questionsProduct.push(questionObj)
  })
  return questionsProduct
}
// пока вывел только один комментарий data.product.quotes[0].content, эта функция нужна если будет слайдер с комментариями
export function convertQuotesProduct (data) {
  const quotesProduct = []
  data.forEach(item => {
    const quoteObj = {
      quote: item.content
    }
    quotesProduct.push(quoteObj)
  })
  return quotesProduct
}

export async function getInfoOneProduct (id, item) {
  const { data } = await axios.get(`/web/insurance-web-products/${id}`)
  item.tariffs = convertTariffsProduct(data.product.tariffs)
  item.questions = convertQuestionsProduct(data.product.questions)
  item.quote = data.product.quotes[0]?.content
  return item
}

export function convertInsuranceProducts (data) {
  const insuranceProducts = [
    {
      id: 0,
      title: 'Страхование по подписке',
      namePage: '',
      description: '<p>Уникальная возможность купить страховой полис за небольшую ежемесячную плату от 90 тенге и <br> защитить свой дом, квартиру и автомобиль от возможных финансовых убытков.</p>' +
        '<ul>\n' +
        '<li>Оформление за 2 минуты</li>\n' +
        '<li>Выгодные программы для каждого</li>\n' +
        '<li>Возможность выбрать сумму выплаты</li>\n' +
        '</ul>',
      links: null,
      lineup: null,
      priceMonth: null,
      titleProductCard: null,
      firstTMPrice: null,
      coating: null,
      quote: '<p>Приобрел страховку от несчастного случая <br/> за 12 000 тенге. <br/> Получил травму левой ноги во время игры в футбол. Врачи сказали, что требуется операция. Собрав все необходимые документы, получил выплату 300 000 тенге по программе «Самое главное».<br/><br/> Мурагер, 29 лет г. Нур-Султан</p>',
      description_subscription: '<p>Это выгодное страхование для Вас и вашей семьи. Страхование по подписке, позволит <br> Вам разделить стоимость страхового полиса на ежемесячные платежи. <br> Тем самым, Вы получаете надежную защиту вашей квартиры, дома и автомобиля.<br><br> Реальные выплаты, онлайн поддержка 24/7.</p>', // контент "Оформи подписку"
      additionBackground: '',
      iconProduct: {},
      imageProduct: {
        url: require('@/assets/images/product4.png'),
        alt: 'general_image'
      },
      link_to_design: '',
      link_to_description: ''
    }
  ]
  if (data.length > 0) {
    data.forEach(item => {
      const insuranceProduct = {
        id: item.id,
        title: item.title, // "Страхование квартиры по подписке"
        namePage: item.subtitle, // "Apartment"
        description: item.content, // "<p>Программа «Квартирный ответ» защитит не только имущество <br> в вашей ...
        links: JSON.parse(item.links), // "[\"MyHouse\", \"CascoLite\"]"
        lineup: JSON.parse(item.parameters), // "[\"потоп\", \"пожар\", \"кража\", \"залив соседей снизу\"]"
        priceMonth: item.price,
        titleProductCard: item.title_description, // Страхование квартиры
        firstTMPrice: item.subtitle_description, // За первые три месяца от 270 ₸
        coating: item.content_description, // "<p class=\"product-card__coating_title\">Тип покрытия</p>\n<ul class=\"product-card__coating_list\">\n<li>Лёгкий <span>до 1 000 000 ₸</span></li>\n
        quote: item.quote,
        description_subscription: item.description_subscription, // контент "Оформи подписку"
        additionBackground: item.button_color,
        iconProduct: {
          url: item.images, // url get-запроса "https://halyk-sk.nidge.kz/storage/344/UXP9dEjzUTChevzcg1Jk2eQgCSQzsoxAnMQhEn0K.png"
          alt: 'icon-' + item.subtitle,
          background: item.subtitle === 'Apartment' ? '#FFFFFF' : ''
        },
        imageProduct: {
          url: item.cover, // url get-запроса "https://halyk-sk.nidge.kz/storage/342/MwWl4jktTaMkcgSfQeaFL35K90Kwkhjy2uawyYkN.png"
          alt: 'image-' + item.subtitle
        },
        link_to_design: item.subtitle === 'Apartment' ? 'https://polisonline.kz/strahovanie-kvartiry/'
          : item.subtitle === 'MyHouse' ? 'https://polisonline.kz/strahovanie-chastnogo-doma/'
            : item.subtitle === 'CascoLite' ? 'https://polisonline.kz/kasko-light/' : '',
        link_to_description: { name: ['Apartment', 'MyHouse', 'CascoLite'].includes(item.subtitle) ? item.subtitle : '' }
      }
      insuranceProducts.push(insuranceProduct)
    })
  }
  return insuranceProducts
}
