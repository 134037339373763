<template>
  <section class="services-body" :class="status === 0 ? 'bg-loading white' : ''">
    <slot v-if="status === 1"/>
    <div class="services-body__empty" v-if="status === 2">
      <div class="container">
        <div class="services-body__empty_text">
          {{ empty }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ServicesBody',
  props: {
    status: Number,
    empty: String
  },
  computed: {
    ...mapGetters({
      subStatuses: 'subStatuses'
    })
  }
}
</script>

<style scoped>
.services-body {
  margin-bottom: 50px;
}
.services-body.bg-loading.white {
  min-height: 40vh;
}
.services-body__empty_text {
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  line-height: 120%;
  flex-wrap: nowrap;
  font-weight: 700;
  min-height: 40vh;
  max-width: 600px;
  font-size: 30px;
  color: #303030;
  display: flex;
  margin: auto;
}
</style>
