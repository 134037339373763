<template>
  <section>
    <div :class="visible ? 'backdrop' : 'backdrop-none'">
      <div :class="visible && loading ? 'bg-loading modal' : visible && !loading ? 'modal' : 'modal-none'">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ModalLayout',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="css"></style>
