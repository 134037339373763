<template>
  <div :class="product_info_item === last_item ? 'last_item product-information-item' : 'product-information-item'">
    <div class="product-information-item__title mb-10" @click="$emit('visibleProductInformation', product_info_item)">
      <p>{{ product_info_item.title }}</p>
      <div class="product-information-item__icon">
        <div class="product-information-item__icon-plus-svg" v-if="!product_info_item.isOpen">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.86377 1.54297V6.5013H11.9061" stroke="#11BB91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path opacity="0.5" d="M1.8208 6.5H6.86317V11.4583" stroke="#11BB91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <span :class="!product_info_item.isOpen ? 'product-information-item__icon-plus-1' : 'product-information-item__icon-minus-1'"></span>
        <span :class="product_info_item.isOpen && 'product-information-item__icon-minus-2'"></span>
      </div>
    </div>
    <div
      class="product-information-item__list"
      v-show="product_info_item.isOpen"
    >
      <template v-if="!product_info_item.description[activeItem].url">
        <span class="mb-10" v-html="product_info_item.description"></span>
      </template>
      <template v-if="product_info_item.description[activeItem].url">
        <span class="mb-10">
<!--          <img class="steps-item__image" :src="product_info_item.description[activeItem].url" :alt="product_info_item.description[activeItem].alt">-->
          <steps-slider :steps="product_info_item.description" :value="activeItem" v-on:val="isActive" />
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import StepsSlider from '@/components/subscription/StepsSlider'

export default {
  name: 'ProductItemInformation',
  components: { StepsSlider },
  props: {
    product_info_item: {
      type: Object
    },
    last_item: {
      type: Object
    }
  },
  data: () => ({
    activeItem: 0
  }),
  methods: {
    isActive (id) {
      this.activeItem = id
    }
  }
}
</script>

<style lang="css">
.product-tabs__info_card {
  color: #2A353D;
}
.product-tabs__info_card__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 113%;
  margin-bottom: 10px;
}
.product-information-item__title {
  padding-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
}
.product-information-item__title > p {
  font-weight: 600;
  font-size: 15px;
}
.product-tabs__info_card .product-information-item {
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.product-tabs__info_card .product-information-item:before {
  background: #C8C7CC;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: "";
}
.product-tabs__info_card .product-information-item.last_item:before {
  display: none;
}
.product-information-item__list > span {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 113%;
}
.product-information-item__icon {
  position: relative;
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.product-information-item__icon-plus-svg {
  position: absolute;
  top: 14px;
  left: 9px;
  z-index: 9;
}
.product-information-item__icon-plus-1, .product-information-item__icon-minus-1 {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  background-color: #FFFFFF;
  border: 1px #F1F2F6 solid;
}
.product-information-item__icon-minus-2 {
  width: 12px;
  height: 2px;
  background-color: #11BB91;
  border-radius: 2px;
  position: absolute;
  top: 20px;
  left: 10px;
}
img.steps-item__image {
  height: 500px;
  margin: 0 auto;
  object-fit: cover;
}
</style>
