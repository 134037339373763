<template>
  <div class="form-control" :class="[setClassForm, disabled ? 'disabled' : '']">
    <slot></slot>
    <label class="label" :for="idFor">{{ label }}</label>
    <span class="text-danger-error" v-if="error && visible_error">{{ error }}</span>
    <rounded-loader v-if="loader" />
  </div>
</template>

<script>
import RoundedLoader from '@/components/loaders/RoundedLoader'

export default {
  name: 'FieldLayout',
  components: {
    RoundedLoader
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    idFor: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    loader: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    visible_error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    setClassForm () {
      let additionalClass = ''
      if (this.visible) {
        additionalClass += 'val'
      } else additionalClass = ''
      if (this.error && additionalClass) {
        additionalClass += ' form-control-error'
      } else if (this.error && !additionalClass) {
        additionalClass += 'form-control-error'
      }
      return additionalClass
    }
  }
}
</script>

<style lang="css">
  .text-danger-error {
    position: absolute;
    font-size: 10px;
    padding: 5px;
    color: red;
    z-index: 3000;
    bottom: 100%;
    left: 0;
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .05);
    background: rgba(245, 244, 244, 0.99);
  }
  .form-control-error {
    border: 1px solid red;
  }
  .form-control.disabled {
    opacity: .6;
  }
</style>
