/* jslint eqeq: false */
export default function (list, join, value) {
  if (!value || !list || !join) {
    return []
  }

  const newList = list.filter(function (row) {
    return join[row.value] === value
  })

  if (newList.length < 1) {
    return []
  }

  return newList
}
