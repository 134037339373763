<template>
  <div class="product-tariff-item-card mb-10">
    <img :src="item.icon.url" class="img" :alt="item.icon.alt"/>
    <div class="card-body">
      <h3 class="title">{{ item.title }}</h3>
      <span class="subtitle">Выплатим до {{ item.coating }} ₸</span>
      <a href="#">Выбрать тариф</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductTariffItem',
  props: {
    item: {
      type: Object
    }
  },
  components: {},
  computed: {}
}
</script>

<style lang="css">
  .product-tariff-item-card {
    overflow: hidden;
    background: #fff;
    box-shadow: 0 12px 16px rgb(0 0 0 / 5%);
    border-radius: 12px;
    width: 30%;
    margin: 0 auto 15px;
  }
  .product-tariff-item-card .img {
    width: 100%;
  }
  .product-tariff-item-card .card-body {
    padding: 12px;
  }
  .product-tariff-item-card .card-body .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
  }
  .product-tariff-item-card .card-body .subtitle {
    font-family: Manrope, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #566681;
    display: block;
    margin-bottom: 4px;
  }
  .product-tariff-item-card .card-body > a {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #11BB91;
  }
  @media (max-width: 700px) {
    .product-tariff-item-card {
      width: 80%;
    }
  }
</style>
