<template>
  <div class="product-other-item mb-10" v-if="$route.name !== item.link.name">
    <img :src="item.icon.url" class="img" :alt="item.icon.alt"/>
    <div class="card-body">
      <h3 class="title" v-html="item.title"></h3>
      <span class="subtitle">Выберите один из трёх тарифов</span>
      <router-link :to="item.link">Узнать подробнее</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductOtherItem',
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style lang="css">
  .product-other-item {
    overflow: hidden;
    background: #fff;
    box-shadow: 0 12px 16px rgba(0, 0, 0, .05);
    border-radius: 12px;
    margin: 0 auto 35px;
  }
  .product-other-item .img {
    width: 100%;
  }
  .product-other-item .card-body {
    padding: 12px;
  }
  .product-other-item .card-body .title {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 4px;
  }
  .product-other-item .card-body .subtitle {
    font-family: Manrope, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #566681;
    display: block;
    margin-bottom: 4px;
  }
  .product-other-item .card-body > a {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #11BB91;
  }
  @media (max-width: 1000px) {
    .product-other-item .card-body .title {
      font-size: 16px;
      line-height: 18px;
    }
    .product-other-item .card-body > h3 > br {
      display: none;
    }
  }
</style>
