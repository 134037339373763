import axios from 'axios'

export async function getPeopleByIin (iin) {
  return axios.get(`/info/get-people-by-iin?iin=${iin}`)
}

export async function getCarByLicensePlate (val) {
  return axios.get(`/info/get-car-by-license-plate?license_plate=${val}`)
}

export function generateOtp (param, id) {
  return axios.get(`/verification/generate-otp/${param}/${id}`)
}

export function verifyOtp (param, id, code) {
  return axios.get(`/verification/verefy-otp/${param}/${id}?otp=${code}`)
}
